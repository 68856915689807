/* FOODNOTIFY PAGINATOR */
/**
 * Implement function in .js that will be called by paginator on updates of paginator's state
 * Then set name of your function so paginator knows which function to call, either through .html or set in .js
 */

 
// In example below all things mentioned are mandatory to be part of the function
// 
// [=== example of implementation with pseudocode ===]

// [ if item list can be updated outside the paginator navigation you need to implement call_from_paginator argument
//   and include it in function like in example, otherwise, you can leave function without any reference to call_from_paginator

//   
// ]

//  name_of_your_update_function(current_page = 1, load_next_page = 1, items_per_page = 10, call_from_paginator = true) {
//      request {
//          async = true
         
//          before {
//             if(not call_from_paginator) {
//                 FoodNotifyPaginator.toggleShow(false)
//             }
//          }
//          success {
//              if(not call_from_paginator) {
//                 FoodNotifyPaginator.activePage(1)
//                 FoodNotifyPaginator.totalItemsNumber(total items)
                
//                 FoodNotifyPaginator.refresh()
//              }

//              if(count items > 0) {
//                 FoodNotifyPaginator.toggleShow()
//                 FoodNotifyPaginator.completedUpdate()
//              } else {
//                 FoodNotifyPaginator.toggleShow(false)
//              }
//          }
//          error {
//              FoodNotifyPaginator.error()
//          }
//      }
//  }

import Animations from "./animations";

class FoodNotifyPaginator {
    constructor() {}

    /**
     * Execute received message
     * @param {String} message 
     */
    static log(message) { FoodNotifyPaginator_log(message); }
    
    /**
     * Set flag that request from external update method is completed
     */
    static completedUpdate() { FoodNotifyPaginator_completedUpdate(); }
    
    /**
     * Raise error
     */
    static error() { FoodNotifyPaginator_error(); }
    
    /**
     * Show/Hide paginator
     * @param {Boolean} show 
     */
    static toggleShow(show = true) { FoodNotifyPaginator_toggleShow(show); }
    
    /**
     * Just update page parameter of current page
     * @param {Number} page 
     */
    static basicUpdate(page) { FoodNotifyPaginator_basicUpdate(page); }
      
    /**
     * Set, update DOM and return value of items per page
     * @param {Number} itemsPerPage
     * @param {Boolean} getFromDOM // get value from the DOM instead from argument
     * @returns {Number}
     */
    static itemsPerPage(itemsPerPage = __itemsPerPage, getFromDOM = false) { return FoodNotifyPaginator_itemsPerPage(itemsPerPage, getFromDOM); }

    /**
     * 
     * @param {*} totalItemsNumber 
     * @param {*} getFromDOM 
     * @returns 
     */
    static totalItemsNumber(totalItemsNumber = __totalItemsNumber, getFromDOM = false) { return FoodNotifyPaginator_totalItemsNumber(totalItemsNumber, getFromDOM); }
    
    /**
     * Set, update DOM and return value of currently active page
     * @param {Number} activePage
     * @param {Boolean} getFromDOM // get value from the DOM instead from argument
     * @returns {Number}
     */
    static activePage(activePage = __activePage, getFromDOM = false) { return FoodNotifyPaginator_activePage(activePage, getFromDOM); }
    
    /**
     * Set, update DOM and return name of the external update function
     * @param {String} externalUpdateMethodName 
     * @param {Boolean} getFromDOM // get value from the DOM instead from argument
     * @returns {String}
     */
    static externalUpdateMethodName(externalUpdateMethodName = __externalUpdateMethodName, getFromDOM = false) { return FoodNotifyPaginator_externalUpdateMethodName(externalUpdateMethodName, getFromDOM); }
    
    /**
     * Initialize paginator values defined by arguments, and re-"render" paginator
     * @param {Number} activePage 
     * @param {Number} numberOfPages 
     * @param {Number} itemsPerPage 
     * @param {String} externalUpdateMethodName 
     */
    static new(activePage, totalItemsNumber, itemsPerPage, externalUpdateMethodName) { FoodNotifyPaginator_new(activePage, totalItemsNumber, itemsPerPage, externalUpdateMethodName); }
    
    /**
     * re-"render" paginator with current values
     */
    static refresh() { FoodNotifyPaginator_refresh(); }
}

export default FoodNotifyPaginator;









/**
 * Array of parameter names for function calls and checkup of valid data
 * @var {Array}
 */
var __parameterData = ['activePage', 'numberOfPages', 'itemsPerPage', 'externalUpdateMethodName'];
 
/**
 * Indicates if update is currently happening, (all actions in update timeframe are blocked from piling up)
 * @var {Boolean}
 */
var __eventUpdateActive = false;
 
/**
 * Name of the external update function to be called by paginator on page change
 * @var {String}
 */
var __externalUpdateMethodName = "";
 
var __totalItemsNumber = 1; var __itemsPerPage = 10; var __numberOfPages = 1; var __activePage = 1; var __active = false; var __messageIndex = 1;



function FoodNotifyPaginator_log(message) { console.log('[FoodNotifyPaginator]:' + __messageIndex++ + ': ' + message); }
 

function FoodNotifyPaginator_completedUpdate() { __eventUpdateActive = false; }
 

function FoodNotifyPaginator_error() {
    __eventUpdateActive = false;
    FoodNotifyPaginator_toggleShow(false);
    FoodNotifyPaginator_new(1, 1, 1, __externalUpdateMethodName);
}
 

function FoodNotifyPaginator_toggleShow(show = true) { 
    $('#__fn-paginator-wrap').toggleClass('hidden', !show);
    __active = show;
}
 

function FoodNotifyPaginator_basicUpdate(page) {
    __eventUpdateActive = false;
    FoodNotifyPaginator_activePage(page);
}
 

function FoodNotifyPaginator_itemsPerPage(itemsPerPage = __itemsPerPage, getFromDOM = false) {
    __itemsPerPage = Number(getFromDOM ? $('#__fn-paginator-limit').attr('data-items-per-page') : itemsPerPage);
    $('#__fn-paginator-limit').attr('data-items-per-page', __itemsPerPage);
    $('#__fn-items-per-page-display').html(__itemsPerPage);
 
    return __itemsPerPage;
}


function FoodNotifyPaginator_totalItemsNumber(totalItemsNumber = __totalItemsNumber, getFromDOM = false) {
    __totalItemsNumber = totalItemsNumber;
    __numberOfPages = __roundCorrectionDivision__(__totalItemsNumber, __itemsPerPage);

    $('#__fn-paginator-last').attr('data-value', __numberOfPages);
    $('#__fn-paginator-last').html(__numberOfPages);

    return __totalItemsNumber;
}
 

function FoodNotifyPaginator_activePage(activePage = __activePage, getFromDOM = false) {
    __activePage = Number(getFromDOM ? $('#__fn-paginator-index-active').val() : activePage);
    $('#__fn-paginator-index-active').val(__activePage);
 
    return __activePage;
}
 

function FoodNotifyPaginator_externalUpdateMethodName(externalUpdateMethodName = __externalUpdateMethodName, getFromDOM = false) {
    __externalUpdateMethodName = (getFromDOM ? $('#__fn-paginator').attr('data-external-update-method-name') : externalUpdateMethodName);
    $('#__fn-paginator').attr('data-external-update-method-name', __externalUpdateMethodName);
     
    return __externalUpdateMethodName;
}
 
 
/**
 * Create new paginator
 * @param {Number} activePage 
 * @param {Number} totalItemsNumber 
 * @param {Number} itemsPerPage 
 * @param {string} externalUpdateMethodName 
 */
function FoodNotifyPaginator_new(activePage, totalItemsNumber, itemsPerPage, externalUpdateMethodName) {
    FoodNotifyPaginator_activePage(activePage);
    FoodNotifyPaginator_totalItemsNumber(totalItemsNumber);
    FoodNotifyPaginator_itemsPerPage(itemsPerPage);
    FoodNotifyPaginator_externalUpdateMethodName(externalUpdateMethodName);

    __eventUpdateActive = false;
 
    __reloadFoodNotifyPaginator__();
}
 
 
 
/**
 * Refresh paginator state with lates parameter
 */
function FoodNotifyPaginator_refresh() {
    __reloadFoodNotifyPaginator__();
}
 
 
 
/**
* Check paginator parameters if everything is correct, alert and hide paginator if invalid
* @returns {Boolean}
*/
function __initializedParameters__() {
    var parameterData = [ __parameterData ];
    parameterData.push([__activePage, __numberOfPages, __itemsPerPage, __externalUpdateMethodName]);
 
    if(__checkEmpty__(parameterData, true)) {
        if(__checkEmpty__(parameterData)) {
            FoodNotifyPaginator.toggleShow(false);
            return false;
        }
    }
 
    if(__activePage    < 1 || __numberOfPages < 1 || __itemsPerPage  < 1) {
        FoodNotifyPaginator.toggleShow(false);
        return false;
    }
    return true;
}
 
 
 
/**
 * Return true if parameter is invalid
 * @param {Array} data 
 * @param {Boolean} evaluate 
 * @returns {Boolean}
 */
function __checkEmpty__(data, evaluate = false) {
    for(var i = 0; i < data[1].length; i++) {
        if(!data[1][i] || data[1][i] == "" || data[1][i] == null) {
            // if evaluate is requested and parameter is invalid, call matched method to try and update values from the DOM
            if(!evaluate || (evaluate && !eval('FoodNotifyPaginator_' + data[0][i] + '(0, true)'))) {
                return true;
            }
        }
    }
    return false;
}
 
 
/**
 * Calculate visual presentation of paginator by parameters
 */
function __reloadFoodNotifyPaginator__() {
    var state = null;
 
    // map paginator to correct numbers ('0' is always new page)
    [-2, -1, 0, 1, 2].forEach(function(val, index) {
        state = __activePage + val;
        $('#__fn-paginator-index-' + index).attr('data-value', state);
        $('#__fn-paginator-index-' + index).html(state);
        $('#__fn-paginator-index-' + index).toggleClass('hidden', (
            (Math.abs(val) == 2 && (__activePage > 2 || __activePage < __numberOfPages - 2)) || (state > __numberOfPages || state < 1))
        );
    });
 
    /** Update visual positions of paginator according to new page number */
    state = __activePage == 1;
    $('#__fn-p-previous').css('opacity', state ? 0.15 : 0.6);
    $('#__fn-p-previous').css('text-decoration-line', state ? 'none' : 'underline');
 
    state = __activePage == __numberOfPages;
    $('#__fn-p-next').css('opacity', state ? 0.15 : 0.6);
    $('#__fn-p-next').css('text-decoration-line', state ? 'none' : 'underline');
 
    $('#__fn-paginator-left-extension').toggleClass('hidden', !(__activePage > 2));

    state = ((__activePage < __numberOfPages - (__numberOfPages == 4 ? 2 : 1)) && __numberOfPages > 3);
    $('#__fn-paginator-right-extension-last').toggleClass('hidden', !state);
    $('#__fn-paginator-separator-right').toggleClass('hidden', !state);
    /** */
}
 
 
 
 
/** 
 * Update DOM of paginator according to action from event handler
 * @param {string|Number} newPage 
 * @param {Boolean} callUpdate 
 * @returns {*}
 */
function __updateFoodNotifyPaginatorState__(newPage, callUpdate = true, forceUpdate = false) {
    if(!__active || !__initializedParameters__()) {
        return false;
    }

    // if update is not from external caller we don't need to block events
    __eventUpdateActive = callUpdate;
    currentPage = __activePage;
 
    // format, calculate and convert to new page
    if(isNaN(parseFloat(newPage))) {
        if(newPage == 'prev' && currentPage != 1) { newPage = Number(currentPage - 1); }
        else if(newPage == 'next' && currentPage != __numberOfPages) { newPage = Number(currentPage + 1); }
        else { // if no change made than don't call for external update
            FoodNotifyPaginator.basicUpdate(currentPage);
            return;
        }
    } else { newPage = Number(newPage); }
 
    __activePage = newPage;
    // update values in DOM for current page to new page number
    $('#__fn-paginator').attr('data-current-page', newPage);
    $('#__fn-paginator-index-active').val(newPage);
 
    if(callUpdate) {
        // call external update method, if return is false paginator update to current state
        if(!__externalUpdate(currentPage, newPage, __itemsPerPage)) {
            FoodNotifyPaginator.basicUpdate(currentPage);
            return;
        }
    } else if(!forceUpdate) { // update to current state if no call update, mainly initialization of paginator
        FoodNotifyPaginator.basicUpdate(currentPage);
        return;
    }
 
    __reloadFoodNotifyPaginator__();
}
 


/**
 * Call external function for updating on page action
 * @param {Number} currentPage 
 * @param {Number} newPage 
 * @param {Number} itemsPerPage 
 * @returns {Boolean}
 */
function __externalUpdate(currentPage, newPage, itemsPerPage) {
    return eval(__externalUpdateMethodName + '(' + currentPage + ', ' + newPage + ', ' + itemsPerPage + ')');
}
 


 /** * */
 $(document).ready(function(){

    let __Animations = new Animations();

    /**
     * Handle for presented options
     */
    $('#__fn-paginator .index-clickable').on('click', function(e) { 
        if(__eventUpdateActive) { return; } // don't execute if update is running
        __updateFoodNotifyPaginatorState__($(this).attr('data-value'));
    });
 

    /**
     * Handle for input page number
     */
    $('#__fn-paginator-index-active').on('update change', function(e) {
        if(__eventUpdateActive) { return; } // don't execute if update is running
        //var numberOfPages = Number($('#__fn-paginator').attr('data-number-of-pages'));
        var newPage = $(this).val();
 
        // if not number do nothing
        if(isNaN(newPage)) { return; }
        // set overflow to max, underflow to min page
        if($(this).val() > __numberOfPages) { newPage = __numberOfPages; }
        else if($(this).val() < 1) { newPage = 1; }
        __updateFoodNotifyPaginatorState__(newPage);
    });
 

    /**
     * Selected new number of items per page
     */
    $('.fn-p-item-option').on('click tap', function(e) {
        e.stopPropagation();
        if(__eventUpdateActive) { return; }

        __setupItemsPerPage__($(this).attr('data-value'));
    });


    /**
     * Calculate dropdown position and action for items per page dropdown
     */
    $('#__fn-paginator-items-button').on('click tap', function(e) {
        e.stopPropagation();
        var pp = $('#__fn-paginator-wrap').position();
        var position = pp.top / $(window).height() * 100;

        $('#__fn-paginator-items-selection').css('top', position < 50 ? '2.9em' : '-11em');
        $('#__fn-paginator-items-button').toggleClass('active');
        showOptionsDropdown();
    });


    /**
     * Refocus to initial input of custom items per page
     */
    $('#__fn-paginator-items-per-page-custom').on('focus', function(e) {
        $('#__fn-paginator-items-per-page-custom').css('text-decoration', 'none');
        $('#__fn-paginator-items-per-page-custom').css('color', '#333333');
    });


    /**
     * Parse input and confirm custom number of items per page
     */
    $('#__fn-paginator-items-per-page-custom-confirm').on('click tap', function(e) {
        var ippInput = Number($('#__fn-paginator-items-per-page-custom').val());

        if(isNaN(parseFloat(ippInput)) || ippInput < 1 || ippInput > 200) {
            $('#__fn-paginator-items-per-page-custom').css('text-decoration', 'underline dashed');
            $('#__fn-paginator-items-per-page-custom').css('color', '#cc3232');
            return;
        }

        __setupItemsPerPage__($('#__fn-paginator-items-per-page-custom').val());
    });

     
    /**
     * Trigger hide dropdown on focus outside the dropdown
     */
    $(window).on('tap click', function(e) {
        var caller = $(e.target);
        if(!caller.parents('#__fn-paginator-limit').length && !$('#__fn-paginator-items-selection').hasClass('hidden')) {
            $('#__fn-paginator-items-button').toggleClass('active');
            showOptionsDropdown(false);
        }
    });


     /**
     * Show/hide dropdown for items per page element
     */
     let showOptionsDropdown = function (show = null) {
        __Animations.halfRotate($('#__fn-paginator-items-caret'));

        if(show == null) {  $('#__fn-paginator-items-selection').toggleClass('hidden'); } 
        else { $('#__fn-paginator-items-selection').toggleClass('hidden', !show); }
    }
 });
 /** * */


/**
 * Calculate new paginator state for new number of items per page
 * @param {Number} itemsPerPage 
 */
function __setupItemsPerPage__(itemsPerPage = 10) {
    var positionPercent = (100 / __numberOfPages) * __activePage;

    FoodNotifyPaginator.itemsPerPage(itemsPerPage);
    FoodNotifyPaginator.totalItemsNumber(__totalItemsNumber);

    var newPage = __roundCorrectionDivision__(positionPercent * __numberOfPages, 100);

    $('#__fn-paginator-limit').attr('data-items-per-page', __itemsPerPage);
    $('#__fn-items-per-page-display').html(__itemsPerPage);
    $('#__fn-paginator-items-selection').toggleClass('hidden');
    $('#__fn-paginator-items-button').toggleClass('active');

    __updateFoodNotifyPaginatorState__(newPage);
}


/**
 * Round to integer by division leftover
 * @param {Number} x 
 * @param {Number} y 
 * @returns {Number}
 */
function __roundCorrectionDivision__(x, y) {
    return ((x / y) >> 0) + !!(x % y);
}


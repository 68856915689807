/* payment/subs pages */
import {htmlLoading} from "./global";
$( document ).ready(function() {
    if (!$('#payment-summary').length) {
        return;
    }
	
	var temp_url;

	$('body').on('click', '#checkout-no-tos', function() {
		var temp = document.getElementById('checkout-no-tos');
		var userId = temp.getAttribute('userId');
		temp_url = "/admin/users/" + userId + "/stripe/add-payment-method";
	});
	
	$('body').on('click', '.stripe-add-payment', function() {
		var button = $(this);
		var userId = button.attr("userId");
		var customerKey = button.attr("customerKey");
		temp_url = "/admin/users/"+ userId + "/stripe/add-payment-method-additional-customer-numbers/" + customerKey;
	});

    // init stripe payments
    (function() {
        var iban_form = $('#payment-modal-form-iban');
        var cc_form = $('#payment-modal-form-cc');
        var stripe = Stripe($('#payment-modal .modal-body').attr('data-pk'));

        var style = {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif',
                fontSize: '15px',

                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
        };

        // show result/errors or submit
        var setOutcome = function(result, form) {
            var errorElement = form.find('.error').removeClass('visible');

            if (result.error) {
                errorElement.text(result.error.message)
                    .addClass('visible');
                form.find('button').button('reset'); 
            }
            else
            if (result.source && result.source.id) {
                if (form.attr('data-save-only')) {
                    alert('New payment method added to stripe');

                } else {
                    $.ajax({
                        // url: form.attr('action'),
                        url: temp_url,
                        method: 'post',
                        data: {
                            source: JSON.stringify(result.source)
                        },
                        success: function(data) {
                            if (form.attr('data-close-on-success')) {
                                $('#payment-modal').modal('hide');
                            }
                        },
                        error: function(xhr) {
                        },
                        complete: function(xhr) {
                            form.find('button').button('reset');
                        }
                    });
                }
            }
        };

        // cc
        (function() {
            var elements = stripe.elements();
            
            var cardNumberElement = elements.create('cardNumber', {
                style: style
            });
            cardNumberElement.mount('#card-number-element');

            var cardExpiryElement = elements.create('cardExpiry', {
                style: style
            });
            cardExpiryElement.mount('#card-expiry-element');

            var cardCvcElement = elements.create('cardCvc', {
                style: style
            });
            cardCvcElement.mount('#card-cvc-element');


            var cardBrandToPfClass = {
                'visa': 'pf-visa',
                'mastercard': 'pf-mastercard',
                'amex': 'pf-american-express',
                'discover': 'pf-discover',
                'diners': 'pf-diners',
                'jcb': 'pf-jcb',
                'unknown': 'pf-credit-card',
            }

            var setBrandIcon = function(brand) {
                var brandIconElement = document.getElementById('brand-icon');
                var pfClass = 'pf-credit-card';
                if (brand in cardBrandToPfClass) {
                    pfClass = cardBrandToPfClass[brand];
                }
                for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
                    brandIconElement.classList.remove(brandIconElement.classList[i]);
                }
                brandIconElement.classList.add('pf');
                brandIconElement.classList.add(pfClass);
            }

            cardNumberElement.on('change', function(event) {
                if (event.brand) {
                    setBrandIcon(event.brand);
                }

                setOutcome(event, cc_form);
            });

            cc_form.on('submit', function(e) {
                e.preventDefault();
                e.stopPropagation();

                cc_form.find('button').button('loading');

                var sourceData = {
                    owner: {
                        name: cc_form.find('input[name="name"]').val(),
                    },
                    //address_zip: document.getElementById('postal-code').value,
                };
                stripe.createSource(cardNumberElement, sourceData).then(function(result) {
                    setOutcome(result, cc_form)
                });

                return false;
            });
        })();

        // iban
        (function() {
            var elements = stripe.elements();

            var options = {
                style: style,
                supportedCountries: ['SEPA'],
                placeholderCountry: iban_form.attr('data-country') || ''
            };
			
            var iban = elements.create('iban', options);
            iban.mount('#iban-element');
            iban.on('change', function(event) {
                setOutcome(event, iban_form);
            }); 

            iban_form.on('submit', function(e) {
				
                iban_form.find('button').button('loading');

                var sourceData = {
                    type: 'sepa_debit',
                    currency: 'eur',
                    owner: {
                        name: iban_form.find('input[name="name"]').val(),
                        email: iban_form.attr('data-email')
                    },
                };

                stripe.createSource(iban, sourceData).then(function(result) {
                    setOutcome(result, iban_form);
                });

                return false;
            });

        })();
         
    })();

    $('#checkout').click(function() {
        var tos = $('#tos');
        if (!tos.is(':checked')) {
            tos.showPopover(tos.attr('data-error-text'), 3000);
            return 
        }

        $('#payment-modal').modal();
    });

    $('#checkout-no-tos').click(function() {
        $('#payment-modal').modal();
    });
	
	$('.stripe-add-payment').click(function() {
        $('#payment-modal').modal();
    });


    // set total value
    var setTotalCost = function(total) {
        $('#sub-total').text(toPrice(toNumber(total)));
    };

    var hidePayment = function() {
        $('#billing-area').removeClass('pre-confirm');
        $('#fn-free').show();
        $('#payment-summary').hide();
    };

    var resetPayment = function() {
        $('#billing-area').removeClass('pre-confirm');
        $('#fn-free').hide();
        $('#payment-summary').show();
    };

    // get coupon code 
    var getCouponCode = function(total) {
        return $('#coupon').val();
    };

    var removeCoupon = function() {
        var $this = $(this);
        $('#coupon-enter').show();
        $('#coupon-calculated').hide();

        $('#coupon').val('');
        resetPayment();
        return false;
    };
    $('#remove-coupon').click(removeCoupon);

    // recalc on server
    var serverCalc = function() {
        var submit_btn = $('#calc-order');
        var coupon_code = $('#coupon').val();

        var data = {
            'package': 'premium',
            'payment': {
                'coupon': getCouponCode()
            }
        };

        // ask server 
        $.ajax({
            beforeSend: function() {
                htmlLoading();
                submit_btn.button('loading');
            },
            url: submit_btn.attr('data-recalc-payment-url'),
            type: 'post',
            data: data,
            success: function(data) {
                $('#billing-area').addClass('pre-confirm');
                //setTotalCost(data.total);
                $('#net-total').text(data.net);
                $('#vat-percent').text(data.vat_percent);
                $('#vat-total').text(data.vat);
                $('#full-total').text(data.total);

                if ('coupon_value' in data) {
                    var coupon_calculated = $('#coupon-calculated');
                    coupon_calculated.find('.name').text(data.coupon_name);
                    coupon_calculated.find('.price').text(data.coupon_value);
                    coupon_calculated.show();
                    $('#coupon-enter').hide();
                }

                $('#coupon-area').hide();
            },
            complete: function() {
                submit_btn.button('reset');
            }
        });
    };
    
    $('#go-premium').click(function() {
        $('#tab-locations').addClass('go-premium');
        setTotalCost($(this).attr('data-cost'));

        $('#fn-free').hide();
        $('#payment-summary').show();
        openRightSidebar();
    });

    $('#dont-go-premium').click(function() {
        $('#tab-locations').removeClass('go-premium');
        hidePayment();
        closeRightSidebar();
    });

    // click on recalc
    $('.subscription-payment-form').on('submit', function() {
        serverCalc();
        return false;
    });

    $('#show-coupon-area').click(function() {
        var $this = $(this),
            coupon_area = $('#coupon-area');

        $('#billing-area').removeClass('pre-confirm');
        coupon_area.show().find('input').focus();
        return false;
    });

    $('#coupon-area .close').click(function() {
        var area = $('#coupon-area');
        area.find('input').val('');
        area.toggle();
    });

    $('#booked-features > li > span').click(function() {
        var $this = $(this), li = $this.closest('li');
        li.toggleClass('open');
    });

    $('#confirm-fontract-form').on('submit', function() {
        $(this).save(function() {
            var modal = $('#payment-modal');
            modal.find('#iban-name').val($('#company-person-first-name').val()+' '+$('#company-person-last-name').val());
            modal.modal('show');
        });
        return false;
    });
});

/* /recipes/view */
$( document ).ready(function() {

    var changed_recipe = $('body').find('input[name="changed_recipe"]').val();
    var show_display_reminder = $('body').find('input[name="show_display_reminder"]').val();
    if (changed_recipe == 1 && show_display_reminder == 1) {
        $('#change_recipe_notification_modal').modal('show');
    }

    var recipe_view = $('.recipe-view');
    if (!recipe_view.length) {
        return;
    }

    $('#recipe-info').on('shown.bs.collapse', function() {
        $('.btn-recipe-info').addClass('active'); 
    }).on('hidden.bs.collapse', function() {
        $('.btn-recipe-info').removeClass('active'); 
    });

    // remove controls if only one item
    $('.carousel').each(function() {
        var $this = $(this);
        if ($this.find('.item').length < 2) {
            $this.find('.carousel-control').hide();
        }
    });

    // recipe-view: check for empty values
    (function() {
        var no_quantity = true;
        if ($('.recipe-view .ing-quantity').each(function() {
            if ($.trim($(this).text()).length) {
                no_quantity = false;
                return false;
            }
        }).length && no_quantity) {
            $('.recipe-view .ingredients').addClass('no-quantity');
        }
        var no_costs = true;
        if ($('.recipe-view .ingredient-cost-div').each(function() {
            if ($.trim($(this).text()).length > 1) {
                no_costs = false;
                return false;
            }
        }).length && no_costs) {
            $('.recipe-view .ingredients').addClass('no-costs');
        }
    })();

    // recipe print options
    $('.recipe-view-print-options :checkbox').on('click load', function(e) {
        var $this = $(this), name = $this.attr('name'), 
            checked = $this.is(':checked'), view = $('body')

        if (name.match(/^(title|description|categories|price)$/)) {
            view.find('.recipe-'+name).toggleClass('hidden-print', !checked)
        } else
        if (name.match(/^(ingredients|allergens|nutritions|additives|weight)$/)) {
            view.find('.'+name).toggleClass('hidden-print', !checked)
        } else
        if (name == 'comments') {
            view.find('.recipe-comments, .subrecipe-comment').toggleClass('hidden-print', !checked)
        } else
        if (name == 'ingredients_allergens') {
            view.find('.ingredients .ing-allergens').toggleClass('hidden-print', !checked)
        } else
        if (name == 'ingredients_additives') {
            view.find('.ingredients .ing-additives').toggleClass('hidden-print', !checked)
        } else
        if (name == 'gallery') {
            view.find('.recipe-gallery-wrapper').toggleClass('hidden-print', !checked)
        } else
        if (name == 'disclaimer') {
            view.find('.disclaimer-info').toggleClass('hidden-print', !checked)
        } else
        if (name == 'photo-large') {
            view.toggleClass('photo-large', checked)
        } else
        if (name == 'ingredients_prices') {
            view.find('.ingredient-cost-div').toggleClass('hidden-print', !checked)
        } else
        if (name == 'cmcalc') {
            view.find('.cmcalc4').toggleClass('hidden-print', !checked)
        }
        if (name == 'subrecipes') {
            view.find('.subrecipe').toggleClass('hidden-print', !checked)

            if (e.type == 'click' && checked && !view.find('.ingredients.resolve-subrecipes').length) {
                document.location.href = document.location.href + '?subrecipes=1' 
            }
        }
    }).trigger('load');

    // update costs
    var cost_update = function(rows) {
        var params = [];
        rows.each(function() {
            var row = $(this);
            params.push({
                id: row.attr('data-main-id'),
                ingredient_id: row.attr('data-id'),
                ing_recipe_id: row.attr('data-ing-recipe-id'),
                quantity: row.find('.quantity').text().trim(),
                quantity_unit: row.find('.unit').attr('data-quantity-unit')
            })
        });

        $.ajax({
            url: $('.recipe-view').attr('data-cmcalc-url'),
            method: 'post',
            data: { ingredients: params },
            beforeSend: function() {
                $('html').addClass('async-loading');
            },
            success: function(data) {
                if (data && 'costs' in data) {
                    data.costs = data['costs'];
                    for (var i=0, len=data.costs.length; i<len; i++) {
                        var icost = data.costs[i],
                            main_id = icost.id,
                            iid = icost.ingredient_id,
                            irid = icost.ing_recipe_id;

                        if (main_id) {
                            $('[data-main-id="'+main_id+'"]').each(function() {
                                var cost = $(this).find('.ingredient-cost-div value'),
                                    cost_fld = $(this).find('.ingredient-cost');

                                if ('cost' in icost) {
                                    cost.text(toPrice(icost['cost'], 3));
                                    cost_fld.val(toPrice(icost['cost'], 3));
                                }
                            });
                        }
                    }
                }
            },
            complete: function() {
                $('html').removeClass('async-loading');
            }
        });
    };

    var update_cmcalc_data = function() {
        var persons = toNumber($('input[name=persons-orig]').val());
        var ing_cost = $('#ingredient-cost-sum');

        var old_val = ing_cost.val();
        var sum = 0, error = false;
        var costs = $('.recipe-view .ingredient-cost');

        costs.each(function() {
            var $this = $(this);
            var cost = $this.val();

            if (cost) {
                sum += toNumber(cost);
            } else {
                error = true;
            }
        });

        sum = sum/persons;
        sum = toPrice(sum);
        ing_cost.val(sum).trigger('update');

        if (old_val != sum) { ing_cost.blinkMe() }
    };

    // recalculation
    var update_ingredient_data = function(persons) {
        // use hidden original field which never changes
        var persons_orig = toNumber($('input[name=persons-orig]').val());

        // update ingredient quantities
        $('.ingredients .row.ing .ing-quantity .quantity').each(function () {
            var $this = $(this), quantity_value;

            // save original value first time to avoid rounding errors
            if (!$this.data('orig-value')) {
                quantity_value = $this.text().trim();

                if (quantity_value && quantity_value.match(/^[0-9]+([,.][0-9]+)?$/)) {
                    $this.data('orig-value', toNumber(quantity_value));
                } else {
                    return;
                }
            }
            quantity_value = $this.data('orig-value');

            if (quantity_value && quantity_value > 0) {
                quantity_value = +(quantity_value * persons / persons_orig).toFixed(4);
                $this.text(localizedNumber(quantity_value));
            }
        });

        // update ingredient weight total
        var weight_total = $('.weight .total .quantity:visible'),
            weight_total_value = '0';

        // save original value first time to avoid rounding errors
        if (!weight_total.data('orig-value')) {
            weight_total_value = weight_total.text().trim();

            if (weight_total_value && weight_total_value.match(/^[0-9]+([,.][0-9]+)?$/)) {
                weight_total.data('orig-value', toNumber(weight_total_value));
            } else {
                return;
            }
        }
        weight_total_value = weight_total.data('orig-value');

        if (weight_total_value && weight_total_value > 0) {
            weight_total_value = +(weight_total_value * persons / persons_orig).toFixed(2);
            $('.weight .total .quantity').text(localizedNumber(weight_total_value));
        }

        // update ingredient costs
        cost_update($('.ingredients .row.ing'));
    };

    // ingredient's quantities recalculation on recipe's persons number difference
    $('.ingredient-recalculate').on('keyup keydown keypress', function (e) {
        var $this = $(this);
        var persons = toNumber($this.val().trim() || $this.attr('placeholder'));

        clearTimeout($this.data('update_ingredient_data_timer'));
        $this.data('update_ingredient_data_timer', setTimeout(function () {
            update_ingredient_data(persons);

            // rewrite print url
            var btn_print = $('.btn-recipe-print');
            if (!btn_print.data('href')) {
                // cache original url for later
                btn_print.data('href', btn_print.attr('href'));
            }
            btn_print.attr('href', btn_print.data('href') + '?persons=' + persons)
        }, 800));
    });

    if ($('.print-persons').length) {
        //update_ingredient_data(toNumber($('.print-persons').text().trim()));
    }

    $('.lang-tabs a').on('click', function () {
        var lang = $(this).attr('href');

        $('.tab-pane').removeClass('in active');
        $(lang).addClass('in active');
        $(lang + '-ings').addClass('in active');
        $(lang + '-comments').addClass('in active');

        $('.lang-tabs li').removeClass('active');
        $('.lang-tabs li[data-lang="' + lang.replace('#lang-', '') + '"]').addClass('active');
    });

    $('#order-from-recipe-button').click(function() {
        window.location.href = $(this).attr('href') + '?portions=' + $('[name=persons]').val();
        return false;
    });

    $(".row.ing").on("click", function(event) {
        if ($(event.target).closest('.recipe-view-dropdown-toggle').length) {
            return;
        }
        var subproductWrapperDiv = $(this).find(".subproduct-wrapper");
        subproductWrapperDiv.slideToggle();
    });

    $('.dropdown.recipe-view-dropdown-toggle').on('click', function (event) {
        event.stopPropagation();
    });

    ////////////////////////
    // end of document ready
});

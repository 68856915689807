$( document )
    .ready(function() {
        $('body').on('click tap', '.ccc-connection .add-line', function() {
            var $this = $(this),
                cccline = $this.closest('.ccc-line');
                cccline_clone = cccline.clone(true, true);

            cccline.removeClass('new-line');
            cccline.after(cccline_clone);

        }).on('click tap', '.ccc-connection .remove-line', function() {
            var $this = $(this);
            $this.closest('.ccc-line').remove();

        }).on('change', '.ccc-connection .ccc-category', function() {
            var $this = $(this), 
                id = $this.val(),
                cccline = $this.closest('.ccc-line');

            //cccline.find('.ccc-costcenter')
        });

        $("body").on("click tap", "#add-row-product-cc", function(event) {
            var $target = $(event.target);
            var productGroups = $target.data("productgroups");
            var restaurants = $target.data("restaurants");

            var subButton = document.getElementById("submit-form");
            var form = document.getElementById("ccc-connection").getElementsByTagName("form")[0];
            var row = form.getElementsByClassName("row");
            var lastRow = row[row.length - 1];
            var numOfSelects = parseInt(lastRow.dataset.id, 10) + 1;

            var div = document.createElement("div");
            div.classList.add("row");
            div.id = "row-" + numOfSelects;
            div.setAttribute("data-id", "" + numOfSelects);

            var select1 = document.createElement("select");
            select1.id = "category-" + numOfSelects;
            select1.name = "category-" + numOfSelects;
            select1.classList.add("col-xs-6");
            select1.classList.add("col-sm-3");
            select1.classList.add("col-lg-4");
            select1.classList.add("select-input");
            select1.classList.add("inline");

            var optionBase = document.createElement("option");
            optionBase.value = "-1";
            optionBase.innerHTML = "Select";

            select1.appendChild(optionBase);

            var option;
            var i;
            for (i = 0; i < productGroups.length; i++) {
                option = document.createElement("option");
                option.value = productGroups[i].id;
                option.innerHTML = productGroups[i].name;

                select1.appendChild(option);
            }

            var separatorDiv = document.createElement("div");
            separatorDiv.classList.add("col-xs-1");
            separatorDiv.classList.add("col-sm-1");
            separatorDiv.classList.add("col-lg-1");
            separatorDiv.classList.add("form-group");
            separatorDiv.classList.add("inline");
            separatorDiv.innerHTML = "&nbsp";

            var select2 = document.createElement("select");
            select2.id = "costCenter-" + numOfSelects;
            select2.name = "costCenter-" + numOfSelects;
            select2.classList.add("col-xs-6");
            select2.classList.add("col-sm-3");
            select2.classList.add("col-lg-4");
            select2.classList.add("select-input");
            select2.classList.add("inline");

            var optgroup;
            for(i = 0; i < restaurants.length; i++) {
                optgroup = document.createElement("optgroup");
                optgroup.label = restaurants[i].name;
                for(var j = 0; j < restaurants[i].cost_centers.length; j++) {
                    option = document.createElement("option");
                    option.value = restaurants[i].cost_centers[j].id;
                    option.innerHTML = restaurants[i].cost_centers[j].name;

                    optgroup.appendChild(option);
                }

                select2.appendChild(optgroup);
            }

            var divDelete = document.createElement("div");
            divDelete.id = "divDelete-" + numOfSelects;
            divDelete.classList.add("col-xs-1");
            divDelete.classList.add("col-sm-1");
            divDelete.classList.add("col-lg-1");
            divDelete.classList.add("inline");

            var anchorButton = document.createElement("a");
            anchorButton.href = "#";
            anchorButton.innerHTML = "Löschen";
            anchorButton.classList.add("btn");
            anchorButton.classList.add("btn-danger");
            anchorButton.classList.add("select-input");
            anchorButton.classList.add("deleteButtons");
            anchorButton.setAttribute("data-row", "" + numOfSelects);

            divDelete.appendChild(anchorButton);

            var breakRule = document.createElement("br");
            breakRule.id = "break-" + numOfSelects;

            div.appendChild(select1);
            div.appendChild(separatorDiv);
            div.appendChild(select2);
            div.appendChild(divDelete);
            form.insertBefore(div, subButton);
            form.insertBefore(breakRule, subButton);
        }).on("click tap", ".deleteButtons", function(event) {
            var $target = $(event.target);
            var row = $target.data("row");
            var hidden = document.createElement('input');
            hidden.type = 'hidden';
            hidden.setAttribute("data-id", "" + row);
            hidden.name = "delete-" + row;
            hidden.value = "-1";

            var rowObj = document.getElementById("row-" + row);
            var breakObj = document.getElementById("break-" + row);
            rowObj.appendChild(hidden);
            rowObj.style.display = "none";
            breakObj.remove();
        });

        $("body").on("click", ".connect-button", function(event) {
            var pos_identifier = $(this).parent().find('.connect-button-identifier')[0].value;
            var form = $('#' + pos_identifier)
            if (form[0].checkValidity()) {
                event.preventDefault();
                $.ajax({
                    url: ('/profile/services/' + pos_identifier + '/question'),
                    type: 'post',
                    success: function(data) {
                        if (data['modal_confirmed'] && form[0].checkValidity()) {
                            form.submit();
                        }
                    }
                });
            }
        });
		
		$("body").on("click", ".pos-sync", function() {
			var $this = $(this);
			var temp = document.getElementById('posSync');
			var isSubuser = temp.getAttribute('isSubuser');
			var isMaster = temp.getAttribute('isMaster');
			var hasSubuserPerm = temp.getAttribute('hasSubuserPerm');
			if (isSubuser && hasSubuserPerm) {
				return true;
			}
			else if (isSubuser && !hasSubuserPerm) {
				$.ajax({
					url: $this.attr('href'),
					type: 'get',
				});
				return false;
			}
			else if (isMaster) {
				return true;
			}
		});
		
		$("body").on("click", ".pos-conf", function() {
			var $this = $(this);
			var temp = document.getElementById('posConf');
			var isSubuser = temp.getAttribute('isSubuser');
			var isMaster = temp.getAttribute('isMaster');
			var hasSubuserPerm = temp.getAttribute('hasSubuserPerm');
			if (isSubuser && hasSubuserPerm) {
				return true;
			}
			else if (isSubuser && !hasSubuserPerm) {
				$.ajax({
					url: $this.attr('href'),
					type: 'get',
				});
				return false;
			}
			else if (isMaster) {
				return true;
			}
		});
		
    });

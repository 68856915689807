// contribution margin in recipe
import Animations from "./animations";
import FoodNotifyPaginator from "./paginator";
import noUiSlider from '../../assets/vendor/nouislider/distribute/nouislider.min.js';

export function init_cmcalc4() {
    // cmcalc modal edit
    $('body').on('click', '.cmcalc-edit-url + .ingredient-cost', function () {
        $(this).prev('.cmcalc-edit-url').click();
    });
    $('body').on('click', '.recipe-view .ing .name a.cmcalc, .cmcalc-edit-url', function () {
        var $this = $(this).addClass('last-clicked-cmcalc');

        // subrecipe? open in a new window
        if ($this.closest('.ing-row').attr('data-recipe') == 'true') {
            window.open($this.attr('href'));
        } else {
            $this.async();
        }
        return false;
    });

    if (!$('.cmcalc4.db4').length) {
        return;
    }

    $('.cmcalc4.db4.not-enabled .toggle-view').click(function() {
        var $this = $(this), quest = $this.closest('.quest');
        quest.siblings('.quest').addClass('el-visible')
        quest.removeClass('el-visible');
    });


    // turn details on/off
    $('.cmcalc4.db4 .profit-margin-details .cmcalc-details-turn').on('click', function(e) {
        e.stopPropagation();
        $(this).closest('.cmcalc4').find('.profit-margin-details').toggleClass('turned');
    });

    $('.cmcalc4.db4 input').attr('autocomplete', 'off');

    var chart, slider;
    var country = $('html').attr('country') || 'de';

    // format x axes
    var formatX = function(str, long) {
        var date = new Date(str*1000);
        var options = {
            day: '2-digit',
            month: '2-digit'
        };
        if (long) {
            options.year = 'numeric';
        }
        return (new Intl.DateTimeFormat(country, options).format(date));
    };
    // format y axes
    var formatY = function(str, long) {
        return str.toLocaleString() + '%';
    };

    // convert string to number
    var toNr = function (s) {
        if (s) {
            s = $.trim(s);
            return s ? toNumber(s) : 0
        }
        return 0;
    };

    // typing in input field? allow everything except enter
    var ignoreKeys = function (e) {
        if ('which' in e && e.which && $.inArray(e.which, [9, 13]) >= 0) {
            if (e.which == 13) {    // enter
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            return true;
        }
        return false;
    };

    // update all points of the ist/soll curve
    var updateChartPoints = function(handle, margins) {
        if (chart) {
            let it = 1;
            while(margins.length > 0) {
                var node = chart.data.datasets[handle];
                var last = node.data.length - it;
                var value = node.data[last];
                var margin_from = chart.data.datasets[2].data[last];
                var margin_to = chart.data.datasets[3].data[last];

                if (value >= margin_from) {
                    if (value <= margin_to) {
                        node.pointBackgroundColor[last] = 'rgb(120, 184, 44)';
                        node.pointBorderColor[last] = 'rgb(120, 184, 44)';
                        margins.pop();
                        it++;
                        continue;
                    }
                }

                node.pointBackgroundColor[last] = 'rgb(255, 0, 0)';
                node.pointBorderColor[last] = 'rgb(255, 0, 0)';
                margins.pop();
                it++;
            }
        }
    } 

    // update last point of the ist/soll curve
    var updateChartLastPoint = function(handle, value) {
        if (chart) {
            var node = chart.data.datasets[handle];
            node.data[node.data.length-1] = Math.round(value);
            chart.update();
            checkChartLastPointColor(handle);
        }
    };
    // update color of the point
    var checkChartLastPointColor = function(handle) {
        if (chart) {
            var node = chart.data.datasets[handle];
            var last = node.data.length-1;
            var value = node.data[last];
            var margin_from = chart.data.datasets[2].data[last];
            var margin_to = chart.data.datasets[3].data[last];

            if (value >= margin_from) {
                if (value <= margin_to) {
                    node.pointBackgroundColor[last] = 'rgb(120, 184, 44)';
                    node.pointBorderColor[last] = 'rgb(120, 184, 44)';
                    chart.update();
                    return;
                }
            }

            node.pointBackgroundColor[last] = 'rgb(255, 0, 0)';
            node.pointBorderColor[last] = 'rgb(255, 0, 0)';
            chart.update();
        }
    };

    // get number
    var getSum = function(obj) {
        var val = obj.data('_val');
        if (val && val.length) {
            return val;
        }
        return toNr((obj.prop("tagName") == 'INPUT' ? obj.val() : obj.text()) || 0);
    };
    // set number
    var setSum = function(obj, val, decimals) {
        // save full number in data
        var val = toNr(val);
        obj.data('_val', val);

        if (decimals == null || decimals == undefined) {
            decimals = 2;
        }

        // changed?
        var changed = (getSum(obj).toFixed(2) != val.toFixed(2));

        // show nice number
        obj.text(toPrice(val, decimals));
        obj.closest('div').find('input[type=text]').val(val);

        // blink if changed
        if (changed) {
            obj.parent().blinkMe();
        }

        // update curve if needed
        if (changed) {
            var id = obj.attr('id');

            if (id == 'ingredient-cost-sales-actual') {
                updateChartLastPoint(0, val);
                checkChartLastPointColor(0);
            } else
            if (id == 'ingredient-cost-sales-budget') {
                updateChartLastPoint(1, val);
                checkChartLastPointColor(1);
            }
        }
    };

    // chart
    $('[data-chart=true][data-chart-recipe=true][data-chart-url]').each(function() {
        var $this = $(this);

        var default_data = {
            type: 'line',
            data: {
                labels: [],
                datasets: [
                    // data ist
                    {
                        data: [],
                        label: false,
                        backgroundColor: 'rgba(78, 150, 183, .4)',
                        borderColor: 'rgba(255, 255, 255, 1)',
                        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                        pointHitRadius: 10,
                        borderWidth: 1,
                        fill: 'origin',
                        lineTension: 0
                    },
                    // data soll
                    {
                        data: [],
                        label: false,
                        backgroundColor: 'rgba(78, 150, 183, .4)',
                        borderColor: 'rgba(255, 255, 0, 1)',
                        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                        pointHitRadius: 10,
                        borderWidth: 1,
                        borderDash: [10,5],
                        fill: false,
                        lineTension: 0
                    },
                    // margins
                    {
                        label: false,
                        data: [],
                        borderColor: 'rgba(255, 255, 255, .2)',
                        backgroundColor: 'rgba(119, 184, 44,.6)',
                        borderWidth: 1,
                        fill: '+1',
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointHoverBorderColor: 'transparent'
                    },
                    {
                        label: false,
                        data: [],
                        borderColor: 'rgba(255, 255, 255, .2)',
                        borderWidth: 1,
                        fill: false,
                        pointRadius: 0,
                        pointBackgroundColor: 'transparent',
                        pointHoverBorderColor: 'transparent'
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //beginAtZero:true,
                            suggestedMin: 10,
                            suggestedMax: 60,
                            stepSize: 10,
                            fontColor: 'rgba(255, 255, 255, 1)',
                            callback: function(value, index, values) {
                                return formatY(value);
                            }
                        },
                        gridLines: {
                            color: 'rgba(255, 255, 255, .1)',
                            zeroLineColor: 'rgba(255, 255, 255, .1)'
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            fontColor: 'rgba(255, 255, 255, 1)',
                            callback: function(value, index, values) {
                                return formatX(value);
                            }
                        },
                        gridLines: {
                            color: 'rgba(255, 255, 255, .1)',
                            zeroLineColor: 'rgba(255, 255, 255, .1)'
                        },
                    }]
                },
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgba(255,255,255,1)'
                    }
                },
                tooltips: {
                    displayColors: false,
                    callbacks: {
                        label: function(item, data) {
                            var index = item.index;
                            var y = data.datasets[item.datasetIndex].data;
                            var x = data.labels;

                            return formatY(y[index], true) + ' (' + formatX(x[index], true)  + ')';
                        }
                        //title: () => null,
                    }
                },
            }
        };

        var updateChart = function() {
            $.get($this.attr('data-chart-url'), function(data) {
                var local_data = default_data;
                local_data.type = data.type || local_data.type;
                local_data.data.labels = data.labels || [];
                local_data.data.datasets[0].pointBackgroundColor = data.pointBackgroundColor;
                local_data.data.datasets[0].pointBorderColor = data.pointBorderColor;
                //local_data.data.datasets[1].pointBackgroundColor = $.extend({}, data.pointBackgroundColor);
                //local_data.data.datasets[1].pointBorderColor = $.extend({}, data.pointBorderColor);
                local_data.data.datasets[1].pointBackgroundColor = [];
                local_data.data.datasets[1].pointBorderColor = [];
                local_data.data.datasets[0].data = data.data.ist || [];
                local_data.data.datasets[1].data = data.data.soll || [];
                data.margins.bottom.forEach(function(e, i) {
                    data.margins.bottom[i] = $this.parents('.cmcalc-block').next('.profit-margin-data').find('.profit-margin-from-hidden-input').val();
                });
                data.margins.top.forEach(function(e, i) {
                    data.margins.top[i] = $this.parents('.cmcalc-block').next('.profit-margin-data').find('.profit-margin-to-hidden-input').val();
                });
                local_data.data.datasets[2].data = data.margins.bottom || [];
                local_data.data.datasets[3].data = data.margins.top || [];

                if (!chart) {
                    chart = new Chart($this.get(0).getContext('2d'), local_data);
                    $this.data('chart', chart);
                } else {
                    chart.data = local_data.data;
                    chart.update();
                }

                // reset slider
                if (slider && slider.length) {
//                    slider.trigger('update', [ data.margins.bottom[0], data.margins.top[0] ]);
                }

                let margins_bottom_tmp = local_data.data.datasets[2].data.slice();
                let margins_top_tmp = local_data.data.datasets[3].data.slice();
                updateChartPoints(0, margins_bottom_tmp);
                updateChartPoints(1, margins_top_tmp);
                chart.update();
            });
        };

        updateChart();
        $this.on('click', updateChart);
    });

    (function() {
        slider = $('#profit-margin-slider');
        if (!slider.length) {
            return
        }
        var slider_node = slider.get(0);

        var cmcalc_block = slider.parents('.cmcalc-block');
        var margin_from = cmcalc_block.next('.profit-margin-data') ? cmcalc_block.next('.profit-margin-data').find('.profit-margin-from-hidden-input').val() : 0;
        var margin_to = cmcalc_block.next('.profit-margin-data') ? cmcalc_block.next('.profit-margin-data').find('.profit-margin-to-hidden-input').val() : 0;
        slider.attr('data-from', margin_from);
        slider.attr('data-to', margin_to);

        // add external event for updating slider
        slider.on('update', function(e, margin_from, margin_to) {
            slider_node.noUiSlider.set([margin_from, margin_to]);
        })

        noUiSlider.create(slider_node, {
            start: [ slider.attr('data-from') || 0, slider.attr('data-to') || 0 ],
            connect: true,
            range: {
                'min': 0,
                'max': 100
            }
        });

        if (slider.closest('.cmcalc4').hasClass('read-only')) {
            slider_node.setAttribute('disabled', true);
        }

        var margins = {
            0: $(slider).find('.noUi-handle-lower'),
            1: $(slider).find('.noUi-handle-upper')
        };
        var updateHandleValue = function(handle, val) {
            margins[handle].attr('data-content', val+'%');
        };
        updateHandleValue(0, slider.attr('data-from') || 0);
        updateHandleValue(1, slider.attr('data-to') || 0);

        // on slider update, update chart too
        slider_node.noUiSlider.on('update', function(values, handle) {
            if (chart) {
                var val = Math.round(values[handle]);
                var node = chart.data.datasets[handle+2];
                node.data = Array(node.data.length).fill(val);
                chart.update();

                updateHandleValue(handle, val);

                let margins_bottom_tmp = chart.data.datasets[2].data.slice();
                let margins_top_tmp = chart.data.datasets[3].data.slice();
                updateChartPoints(0, margins_bottom_tmp);
                updateChartPoints(1, margins_top_tmp);
                chart.update();
            }
        });
        // on slider end, update the hidden fields for saving in the recipe
        slider_node.noUiSlider.on('end', function(values) {
            slider.parents('.cmcalc-block').next('.profit-margin-data').find('.profit-margin-from-hidden-input').val(toNr(Math.round(values[0])));
            slider.parents('.cmcalc-block').next('.profit-margin-data').find('.profit-margin-to-hidden-input').val(toNr(Math.round(values[1])));
        });
    })();

    // edit button
    $('.cmcalc4:not(.read-only)').find('.cmcalc-edit-btn, .editable > span').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        var div = $(this).closest('div');
        div.toggleClass('editing');
        var input = div.find('input[type=text]');
        var val = div.find('.value').text();
        input.val(val).select();
    });

    var escape_triggered = false;
    // changes in profit margin input
    $('.profit-margin-details .numbers input').on('keydown keypress keyup blur', function(e) {
        var $this = $(this);

        // workaround
        if (escape_triggered) {
            return;
        }

        // blur or enter
        if (e.type == 'blur' || e.which == 13) {
            $this.trigger('blurit');
        } else
        // esc
        if (e.type == 'keyup' && e.which === 27) {
            // terrible workaround to stop the circle
            escape_triggered = true;
            $this.data('escape_triggered', setTimeout(function() {
                escape_triggered = false;
            }, 200));

            $this.trigger('escape');
            return false;
        }

        // because $this.val() is now changed
        if (e.type == 'keyup') {
            var val = toNr($this.val());

            // up
            if (e.which == 38) {
                val += 1;
                $this.val(toPrice(val));
            } else
            // down
            if (e.which == 40) {
                val -= 1;
                $this.val(toPrice(val));
            }

            // update on the fly
            var name = $this.attr('name');
            if (name == 'sales_budget') {
                updateChartLastPoint(1, val);
            }
        }

    // update on "blurit"
    }).on('blurit escape', function(e) {
        var input = $(this);
        var div = input.closest('div');

        if (e.type == 'blurit') {
            var value = div.find('.value');
            setSum(value, toNr(input.val()));
            value.trigger('updated');
        }

        div.removeClass('editing');
        return false;
    });

    // recalc
    (function() {
        var cmcalc_wrapper = $('.cmcalc4-wrapper');
        if (cmcalc_wrapper.length) {
            $('body').on('refresh', '.recipe-view[data-cmcalc-update-url] .ing[data-id]', function () {
                var row = $(this);

                $.ajax({
                    url: row.closest('.recipe-view').attr('data-cmcalc-update-url'),
                    type: 'post',
                    data: {
                        id: row.attr('data-id'),
                    },
                    success: function (data) {
                        if (data.cost) {
                            row.find('.ingredient-cost-div').show().find('value').text(toPrice(data.cost, 3));
                            row.find('.ingredient-cost').val(toPrice(data.cost, 3)).trigger('change');
                            row.closest('.ingredients').removeClass('no-costs');
 
                            // force recalculation
                            $('[name=persons]').trigger('keydown');
                        }
                    }
                });
            });


            // cmcalc
            var ing_cost_overview = $('#ingredient-cost-sum-overview');
            var ing_cost = $('#ingredient-cost-sum');
            var ist_ing_cost = $('#ist-ingredient-cost-sum');
            var ist_netto_calc = $('#ist-ingredient-cost-netto-calc');
            var netto_calc = $('#ingredient-cost-netto-calc');
            var brutto_overview = $('#ingredient-cost-brutto-overview');
            var brutto = $('#ingredient-cost-brutto');
            var brutto_calc = $('#ingredient-cost-brutto-calc');
            var sales_actual_overview = $('#ingredient-cost-sales-actual-overview');
            var sales_actual = $('#ingredient-cost-sales-actual');
            var sales_budget = $('#ingredient-cost-sales-budget');
            //var vat = $('#ingredient-cost-vat');
            var ist_vat = $('.restaurant-price-data.open .inhouse_recipe_vat:visible, .restaurant-price-data.open .sales_recipe_vat:visible, .restaurant-price-data.open .view_sales_recipe_vat, .restaurant-price-data.open .all_locations_inhouse_vat:visible, .restaurant-price-data.open .all_locations_sales_vat:visible, .restaurant-price-data.open view_all_locations_inhouse_vat, .restaurant-price-data.open view_all_locations_sales_vat, .restaurant-price-data.open .view_sales_vat, .restaurant-price-data.open .view_sales_recipe_vat').first();
            var vat = ist_vat;
            var vat_overview = $('#ingredient-cost-vat-overview');
            var ist_surcharge_absolute = $('#ist-ingredient-cost-surcharge-absolute');
            var ist_surcharge = $('#ist-ingredient-cost-surcharge');
            var surcharge = $('#ingredient-cost-surcharge');
            var surcharge_absolute = $('#ingredient-cost-surcharge-absolute');
            var persons = $('input[name=persons]');
            var recipe_price = $('.restaurant-price-data.open .inhouse_recipe_price:visible, .restaurant-price-data.open .sales_recipe_price:visible, .restaurant-price-data.open .view_sales_recipe_price, .restaurant-price-data.open .all_locations_inhouse_price:visible, .restaurant-price-data.open .all_locations_sales_price:visible, .restaurant-price-data.open .view_all_locations_inhouse_price, .restaurant-price-data.open .view_all_locations_sales_price').first();
            var recipe_price_netto = $('.restaurant-price-data.open .inhouse_recipe_price_netto');
            var portion_size = $('input[name=portion_size]');
            var brutto_netto_select = $('[name=price-type-selection]');

            setTimeout(function() {
                recipe_price.trigger('updated')
            }, 300)

            brutto_netto_select.on('updated', function() {
                recipe_price.trigger('updated-netto');
            });

            // update netto-ist
            recipe_price_netto.on('change keyup keydown', function (e) {
                var $this = $(this);

                if (e.type != 'updated') {
                    if ('which' in e && e.which &&
                        (
                            (e.which <= 58) ||
                            ($.inArray(e.which,
                                [
                                    96,97,98,99,100,101,102,103,104,105
                                ]
                            ) >= 0) ||
                            ($.inArray(e.which,[110,188,190]) >= 0 && !$this.val().match(/[,\.]/))
                        )
                    ) {
                    } else {
                        return false;
                    }
                }

                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    var price_amount = toNr($this.val());

                    var vat_amount = toNr(ist_vat.val() || 0);

                    var brutto_price = 0;
                    if (vat_amount == 0 || price_amount == 0) {
                        brutto_price = price_amount;
                    } else {
                        brutto_price = price_amount/100*(100+vat_amount);
                    }
                    recipe_price.val(toPrice(brutto_price));
                    recipe_price.trigger('updated-netto');

                }, e.type == 'keyup' ? 400 : 0));
            });

            // update brutto-ist
            recipe_price.on('change keyup keydown updated updated-netto', function (e) {
                var $this = $(this);

                if (e.type != 'updated' && e.type != 'updated-netto') {
                    if ('which' in e && e.which &&
                        (
                            (e.which <= 58) ||
                            ($.inArray(e.which,
                                [
                                    96,97,98,99,100,101,102,103,104,105
                                ]
                            ) >= 0) ||
                            ($.inArray(e.which,[110,188,190]) >= 0 && !$this.val().match(/[,\.]/))
                        )
                    ) {
                    } else {
                        return false;
                    }
                }

                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    var price_amount = toNr($this.val());
                    var vat_amount = toNr(ist_vat.val() || 0);

                    if (brutto_netto_select.val() == 'netto' && vat_amount > 0 && price_amount > 0) {
                        price_amount += price_amount/100*vat_amount;
                    }
                    

                    var price = toPrice(price_amount);

                    if (e.type != 'updated' && e.type != 'updated-netto') {
                        var netto_price = 0;
                        if (vat_amount == 0 || price_amount == 0) {
                            netto_price = price_amount;
                        } else {
                            netto_price = price_amount/(100+vat_amount)*100;
                        }
                        recipe_price_netto.val(toPrice(netto_price));
                    }

                    brutto.text(price);
                    brutto_overview.text(price);

                    if (e.type == 'updated-netto') {
                        brutto.trigger('updated-netto');
                    } else {
                        brutto.trigger('update');
                    }
                }, e.type == 'keyup' ? 400 : 0));
            });

            //cmcalc_wrapper.find('button.close').on('click', function () {
            //    $('.cmcalc-open').triggerHandler('click');
            //});
            $('.cmcalc-open').click(function () {
                var $this = $(this);
                $this.trigger('toggle-label');
                var open = $('.ingredients, .recipes').toggleClass('cmcalc').hasClass('cmcalc');
                var wrapper = $('.cmcalc-master-wrap');
                setTimeout(function () {
                    open ? wrapper.fadeIn() : wrapper.fadeOut();
                    setTimeout(function () {
                        wrapper.toggleClass('popover-help-wrapper', open)
                    }, 600);
                    $.ajax({
                        url: $this.attr('data-url'),
                        type: 'post',
                        data: {
                            k: 'show-cmcalc',
                            v: open
                        }
                    });
                }, 200)
            });

            // ingredient cost changed?
            $('body').on('change force-change start', '.ingredient-cost', function (e) {
                var old_val = ing_cost.val();
                var sum = 0, error = false;
                var costs = $('.ing-row.ingredient-selected .ingredient-cost, .recipe-row .ingredient-cost, .recipe-view .tab-pane.active .ingredient-cost');

                // get the sum
                costs.each(function () {
                    var $this = $(this);
                    var cost = $this.val();

                    if (cost) {
                        sum += toNumber(cost);
                    } else {
                        error = true;
                    }
                });

                // calc per person
                var persons_val = toNumber(persons.val()) || 1;
                sum = sum / persons_val;
                sum = toPrice(sum);

                // set new value for all fields which are showing ingredient cost sum
                ing_cost_overview.text(sum);
                ist_ing_cost.text(sum).trigger('update');
                ing_cost.text(sum).trigger('update');   // inform about change

                if (old_val != sum) {
                    ing_cost.blinkMe()
                }

                // show error fields if needed
                cmcalc_wrapper.find('.missing-values').toggle(error);
                cmcalc_wrapper.find('.values-ok').toggle(!error);

            // IST: ingredient costs changed
            }).on('update updated', '#ist-ingredient-cost-sum', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    // ingredient costs
                    var ing_cost_sum = getSum(ist_ing_cost);
                    var netto_sum = getSum(ist_netto_calc);

                    // sales
                    var sales_actual_sum = ing_cost_sum * 100 / netto_sum;
                    setSum(sales_actual, sales_actual_sum);
                    setSum(sales_actual_overview, sales_actual_sum);

                    // surcharge absolute
                    var surcharge_absolute_sum = netto_sum-ing_cost_sum;
                    ist_surcharge_absolute.text(toPrice(surcharge_absolute_sum));

                    // surcharges
                    var ing_cost_sum = getSum(ing_cost);
                    var surcharge_sum_absolute = netto_sum - ing_cost_sum;
                    var surcharge_sum = ((netto_sum - ing_cost_sum) / ing_cost_sum) * 100;
                    setSum(ist_surcharge_absolute, surcharge_sum_absolute);
                    setSum(ist_surcharge, surcharge_sum);

                }, e.type == 'keyup' ? 400 : 0));

            // IST: vat
            }).on('updated keydown keyup', '.restaurant-price-data.open .inhouse_recipe_vat:visible,.restaurant-price-data.open .sales_recipe_vat:visible', function (e) {
                setSum(vat_overview, $(this).val(), 0);
                brutto.trigger('updated');
                brutto_calc.trigger('updated');

            // IST: calc back from brutto sum
            }).on('keyup update updated updated-netto blur focusout', '#ingredient-cost-brutto', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    var ing_cost_sum = getSum(ist_ing_cost);
                    var brutto_sum = getSum(brutto);
                    var vat_val = getSum(ist_vat);

                    // netto
                    var netto_sum = brutto_sum - brutto_sum * (vat_val / (100 + vat_val));
                    setSum(ist_netto_calc, netto_sum);

                    if (e.type != 'updated-netto') {
                        recipe_price_netto.val(toPrice(netto_sum));
                    }

                    // sales
                    var sales_actual_sum = ing_cost_sum * 100 / netto_sum;
                    setSum(sales_actual, sales_actual_sum);
                    setSum(sales_actual_overview, sales_actual_sum);

                    if (e.type == 'updated') {
                        if (brutto_netto_select.val() == 'netto') {  
                            recipe_price.val(toPrice(netto_sum));
                        } else {
                            recipe_price.val(toPrice(brutto_sum));
                        }
                        recipe_price_netto.val(toPrice(netto_sum));
                    }
                    setSum(brutto_overview, brutto_sum);

                    // surcharges
                    var ing_cost_sum = getSum(ing_cost);
                    var surcharge_sum_absolute = netto_sum - ing_cost_sum;
                    var surcharge_sum = ((netto_sum - ing_cost_sum) / ing_cost_sum) * 100;
                    setSum(ist_surcharge_absolute, surcharge_sum_absolute);
                    setSum(ist_surcharge, surcharge_sum);


                }, e.type == 'keyup' ? 400 : 0))


            // SOLL: ingredient costs changed
            }).on('update updated', '#ingredient-cost-sum', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    sales_budget.trigger('updated');
                }, e.type == 'keyup' ? 400 : 0));

            // SOLL: vat
            }).on('xupdated keyup keydown', '.restaurant-price-data.open .inhouse_recipe_vat', function (e) {
                brutto_calc.trigger('updated');

            // SOLL: netto updated
            }).on('update updated', '#ingredient-cost-netto-calc', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    var vat_sum = toNr(ist_vat.val());

                    // brutto
                    var netto_sum = getSum(netto_calc);
                    var brutto_sum = netto_sum + (netto_sum/100*vat_sum);
                    setSum(brutto_calc, brutto_sum);

                    // sales budget
                    var ing_cost_sum = getSum(ing_cost);
                    var sales_budget_sum = ing_cost_sum * 100 / netto_sum;
                    setSum(sales_budget, sales_budget_sum);

                    // surcharges
                    var surcharge_sum_absolute = netto_sum - ing_cost_sum;
                    var surcharge_sum = ((netto_sum - ing_cost_sum) / ing_cost_sum) * 100;
                    setSum(surcharge_absolute, surcharge_sum_absolute);
                    setSum(surcharge, surcharge_sum);

                }, e.type == 'keyup' ? 400 : 0));

            // SOLL: brutto updated
            }).on('update updated', '#ingredient-cost-brutto-calc', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    var vat_sum = getSum(ist_vat);
                    var brutto_sum = getSum(brutto_calc);
                    var netto_sum = brutto_sum*100/(vat_sum+100);
                    setSum(netto_calc, netto_sum);

                    // sales budget
                    var ing_cost_sum = getSum(ing_cost);
                    var sales_budget_sum = ing_cost_sum * 100 / netto_sum;
                    setSum(sales_budget, sales_budget_sum);

                    // surcharges
                    var ing_cost_sum = getSum(ing_cost);
                    var surcharge_sum_absolute = netto_sum - ing_cost_sum;
                    var surcharge_sum = ((netto_sum - ing_cost_sum) / ing_cost_sum) * 100;
                    setSum(surcharge_absolute, surcharge_sum_absolute);
                    setSum(surcharge, surcharge_sum);

                }, e.type == 'keyup' ? 400 : 0));

            // SOLL: surcharge updated
            }).on('update updated', '#ingredient-cost-surcharge', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    // ingredient costs
                    var ing_cost_sum = getSum(ing_cost);

                    // ingredient-costs updated
                    // SOLL: surcharges
                    var surcharge_sum = getSum(surcharge);
                    var surcharge_sum_absolute = 0;
                    if (surcharge_sum) {
                        surcharge_sum_absolute = ing_cost_sum/100*surcharge_sum;
                    } else {
                        surcharge_sum_absolute = 0;
                        surcharge_sum = 0;
                    }
                    setSum(surcharge_absolute, surcharge_sum_absolute);
                    setSum(surcharge, surcharge_sum);

                    // netto
                    var netto_sum = ing_cost_sum + surcharge_sum_absolute;
                    setSum(netto_calc, netto_sum);

                    // brutto
                    var vat_sum = getSum(vat);
                    var brutto_sum = netto_sum + (netto_sum/100*vat_sum);
                    setSum(brutto_calc, brutto_sum);

                    // sales budget
                    var ing_cost_sum = getSum(ing_cost);
                    var sales_budget_sum = ing_cost_sum * 100 / netto_sum;
                    sales_budget.text(toPrice(sales_budget_sum));
                    
                    /** trigger sales budget and graph update */
                    var id = sales_budget.attr('id');

                    sales_budget.parent().blinkMe();

                    if (id == 'ingredient-cost-sales-actual') {
                        updateChartLastPoint(0, sales_budget_sum);
                        checkChartLastPointColor(0);
                    } else
                    if (id == 'ingredient-cost-sales-budget') {
                        updateChartLastPoint(1, sales_budget_sum);
                        checkChartLastPointColor(1);
                    }
                    /** we don't call setSum like for others to update sales_budget, because sales_budget value is updated without it, 
                     * and calling again setSum will not result in changes since sales_budget is already changed, so graph update is not triggered, 
                     * that is why we update graph this way */
                }, e.type == 'keyup' ? 400 : 0));

            // SOLL: surcharge updated
            }).on('update updated', '#ingredient-cost-surcharge-absolute', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    // ingredient costs
                    var ing_cost_sum = getSum(ing_cost);

                    // ingredient-costs updated
                    // SOLL: surcharges
                    var surcharge_sum_absolute = getSum(surcharge_absolute);
                    var surcharge_sum = 0;
                    if (surcharge_sum_absolute) {
                        surcharge_sum = ((ing_cost_sum+surcharge_sum_absolute)*100/ing_cost_sum)-100;
                    } else {
                        surcharge_sum_absolute = 0;
                        surcharge_sum = 0;
                    }
                    setSum(surcharge_absolute, surcharge_sum_absolute);
                    setSum(surcharge, surcharge_sum);

                    // netto
                    var netto_sum = ing_cost_sum + surcharge_sum_absolute;
                    setSum(netto_calc, netto_sum);

                    // brutto
                    var vat_sum = getSum(vat);
                    var brutto_sum = netto_sum + (netto_sum/100*vat_sum);
                    setSum(brutto_calc, brutto_sum);

                    // sales budget
                    var ing_cost_sum = getSum(ing_cost);
                    var sales_budget_sum = ing_cost_sum * 100 / netto_sum;
                    sales_budget.text(toPrice(sales_budget_sum));
                    
                    /** trigger sales budget and graph update */
                    var id = sales_budget.attr('id');

                    sales_budget.parent().blinkMe();

                    if (id == 'ingredient-cost-sales-actual') {
                        updateChartLastPoint(0, sales_budget_sum);
                        checkChartLastPointColor(0);
                    } else
                    if (id == 'ingredient-cost-sales-budget') {
                        updateChartLastPoint(1, sales_budget_sum);
                        checkChartLastPointColor(1);
                    }
                    /** we don't call setSum like for others to update sales_budget, because sales_budget value is updated without it, 
                     * and calling again setSum will not result in changes since sales_budget is already changed, so graph update is not triggered, 
                     * that is why we update graph this way */
                }, e.type == 'keyup' ? 400 : 0));

            // SOLL: budget updated
            }).on('update updated', '#ingredient-cost-sales-budget', function (e) {
                if (ignoreKeys(e)) {
                    return false;
                }

                var $this = $(this);
                clearTimeout($this.data('timeout'));
                $this.data('timeout', setTimeout(function () {
                    // ingredient costs
                    var ing_cost_sum = getSum(ing_cost);
                    var vat_sum = getSum(vat);
                    var sales_budget_sum = getSum(sales_budget);

                    // netto
                    var netto_sum = ing_cost_sum * 100 /  sales_budget_sum;
                    setSum(netto_calc, netto_sum);

                    // brutto
                    var brutto_sum = netto_sum + (netto_sum/100*vat_sum);
                    setSum(brutto_calc, brutto_sum);

                    // surcharges
                    var ing_cost_sum = getSum(ing_cost);
                    var surcharge_sum_absolute = netto_sum - ing_cost_sum;
                    var surcharge_sum = ((netto_sum - ing_cost_sum) / ing_cost_sum) * 100;
                    setSum(surcharge_absolute, surcharge_sum_absolute);
                    setSum(surcharge, surcharge_sum);

                }, e.type == 'keyup' ? 400 : 0));

            });

            $('.ingredient-cost').first().trigger('start');
            //$('#ingredient-cost-brutto').trigger('update');
        }
    })();
};

$(document).ready(function () {
    init_cmcalc4();
});





/**
 * Flag for allowing filters other than restaurant
 */
var allow_cm_filters = false;

/**
 * Update recipe list by page and number of recipes per page
 * @param {Number} currentPage
 * @param {Number} loadPage
 * @param {Number} itemsPerPage
 * @param {Boolean} paginatorUpdate
 * @returns {Boolean}
 */
function updateRecipes(currentPage = 1, loadPage = 1, itemsPerPage = 10, paginatorUpdate = false) {
    if (!paginatorUpdate) {
        FoodNotifyPaginator.toggleShow(false);
        $('#recipes-loading-indicator').toggleClass('hidden', false);
    }

    var cats = [];
    $("#contribution-margin-groups-form-checkboxes > * input[type=checkbox]:checked").each(function () {
        cats.push($(this).val());
    });

    var rest = $('#contribution-margin-filter-restaurant').attr('data-value');
    var cmsort = $('#contribution-margin-filter-sort').attr('data-value');
    var mincost = $('#contribution-margin-filter-cost').attr('data-value');
    var maxcost = $('#contribution-margin-filter-cost').attr('data-value-max');
    var maxcost = (mincost != "") ? (maxcost != "" ? maxcost : Number(mincost) + 10) : "";
    var rsearch = $('[name=search_recipe]').val()

    $.ajax({
        url: '/contribution-margin/recipes',
        type: 'POST',
        data: {
            currentPage: currentPage,
            loadPage: loadPage,
            itemsPerPage: itemsPerPage,
            cat: cats,
            restId: rest,
            fcostmin: mincost,
            fcostmax: maxcost,
            cmsort: cmsort,
            filterGroupsMethodSelection: false,
            rsearch: rsearch,
            paginatorCall: Number(paginatorUpdate)
        },
        dataType: 'json',
        async: true,
        beforeSend: function () {
            if (!paginatorUpdate) {
                $('#cm-recipes-title-bar').toggleClass('hidden', true);
                document.getElementById("contribution-margin-recipes-display-table").innerHTML = '';
            } else {
                $('.contribution-margin-recipe-data').css('background', '#cccccc');
                $('.contribution-margin-recipe-data').css('opacity', '0.1');
            }
        },
        success: function (data, status) {
            if (!paginatorUpdate) {
                FoodNotifyPaginator.activePage(1);
                FoodNotifyPaginator.totalItemsNumber(data['countItems']);
                FoodNotifyPaginator.refresh();
            }

            if (data['length'] > 0) {
                $('#cm-recipes-title-bar').toggleClass('hidden', false);
                document.getElementById("contribution-margin-recipes-display-table").innerHTML = data['html'];

                if (allow_cm_filters) {
                    $('#cm-optional-filters').toggleClass('hidden', false);
                    $('#cm-recipes-title-bar').toggleClass('hidden', false);
                    FoodNotifyPaginator.toggleShow();

                    FoodNotifyPaginator.completedUpdate();
                }
            } else {
                $('#cm-recipes-title-bar').toggleClass('hidden', false);
                document.getElementById("contribution-margin-recipes-display-table").innerHTML = data['html'];
                FoodNotifyPaginator.toggleShow(false);
            }
        },
        error: function (err, textStatus, errorData) {
            FoodNotifyPaginator.error();

            $('#contribution-margin-filter-tabs').toggleClass('hidden', true);
            $('#cm-recipes-title-bar').toggleClass('hidden', true);
            $('#error-message-item').toggleClass('hidden', false);
        },
        complete: function () {
            $('#recipes-loading-indicator').toggleClass('hidden', true);
        }
    });

    return true;
}


/**
 * Wrapped pdate function for FoodNotifyPaginator
 * @param {Number} currentPage 
 * @param {Number} loadPage 
 * @param {Number} itemsPerPage 
 * @param {Boolean} paginatorUpdate 
 * @returns {Boolean}
 */
function contributionMarginPaginatorUpdate(currentPage, loadPage, itemsPerPage, paginatorUpdate = true) {
    return $(document).ready() ?
        updateRecipes(currentPage, loadPage, itemsPerPage, paginatorUpdate) : 
        false;
}

// contribution margin page
$(document).ready(function () {

    let animations = new Animations();

    var tabs = $('#contribution-margin-tabs');
    if (!tabs.length) {
        return;
    }

    tabs.on('click', '.view', function(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        return true;
    });

    tabs.on('click', '.item > *', function() {
        var item = $(this).closest('.item');
        if (!item.hasClass('open')) {
            item.siblings('.item').removeClass('open');
        }
        item.toggleClass('open');
    });

    // cmcalc list save
    $('#cm-ingredients').on('change update', 'input', function() {
        var $this = $(this), row = $this.parents('.row[data-url]'), loader = $this, form_group = $this.parents('.form-group');
        if ($this.attr('type') == 'hidden') {
            loader = form_group.find('input[type=text]');
        }

        $.ajax({
            url: row.attr('data-url'),
            type: 'post',
            data: row.find('input').serialize(),
            beforeSend: function() {
                loader.addClass('loading')
            },
            complete: function() {
                loader.removeClass('loading')
            },
            success: function() {
                var ok = $('<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>');
                loader.removeClass('loading').siblings('.form-control-feedback').remove();
                loader.after(ok);
                form_group.removeClass('has-error').addClass('has-success has-feedback');
                $this.get(0).defaultValue = $this.val();

                setTimeout(function() {
                    ok.fadeOut(function() {
                        $(this).remove()
                        form_group.removeClass('has-success has-feedback');
                    });
                }, 2000);

            },
            error: function(e) {
                var not_ok = $('<span class="glyphicon glyphicon-refresh form-control-feedback" aria-hidden="true"></span>');
                loader.removeClass('loading').siblings('.form-control-feedback').remove();
                form_group.removeClass('has-success').addClass('has-error has-feedback');
                loader.after(not_ok);

                not_ok.click(function() {
                    loader.siblings('.form-control-feedback').remove();
                    form_group.removeClass('has-error has-feedback');
                    $this.val($this.get(0).defaultValue);
                });

                if ('responseJSON' in e) {
                    var data = e.responseJSON;
                    if ('f' in data && 'e' in data) {
                        row.find('input[name='+data.f+']').showPopover(data.e, 2000);
                    }
                }
            }
        });
        return false;
    });




    var filter_selection_id = ['restaurant', 'groups', 'sort', 'search', 'cost'];
    /**
     * Handle show/hide dropdown of all filters, allow only one to be open
     * @param {Selector} activeSelector 
     * @param {Boolean} show 
     */
    var showOptionSelectorDropdown = function(activeSelector, show = true) {
        var filterActive;
        for(var i = 0; i < filter_selection_id.length; i++) {
            var action =  (activeSelector != filter_selection_id[i]) || !show;
            var state = $('#contribution-margin-selection-' + filter_selection_id[i]).hasClass('hidden');

            $('#contribution-margin-selection-' + filter_selection_id[i]).toggleClass('hidden', action);
            $('.contribution-margin-filter-' + filter_selection_id[i]).toggleClass('active', !action);

            filterActive = action;
            if(filter_selection_id[i] == 'groups') {
                countGroupsChecked = 0;
                $('.group-input-checkbox').each(function() {
                    if($(this)[0].checked) {
                        countGroupsChecked++;
                    }
                });
                if(countGroupsChecked == 0) {
                    filterActive = false;
                }
            }
            $('.contribution-margin-filter-' + filter_selection_id[i]).toggleClass('filter-active', filterActive);
       
            if(action != state) {
                animations.halfRotate($('#contribution-margin-caret-' + filter_selection_id[i]));
            }
        }
    };


    /**
     * Show/hide dropdown on clicking on filter button
     */
    $('.contribution-margin-button').on('tap click', function(e) {
        e.stopPropagation();
        var activeSelector = $(this).attr('data-id');
        var close = $('#contribution-margin-selection-' + activeSelector).hasClass('hidden');

        showOptionSelectorDropdown(activeSelector, close);
    });


    /**
     * Close opened dropdowns on events outside dropdown area
     */
    $(window).on('tap click', function(e) {
        var caller = $(e.target);
        if(!caller.parents('.contribution-margin-selection').length) {
            showOptionSelectorDropdown("", false);
        }
    });


    /**
     * On initial choosing of restaurant show other filters and load first batch of items for active restaurant filter
     */
     $('.contribution-margin-filter-init > * .contribution-margin-restaurant-option').on('tap click', function(e) {
        $('#contribution-margin-filter-restaurant').removeClass('contribution-margin-filter-init');

        $('#contribution-margin-select-filter-info').toggleClass('hidden', true);

        $('#contribution-margin-filter-restaurant').addClass('contribution-margin-filter');

        $('#contribution-margin-filter-restaurant').attr('data-value', $(this).attr('data-value'));
        $('#contribution-margin-title-restaurant').html($(this).attr('data-title'));

        $('.contribution-margin-filter-restaurant').toggleClass('filter-active', true);

        showOptionSelectorDropdown("", false);
        allow_cm_filters = true;
        updateRecipes(1, 1, FoodNotifyPaginator.itemsPerPage());
    });



    /**
     * Handle show/hide recipe item data
     */
    $(document).on('tap click', '.contribution-margin-recipe-data', function(e) {
        /**
         * If not clicked target to repe view or edit icons, handle show/hide
         */
        if(!$(e.target).hasClass('link-img')) {
            var $this = $(this);
            $detailed = $this.find('.recipe-contribution-margin-view-detailed');
            var show = $detailed.hasClass('hidden');

            var $short = $this.find('.recipe-contribution-margin-view-short');
            var $context = $this.find('.recipe-contribution-margin-view-detailed-context');
            var $links = $this.find('.recipe-contribution-margin-view-recipe-links');

            $this.toggleClass('open', show);

            if(show) {
                $short.fadeTo(200, 0);
                $detailed.toggleClass('hidden');
                $context.fadeTo(250, 1);
                $links.toggleClass('hidden');
            } else {
                $context.fadeTo(200, 0, function() {
                    $links.toggleClass('hidden');
                    $detailed.toggleClass('hidden');
                });
                $short.fadeTo(250, 1);
            }

            animations.halfRotate($(this).find('.contribution-margin-caret-cost'));
        }
    });
    

var countGroupsChecked = 0;
    /**
     * Handle checkbox action for groups
     */
    $('#contribution-margin-groups-form-checkboxes > * input').on('change', function(e) {
        if (window.ctrl_active) {
            return;
        } // if CTRL + click don't handle chained checking

        var id = $(this).val();
        var check = $(this)[0].checked;
        var adder = (check ? 1 : -1);

        countGroupsChecked += adder;

        var parentId = $(this).attr('data-parent');

        // if child group handle parent check
        if(check && parentId != undefined) {
            $('#groups-checkbox-' + parentId)[0].checked = true;
            countGroupsChecked += ($('#groups-checkbox-' + parentId)[0].checked ? 0 : 1);
        }

        // if parent group chain-check children groups
        if(parentId == undefined) {
            $('#groups-children-wrap-' + id).children('div').each(function() {
                $(this).find('input').checked = check;
                countGroupsChecked += adder;
            });
        }
    });


    /**
     * On selected option in any dropdown of filter update items list for active filters
     */
    $('.contribution-margin-option').on('tap click', function(e) {
        $('#contribution-margin-filter-' + $(this).attr('data-id')).attr('data-value', $(this).attr('data-value'));
        $('#contribution-margin-title-' + $(this).attr('data-id')).html($(this).attr('data-title'));
        
        if($(this).attr('data-id') == 'cost') {
            $('#contribution-margin-filter-' + $(this).attr('data-id')).attr('data-value-max', '');
        }
        
        showOptionSelectorDropdown("", false);
        updateRecipes(1, 1, FoodNotifyPaginator.itemsPerPage());
    });


    /**
     * Update items list on confirming selected groups for active filters
     */
    $('#contribution-margin-groups-confirm-selection').on('tap click', function(e) {
        countGroupsChecked = 0;

        $('.group-input-checkbox').each(function() {
            if($(this)[0].checked) { countGroupsChecked++; }
        });
        $('#contribution-margin-title-groups').html(
            countGroupsChecked > 0 ? 
                (countGroupsChecked + ' ' + $('#groups-extended-text-selected').attr('data-text')) : 
                $('#groups-button-default-text').html()
        );
        $('.contribution-margin-filter-groups').toggleClass('filter-active', countGroupsChecked > 0);

        showOptionSelectorDropdown("", false);
        updateRecipes(1, 1, FoodNotifyPaginator.itemsPerPage());
    });

    
    /**
     * Handle click on custom cost buttons
     */
    $('.custom-cost-button').on('tap click', function(e) {
        // if confirm button clicked parse input and update recipes for active filters
        if($(this).attr('data-id') == 'confirm') {
            var min = $('#contribution-margin-cost-custom-min').val();
            var max = $('#contribution-margin-cost-custom-max').val();

            min = min.replace('%', '');
            max = max.replace('%', '');
            if(min.includes('<')) { min = -1000000; }
            if(max.includes('>')) { max = 1000000; }

            // parse for number, 
            if(isNaN(parseFloat(min)) || min == '') {
                $('#contribution-margin-cost-custom-min').css('border', '1px solid #CC3232');
                return;
            }
            else if(isNaN(parseFloat(max)) || max == '') {
                $('#contribution-margin-cost-custom-max').css('border', '1px solid #CC3232');
                return;
            }
            // restore to non error border
            $('#contribution-margin-cost-custom-min').css('border', '1px solid #CCCCCC');
            $('#contribution-margin-cost-custom-max').css('border', '1px solid #CCCCCC');

            min = Number(min);
            max = Number(max);

            min = min < -1000000 ? -1000000 : min;
            max = max > 1000000 ? 1000000 : max;

            if(min > max) { var switcher = min; min = max; max = switcher; }

            $('#contribution-margin-filter-cost').attr('data-value', min);
            $('#contribution-margin-filter-cost').attr('data-value-max', max);
            $('#contribution-margin-title-cost').html($('#cost-custom-text-extend').attr('data-text'));

            $('#contribution-margin-cost-custom-min').val(min);
            $('#contribution-margin-cost-custom-max').val(max);

            showOptionSelectorDropdown("", false);
            updateRecipes(1, 1, FoodNotifyPaginator.itemsPerPage());
            return;
        }

        $('#contribution-margin-cost-custom-min').val('<0%');
        $('#contribution-margin-cost-custom-max').val('>100%');
    });


    /**
     * Filter groups on input
     */
let searchGroupInputTimeout = { timeout: null };
    $('#search_groups').on('keyup keypress', function(e) {
        handleKeyboardInputDelay(e, searchGroupInputTimeout, 
            function(x) {
                var pattern = x.val().toLowerCase(); var hide = true; var active = 0;
                $('#contribution-margin-groups-form-checkboxes').children('span').each(function() {
                    hide = true;
                    if(pattern != "") {
                        $(this).find('input').each(function() {
                            if($(this).attr('data-name').toLowerCase().includes(pattern)) {
                                hide = false; active++; return;
                            }
                        });
                    } else { hide = false; active++; }
                    $(this).toggleClass('hidden', hide);
                });
                $('#groups-no-groups').toggleClass('hidden', active > 0);
            }, 
            $(this)
        );
    });
    

    /**
     * Filter recipes on input
     */
let searchInputTimeout = { timeout: null };
    $('#search_recipe').on('keyup keypress', function(e){
        handleKeyboardInputDelay(e, searchInputTimeout, 
            function(x) {
                $('.contribution-margin-filter-search').toggleClass('filter-active', x.val() != "");
                updateRecipes(1, 1, FoodNotifyPaginator.itemsPerPage());
            }, 
            $(this)
        );
    });
    

});


$( document ).ready(function() {
    $(".toggle-password").click(function() {
        var $this = $(this), input = $this.siblings('input');

        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    var signup_form = $('.signup-form');
    if (!signup_form.length) {
        return;
    }

    // toggle left menu
    $('#signup2-navbar-toggle').click(function() {
        $('body').toggleClass('signup2-navbar-mobile');
    });
    $('#signup2-navbar-close, #signup2-navbar-overflow').click(function() {
        $('body').removeClass('signup2-navbar-mobile');
    });

    // back to step1
    $('body').on('click', '#signup2-change-here', function() {
        $(this).closest('.carousel').carousel(0);
    });

    // signup submit
    $('body').on('submit', '.signup-form', function(data) {
        var form = $(this);

        form.save(function(data) {
            var carousel = form.closest('.carousel');
            if ('add_step' in data) {
                carousel.find('.item:last').after(data.add_step);
            }
            if ('new_url' in data) {
                history.pushState({}, '', data.new_url);
            }
            // force user to go back
            if ('step' in data) {
                carousel.carousel(data.step-1);
            } else
            if ('msg' in data) {
                var msg = $('<p class="thank-you">').text(data.msg);
                form.find('> *').fadeOut(400, function() {
                    setTimeout(function() {
                        form.append(msg);
                        msg.fadeIn();
                    }, 500);
                });

            } else
            if (!('redirect' in data)) {
                carousel.carousel('next');
            }
        });

        return false; 
    });

    // scroll to top on next item
    $('#signup-carousel').on('slide.bs.carousel', function(e) {
//        $('html, body').animate({ scrollTop: 0 }, 100 );
    });
    // focus first visible element on next item
    $('#signup-carousel').on('slid.bs.carousel', function(e) {
        if (e.relatedTarget) {
            $(e.relatedTarget).find('input[type=text]:first').focus();
        }
    });

    // signup show email2 field
    signup_form.find('[name=username]').on('change keydown keypress focus', function() {
        $(this).closest('.signup-form').find('input[name=username2]').closest('.form-group').slideDown();
    });

    // show customer_nr input
    $('body').on('click', '.supplier-customer-nr label', function() {
        var $this = $(this).toggleClass('edit');
        if ($this.hasClass('edit')) {
            // there is jittering in css transition if you
            // try to focus and do transition in the same time
            // lets wait a little bit
            setTimeout(function() {
                $this.closest('.supplier-customer-nr').find('input').focus();
            }, 300);
        }

    // customer_nr input: on "enter/return/escape" or unfocus/blur
    // remove edit
    }).on('keyup keydown keypress focusout', '.supplier-customer-nr input', function(e) {
        if (e.type == 'focusout' || e.which == 13 || e.which == 27) {
            e.preventDefault();

            var $this = $(this), val = $.trim($this.val()), label = $this.closest('.supplier-customer-nr').find('label');

            // blur or enter
            // on escape: just close it
            if (e.which != 27 && label.hasClass('edit')) {
                // show customer number instead of default label text
                // if customer nr is entered
                if (val && val.length) {
                    if (!label.data('label')) {
                        label.data('label', label.text())
                    }
                    label.addClass('customer-nr')
                        .text(label.attr('data-customer-nr-text').replace('{number}', val));
                // reset 
                } else {
                    label.removeClass('customer-nr')
                    if (label.data('label')) {
                        label.text(label.data('label'));
                    }
                }
            }
            label.removeClass('edit').focus()
            return false;
         }

    // close button = uncheck checkbox
    }).on('click', '.close', function(e) {
        $(this).closest('label').click();

    // toggle why
    }).on('click', '.why', function(e) {
        var info = $(this).find('+ .alert');
        if (info.is(':visible')) {
            info.slideUp();
        } else {
            info.slideDown();
        }
    });
});

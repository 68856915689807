import {htmlLoading} from "./global";
import Handlebars from './../handlebars-v1.3.0.min'

/* settings functions */
$( document ).ready(function() {
    if (!$('.settings-form').length) {
        return;
    }

    var hash = $.trim(window.location.hash);
    if (hash) $('.nav-tabs a[href$="'+hash+'"]').trigger('click');

    $('.language-dropdown .dropdown-menu a').on('click', function() {
        var lang_code = $(this).attr('data-value'),
            lang_name = $(this).text();

        var list = $('#languages-list'),
            langCount = list.find('li').length;

        if (list.find('input[name="language[' + lang_code + ']"]').length == 0) {
            if (langCount == 1) {
                list.first().find('.btn-language-delete').show();
            }

            var li = list.find('li.hidden').clone(true);
            li.find('.name').text(lang_name + ' (' + lang_code.toUpperCase() + ')');
            li.find('a').attr('data-value', lang_code);
            li.find('img').attr('src', '/img/langs/' + lang_code + '.png');
            li.find('img').attr('alt', lang_code.toUpperCase());
            li.find('input').attr('name', 'language[' + lang_code + ']');
            li.removeClass('hidden');
            li.hide();

            list.append(li);
            $('#new-language-message').show();
            li.slideDown(30);
        }
    });

    $('#languages-list').find('.btn-language-delete').on('click', function() {
        var btn = $(this),
            item = btn.closest('li'),
            list = btn.closest('ul'),
            langCount = list.find('li').length;

        if (langCount > 1) {
            // a.closest('li').slideUp(100, function () {
            //     $(this).remove();
            // });
            if (langCount == 2) list.first().find('.btn-language-delete').hide();
            item.remove();
        }
    });

    $('#languages-list').sortable({
        items: '> li',
        axis: 'y',
        containment: 'parent',
        tolerance: 'pointer'
    });

    // select recipe unit
    $('body').on('change', '#global-recipe-units-dropdown .selected-global-recipe-unit', function(e, a) {
        var $this = $(this);

        if ($this.val() == 'add') {
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();

            var a = $(this).closest('a');
            var tmplt = $('#global-recipe-unit-edit').addClass('add').removeClass('edit');
            var name = tmplt.find('[name=global_recipe_unit_name]').val('');
            tmplt.find('[name=weight]').val('');
            tmplt.find('[name=recipe_unit_id]').val('');
            tmplt.show();
            name.focus();

        } else {
            if ($('#main-settings').length) {
                a.find('.edit').click();
            } else {
                $('#ingredient-loss').val(a.attr('data-loss'));
                $('#global-recipe-units-dropdown .dropdown-toggle').trigger('reset');
            }
        }
    });

    // edit recipe unit
    $('body').on('click', '#global-recipe-units-dropdown a .edit', function(e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var a = $(this).closest('a');
        var tmplt = $('#global-recipe-unit-edit').addClass('edit').removeClass('add');
        var name = tmplt.find('[name=global_recipe_unit_name]').val(a.attr('data-name'));
        tmplt.find('[name=weight]').val(a.attr('data-base-unit-weight'));
        tmplt.find('.ingredient-unit-selector .custom-unit-unit-value a[data-value="'+a.attr('data-base-unit-unit')+'"]').trigger('clicked');
        tmplt.find('[name=recipe_unit_id]').val(a.attr('data-value'));
        //tmplt.find('[name=affect_existing_templates]').prop('checked', false);
        tmplt.show();
        name.focus().select();

        $('#global-recipe-units-dropdown').removeClass('open');
        return false;
    });

    // delete recipe unit
    $('body').on('click', '#global-recipe-units-dropdown a .delete', function(e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var $this = $(this);
        $this.async(function() {
            $this.closest('a').remove();
        });

        $('#global-recipe-units-dropdown').removeClass('open');
        $('#global-recipe-unit-edit').hide();
        return false;
    });

    // save recipe unit
    $('body').on('click fake-click', '#global-recipe-unit-edit-save', function() {
        var form = $('#global-recipe-unit-edit'),
            btn = $('#global-recipe-unit-edit-save');

        $.ajax({
            url: form.attr('data-url'),
            type: 'post',
            data: {
                recipe_unit_id: form.find('[name=recipe_unit_id]').val(),
                global_recipe_unit_name: form.find('[name=global_recipe_unit_name]').val(),
                global_recipe_unit_base_unit_weight: form.find('[name=weight]').val(),
                global_recipe_unit_base_unit_unit: form.find('[name=unit]').val(),
                //affect_existing_templates: form.find('[name=affect_existing_templates]').prop('checked') ? 1 : 0
            },
            beforeSend: function () {
                btn.button('loading')
            },
            success: function(data) {
                $('#global-recipe-unit-edit').hide();
                var li = $(Handlebars.compile($('#recipe-unit-dropdown-tmpl').html())(data));

                // add to dropdown
                if (form.hasClass('add')) {
                    $('#global-recipe-units-dropdown .dropdown-menu').prepend(li);

                    // modify dropdown
                } else {
                    var old_li = $('#global-recipe-units-dropdown .dropdown-menu').find('li:has(a[data-value="'+data.recipe_unit.id+'"])');
                    old_li.replaceWith(li);
                }

                $('#global-recipe-units-dropdown .dropdown-toggle').trigger('reset');
            },
            complete: function() {
                btn.button('reset')
            }
        });

        return false;
    });

    // submit modal recipe unit add/edit form on enter
    $('body').on('keydown', '#global-recipe-unit-edit [name=global_recipe_unit_name], #global-recipe-unit-edit [name=weight]', function(e) {
        if (e.which == 13) { // enter
            $('#global-recipe-unit-edit-save').trigger('fake-click');
            return false;
        }
    });

    // close recipe unit edit form
    $('body').on('click', '#global-recipe-unit-edit .close', function() {
        $('#global-recipe-unit-edit').hide();
    });

    // enable/disable meals and drinks input fields for takeaway vat after page load
    function disableFieldsAfterPageLoad(item) {
        if (!($('#show_takeaway_vat_for_'.concat(item))[0].checked)) {
            $('#default_takeaway_meals_vat_for_'.concat(item))[0].disabled = true;
            $('#default_takeaway_drinks_vat_for_'.concat(item))[0].disabled = true;
        }
    }
    function getAllGeneratedCountries() {
        var countries = [];
        var tmp = $('input[name="countries"]');
        for (var i=0; i<tmp.length; i++) {
            countries.push(tmp[i].value);
        }
        return countries;
    }
    var countries = getAllGeneratedCountries();
    countries.forEach(disableFieldsAfterPageLoad);

    // enable/disable meals and drinks input fields for takeaway vat on action
    $('body').on('click', '.show_takeaway_vat_trigger', function() {
        function disableFields(item) {
            if ($('#show_takeaway_vat_for_'.concat(item))[0].checked) {
                $('#default_takeaway_meals_vat_for_'.concat(item))[0].disabled = false;
                $('#default_takeaway_drinks_vat_for_'.concat(item))[0].disabled = false;
            } else {
                $('#default_takeaway_meals_vat_for_'.concat(item))[0].disabled = true;
                $('#default_takeaway_drinks_vat_for_'.concat(item))[0].disabled = true;
            }
        }
        var countries = getAllGeneratedCountries();
        countries.forEach(disableFields);
    });

    $('body').on('click', '.massive-delete-option', function() {
        $('.perform-massive-delete-btn-div').removeClass('hidden');
    });

    var showNotificationModal = false;
    $('#perform-massive-delete-action').on('click', function() {
        var action = "";
        $(this).parents('.config-box').find('li[role="presentation"]').each(function() {
            if ($(this).hasClass('selected')) {
                action = $(this).find('.massive-delete-option').attr('data-name');
            }
        });
        $.ajax({
            url: $(this).attr('data-url'),
            type: 'post',
            data: {
                action: action
            },
            beforeSend: function () {
                $(this).prop('disabled', true);
                $(this).html($(this).attr('data-loading-text'));
                htmlLoading();
            },
            success: function(data) {
                if (data['delete_action_completed']) {
                    showNotificationModal = true;
                }
                $('.pseudomodal-title').html($($('.btn-blockk')[2]).attr('data-quantity-unit-txt').trim());
                $('.notification-body-text').html($('.text-template').html() + " " + data['number']);
            },
            complete: function() {
                htmlLoading(false);
                if (showNotificationModal) {
                    showNotificationModal = false;
                    $('.pseudomodal').css('display', 'block');
                }
            }
        });
    });

    $('.close-simple-notification-modal').on('click', function() {
        $('.pseudomodal-content').animate(
            {top: '-=1000px'}, 600, function () {
                $('.pseudomodal').css('display', 'none');
                $('.pseudomodal-content').css('top', '0');
            });
    });

    $('input[name="stock_change_email_notification_per_every_stock_change"], input[name="stock_change_email_notification_daily"], input[name="stock_change_email_notification_weekly"], input[name="stock_change_email_notification_never"]').change(function() {
        let stockChangeNotificationTypes = {
            'stock_change_email_notification_per_every_stock_change' : 'everyTime',
            'stock_change_email_notification_daily' : 'daily',
            'stock_change_email_notification_weekly' : 'weekly',
            'stock_change_email_notification_never' : 'never'
        };

        let selectedType = $(this).attr('name');

        if ($(this).prop('checked')) {
            $('#stock_change_email_notification_type').val(stockChangeNotificationTypes[selectedType]);
            $(this).parents('.onoffswitch-checkbox').siblings().find('input').prop('checked', false);
        } else {
            $('#stock_change_email_notification_type').val('never');
        }
    });

    $('#enable_analytics_budget_information').on('change', function() {
        if ($(this).prop('checked')) {
            $('.analytics-budget-information').removeClass('hidden');
        } else {
            $('.analytics-budget-information').addClass('hidden');
        }
    });

    $('#analytics_budget_information_same_for_all_ccs').on('change', function() {
        if ($(this).prop('checked')) {
            $('.copy-section').addClass('hidden');
            $('.per-locations-and-cost-centers-block').addClass('hidden');
            $('.all-locations-and-cost-centers-block').removeClass('hidden');

            $('.costCenter-block').removeClass('activated-costCenter');
            $('.restaurant-block').removeClass('activated-restaurant');
            $('input[name="selected-restaurant-id"]').val('all_locations');
            $('input[name="selected-costCenter-id"]').val('all_costCenters');

            if (isEverythingReadyForAnalyticsBudgetInformationDataUpdate()) {
                updateDataAfterRequestForExistingAnalyticsBudgetInformation();
            }
        } else {
            $('.all-locations-and-cost-centers-block').addClass('hidden');
            $('.per-locations-and-cost-centers-block').removeClass('hidden');
            $('.copy-section').removeClass('hidden');
        }
    });

    $('#analytics_budget_information_same_for_all_months').on('change', function() {
        if ($(this).prop('checked')) {
            $('.per-months-budget-data').addClass('hidden');
            $('.all-months-budget-data').removeClass('hidden');
        } else {
            $('.all-months-budget-data').addClass('hidden');
            $('.per-months-budget-data').removeClass('hidden');
        }

        if (isEverythingReadyForAnalyticsBudgetInformationDataUpdate()) {
            updateDataAfterRequestForExistingAnalyticsBudgetInformation();
        }
    });

    $('.dots-icon').on('click', function() {
        let obj = $('.copy-option-block');
        if (obj.hasClass('hidden')) {
            obj.removeClass('hidden');
        } else {
            obj.addClass('hidden');
        }
    });

    $('.copy-from').on('click', function() {
        let obj = $('.copy-content');
        if (obj.hasClass('hidden')) {
            obj.removeClass('hidden');
            $(this).find('img').css('transform', 'rotate(180deg)');
        } else {
            obj.addClass('hidden');
            $(this).find('img').css('transform', 'none');
        }
    });

    $('.year-btn').on('click', function() {
        if ($(this).parent().hasClass('open')) {
            $(this).find('img').css('transform', 'none');
        } else {
            $(this).find('img').css('transform', 'rotate(180deg)');
        }
    });

    $('.budget-year-option').on('click', function() {
        $('input[name="selected-budget-year"]').val($(this).attr('data-name'));

        if (isEverythingReadyForAnalyticsBudgetInformationDataUpdate()) {
            updateDataAfterRequestForExistingAnalyticsBudgetInformation();
        }
    });

    $('.costCenter-block').on('click', function() {
        $('.costCenter-block').removeClass('activated-costCenter');
        $('.restaurant-block').removeClass('activated-restaurant');
        $(this).addClass('activated-costCenter');
        let rest = $(this).prevAll('.restaurant-block').first();
        rest.addClass('activated-restaurant');
        $('input[name="selected-restaurant-id"]').val(rest.attr('data-id'));
        $('input[name="selected-costCenter-id"]').val($(this).attr('data-id'));

        if (isEverythingReadyForAnalyticsBudgetInformationDataUpdate()) {
            updateDataAfterRequestForExistingAnalyticsBudgetInformation();
        }
    });

    function isEverythingReadyForAnalyticsBudgetInformationDataUpdate() {
        if ($('input[name="selected-budget-year"]').val() === "") {
            return false;
        }

        if (!$('#analytics_budget_information_same_for_all_ccs').prop('checked') &&
            ($('input[name="selected-restaurant-id"]').val() === "all_locations" ||
            $('input[name="selected-costCenter-id"]').val() === "all_costCenters")) {
                return false;
        }

        return true;
    }

    let months = [ 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december' ];

    function updateDataAfterRequestForExistingAnalyticsBudgetInformation() {
        let sameForAllMonths = $('#analytics_budget_information_same_for_all_months').prop('checked') ? 1 : 0,
        selectedBudgetYear = $('input[name="selected-budget-year"]').val(),
        selectedRestaurantId = $('input[name="selected-restaurant-id"]').val(),
        selectedCostCenterId = $('input[name="selected-costCenter-id"]').val();

        $.ajax({
            url: '/settings/find-existing-analytics-budget-information',
            type: 'post',
            data: {
                selected_budget_year: selectedBudgetYear,
                selected_restaurant_id: selectedRestaurantId,
                selected_cost_center_id: selectedCostCenterId
            },
            beforeSend: function() {
                $('.settings-form').css('pointer-events', 'none');
                htmlLoading();
            },
            success: function(data) {
                if (sameForAllMonths) {
                    if (data['budget_per_month_data'] !== null) {
                        $('input[name="monthly-budget"]').val(data['budget_per_month_data']['monthly-budget']);
                    } else {
                        $('input[name="monthly-budget"]').val("");
                    }
                } else {
                    for (let i = 0; i < months.length; i++) {
                        if (data['budget_per_month_data'] !== null) {
                            $('input[name="' + months[i] + '-budget"]').val(data['budget_per_month_data'][months[i] + '-budget']);
                        } else {
                            $('input[name="' + months[i] + '-budget"]').val("");
                        }
                    }
                }
            },
            complete: function() {
                $('.settings-form').css('pointer-events', 'all');
                htmlLoading(false);
            }
        });   
    }

    $('.copy-option').on('click', function() {
        if ($(this).hasClass('cost-center')) {
            let sameForAllMonths = $('#analytics_budget_information_same_for_all_months').prop('checked') ? 1 : 0,
            selectedBudgetYear = $('input[name="selected-budget-year"]').val(),
            copyToRestaurantId = $('input[name="selected-restaurant-id"]').val(),
            copyToCostCenterId = $('input[name="selected-costCenter-id"]').val(),
            selectedRestaurantId = $(this).prevAll('.copy-option.restaurant').attr('data-id'),
            selectedCostCenterId = $(this).attr('data-id');

            $.ajax({
                url: '/settings/copy-existing-analytics-budget-information',
                type: 'post',
                data: {
                    same_for_all_months: sameForAllMonths,
                    selected_budget_year: selectedBudgetYear,
                    selected_restaurant_id: selectedRestaurantId,
                    selected_cost_center_id: selectedCostCenterId,
                    copy_to_restaurant_id: copyToRestaurantId,
                    copy_to_cost_center_id: copyToCostCenterId
                },
                beforeSend: function() {
                    $('.settings-form').css('pointer-events', 'none');
                    htmlLoading();
                },
                success: function(data) {
                    resetCopyToDropdown();
                    if (sameForAllMonths) {
                        $('input[name="monthly-budget"]').val(data['budget_per_month_data']['monthly-budget']);
                    } else {
                        for (let i = 0; i < months.length; i++) {
                            $('input[name="' + months[i] + '-budget"]').val(data['budget_per_month_data'][months[i] + '-budget']);
                        }
                    }
                },
                complete: function() {
                    $('.settings-form').css('pointer-events', 'all');
                    htmlLoading(false);
                }
            });   
        }
    });

    function resetCopyToDropdown() {
        $('.copy-content').addClass('hidden');
        $('.copy-from').find('img').css('transform', 'none');
        $('.copy-option-block').addClass('hidden');
    }
});

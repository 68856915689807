$( document ).ready(function() { 
    // smartphone
    (function() {
    var sm = $('.smartphone-menu:visible');
    if (sm.length) {
        window.onpopstate = function(e) {
            window.history.replaceState(e.state, 'smartmenu', '#');
        };

        sm.on('hide', function() {
            var $this = $(this);
            $this.removeAttr('data-id').removeClass('visiblex')
            window.history.back(-1);
            $this.addClass('hiding');
            setTimeout(function() {
                $this.removeClass('hiding');
            }, 50);
        });
        // dropdown-toggle smart menu v2
        $('body').on('click', '.smart .menu-toggle', function(e) {
            var sm = $('.smartphone-menu:visible');
            if (!sm.length || sm.hasClass('hiding')) {
                return;
            }

            e.stopPropagation();
            e.preventDefault();
            var $this = $(this);

            if (sm.hasClass('visiblex')) {
                sm.trigger('hide');
                $this.trigger('blur mouseleave');
                return false;
            }

            sm.children(':not(.btn-sm-close)').remove();
            $this.nextAll('ul').find('li > *:not(.sm-menu-hidden)').each(function() {
                sm.append($(this).clone(true).addClass('btn btn-block btn-default'));

                //var a = $(this);
                //sm.append(a.clone(false).addClass('btn btn-block btn-default').on('click', function() {
                //    a[0].click();
                //    return false;
                //}));
            });
            sm.append(sm.find('.btn-sm-close'));
            sm.addClass('visiblex');
            window.history.pushState({ smartmenu: true }, 'smartmenu', '#sm');
            return false;
        });

        $('*').on('click', function(e) {
            if (sm.hasClass('visiblex')) {
                if ($(e.target).parents('.smartphone-menu').length) {
                    return 
                }
                sm.trigger('hide');
            }
        });

        // open/hide wells
        $('body').on('click', '.hide-well .sub-title', function() {
            $(this).parents('.hide-well').toggleClass('open');
        });
    }
    })();
});

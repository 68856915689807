// suppliers surveys
// also used on signup page
$( document ).ready(function() {
    // trigger hidden checkbox area
    $('body').on('click fake-click', 'form.supplier-form input[type=checkbox]', function() {
        var area = $(this).closest('.row');
        area.find('.number-selected').text(area.find('[type=checkbox]:checked').length);
    });
    // run on start
    $('form.supplier-form input[type=checkbox]:checked').trigger('fake-click');

    // add new option modal
    // disable submit in modal
    $('body').on('shown.bs.modal', '.modal.survey-option-add', function(e) {
        $(this).find('form').bindFirst('submit', function() {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $(this).closest('.modal').modal('hide'); 
            return false;
        });
    });
    // if disable did not work, still close it
    $('body').on('submit', '.modal.survey-option-add form', function(e) {
        $(this).closest('.modal').modal('hide'); 
        return false;
    });
    // get input value on close
    $('body').on('hidden.bs.modal', '.modal.survey-option-add', function(e) {
        var $this = $(this);
        var option = $.trim($this.find('.input-text').val());

        if (option.length == 0) {
            return;
        }

        var list = ($this.hasClass('survey-option-add-wh')) ? $('.suppliers .wholesalers') : $('.suppliers .cooperatives');
        var new_opt = list.find('.checkbox').first().clone();
        new_opt.find('input').remove();
        var span_option = $('<span></span>').text(option);
        var label = new_opt.find('label').addClass('nice-checkbox').text('').append(span_option);
        var input = $('<input type="checkbox" name="extra[]">').val(option);

        // add close button
        var close = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>').click(function() {
            var that = $(this), checkbox = that.closest('.checkbox');
            checkbox.find('input').prop('checked', false).trigger('fake-click');
            checkbox.fadeOut(function() {
                $(this).remove();
            });
        });
        //label.append(close);
        label.append(input);
        list.find('.checkbox:last-child').before(new_opt);
        input.click();
    });

    // select all link
    $('.select-all-options').click(function() {
        var $this = $(this), checked = $this.hasClass('checked');

        $this.closest('.select-all-options-div').find(':checkbox').
            prop('checked', !checked).
            trigger('fake-click').trigger('change');

        $this.toggleClass('checked', !checked).trigger('toggle-label');
    });

    // IE workaround
    $('body').on('click', '.nice-checkbox img', function(e) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();

        var checkbox = $(this).closest('.nice-checkbox').find(':checkbox');
        checkbox.prop('checked', !checkbox.is(':checked'));
        checkbox.trigger('fake-click').trigger('change');
        return false;
    })
});


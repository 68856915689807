// recipe form
import {htmlLoading} from "./global";
import Handlebars from './../handlebars-v1.3.0.min'
import {init_cmcalc4} from "./profit-margin"

var currentSelectedLang = "";

var previousSelectedLang = ($('ul.lang-block-correction').find('li.active')).attr("data-lang");


var currentStepAddingImage = "";
// var stepsToTranslet = 0;
// var stepsTranslated = 0;
// global functions

function addRemoveRecipeNutritionTag(nutritionTagId) {
    var oldValueTag = $('body').find('input[name="nutrition_tags['+nutritionTagId+']"]').val();
    var element = document.getElementById("nutrition_tag_label_"+nutritionTagId);
    if(oldValueTag != '0') {
        $('body').find('input[name="nutrition_tags['+nutritionTagId+']"]').val('0');
        element.classList.remove("tag-border-and-text-view-mode");
        var tooltipTitle = document.getElementById("nutrition_tag_label_"+nutritionTagId).innerHTML;
        var tooltipDesc = $('body').find('input[name="nutrition_tags_tooltip_add"]').val();

        document.getElementById("nutrition_tag_label_"+nutritionTagId).setAttribute("data-original-title", tooltipDesc + " " + tooltipTitle);
    }
    else {
        $('body').find('input[name="nutrition_tags['+nutritionTagId+']"]').val(nutritionTagId);
        element.classList.add("tag-border-and-text-view-mode");
        var tooltipDesc = $('body').find('input[name="nutrition_tags_tooltip_remove"]').val();

        document.getElementById("nutrition_tag_label_"+nutritionTagId).setAttribute("data-original-title", tooltipDesc);
    }
}

// Assign the function to the window object
window.addRemoveRecipeNutritionTag = addRemoveRecipeNutritionTag;

function getAvailableLanguages() {
    var languages = [];

    var elements = $(".lang-block-correction").find('li');

    var temp = null;
    elements.each(function () {

        temp = $(this).attr('data-lang');

        if (temp != null) {
            languages.push(temp);
        }

    });


    return languages;
}

function getCurrentLanguage() {

    var sourceLang = $('ul.lang-block-correction').find('li.active');

    if (sourceLang.length == 0) {
        return null;
    }


    return sourceLang.attr("data-lang");
}

function getUserDefaultLang()
{
    var lang = "";

    if ($(".user-default-lang").length > 0 && $(".user-default-lang").val() != "")
    {
        lang = $(".user-default-lang").val();
    }

    return lang;
}
// translate api
function text_translate(from, to, text, cb) {
    $.ajax({
        url: '/translate',
        type: 'post',
        data: {
            source: from,
            target: to,
            q: text
        },
        success: cb
    });
};

function text_translate_with_custom_callback(from, to, text, index,field, cb) {
    $.ajax({
        url: '/translate',
        type: 'post',
        data: {
            source: from,
            target: to,
            q: text
        },
        success: function(data) {
            var temp = text;

            if (data['t'] != null) {
                temp = data['t'];
            }
            cb(from, to, temp, index, field);
        }
    });
};

var update_with_translation = function (data, fld, btn) {
    var translation = data['t'];

    if ($.trim(translation).length) {
        if (fld.attr('id') === 'recipe-title')
            translation = translation.capitalizeFirstLetter();
        fld.is('body') ? fld.text(translation) : fld.val(translation);
        btn.addClass('auto-translated');
    }
};

var update_comment_with_translation = function (data, tinymce_textarea, btn) {
    var translation = data['t'];

    if ($.trim(translation).length && (typeof tinymce_textarea != 'undefined')) {
        tinymce_textarea.setContent(translation);
        btn.addClass('auto-translated');
        setTimeout(function () {
            tinymce_textarea.save();
        }, 1000);
    }
};

// auto-translate empty fields of target languages
function autotranslate_empty(empty_titles, empty_descs, tab, form) {
    var source_lang = $('.lang-tabs').attr('data-source-lang'),
        fld_title = $('.recipe-title-input[data-lang=' + source_lang + ']'),
        fld_desc = $('.recipe-description-title[data-lang=' + source_lang + ']'),
        txt_title = fld_title.val(),
        txt_desc = fld_desc.val(),
        tab_activated = false;

    var autotranslate = function (text, fld, save) {
        var target_lang = fld.attr('data-lang'),
            btn = fld.prev();

        text_translate(source_lang, target_lang, text, function (data) {
            if (data['t'] === text)
                text_translate(null, target_lang, text, function (data) {
                    update_with_translation(data, fld, btn);
                });
            else update_with_translation(data, fld, btn);

            if (form && save) {
                form.save(function (data) {
                    window.onbeforeunload = null;

                    if (!form.find('[name=id]').length) {
                        form.prepend($('<input name="id" type="hidden"/>').val(data.id));
                    }
                });
            }
        });
    };

    empty_titles.each(function (i) {
        if (txt_title) {
            if (!txt_desc && i == empty_titles.length - 1) {
                autotranslate(txt_title, $(this), true);
            }
            else {
                autotranslate(txt_title, $(this));
            }
        }
    });

    empty_descs.each(function (i) {
        if (txt_desc) {
            if (i == empty_descs.length - 1)
                autotranslate(txt_desc, $(this), true);
            else
                autotranslate(txt_desc, $(this));
        }
    });
}

function checkIfViewMode() {
    var url = (window.location.href);

    return url.includes("/view/");

}

function checkIfStepNeedToBeTranslated(lang, index, obj) {


    var valid = false;
    obj.forEach(element => {

        if (element.lang == lang && element.step == index) {

            valid = true;
        }
    });

    return valid;
}

function checkShowingTranslateButton(lang)
{
    var userDefaultLang = getUserDefaultLang();

    if (lang != userDefaultLang)
    {
        $("#translateSteps").show();
        
    } else {
        $("#translateSteps").hide();
    }
}

function getStepsCountByLang(lang)
{
    return $(".step-section-content[step-lang='" + lang + "']").length;
}


function checkStepsLang(_sourceLang = null, submitFormAfterFinish = false) {

    var currentSelectedLang = getCurrentLanguage();
    var sourceLang = $('ul.lang-block-correction').find('li.active');

    var userCurrentDefaultLang = getUserDefaultLang();

    if (sourceLang.length == 0) {
        return 1;
    }

    sourceLang = sourceLang.attr("data-lang");

    if (_sourceLang != null) {
        sourceLang = _sourceLang;
    }

    if (checkIfViewMode()) {

        return 1;
    }

    var copiedStepsLang = [];
    var languages = getAvailableLanguages();
    var languagesToTranslate = [];

    languages.forEach(element => {
        if (element != sourceLang) {
            languagesToTranslate.push(element);
        }
    });

    var current_state = $(".current-steps-count").val();

    var regularExpression = /(?:\[).*?(?=\])/g;

    var translationDone = false;

    var index = 1;

    var sourceLangStepsCount = getStepsCountByLang(sourceLang);
    $('.step-section-content').each(function () {

        var lang = $(this).attr("step-lang");

        if (lang == sourceLang) {

            //if (($("#stepTitle-" + sourceLang + "-" + index).val()).trim() != "") {

                languagesToTranslate.forEach(element => {

                    var stepsCountByLang = getStepsCountByLang(element);

                    if(stepsCountByLang < sourceLangStepsCount)
                    {

                        var elementToTranslate = $("#stepTitle-" + element + "-" + index);

                        if (elementToTranslate.length == 0) {

                            var nameOfObjectToTranslate = $("#stepTitle-" + sourceLang + "-" + index).attr("name");

                            var current_num = (nameOfObjectToTranslate.match(regularExpression))[0];
                            current_num = current_num.replace("[", "");
                            current_num = current_num.replace("]", "");
                            current_state++;

                            var temp = sourceLang;

                            var originHtml = $(this).html();

                            var newHtml = (originHtml).replace((new RegExp("-" + temp + "-", "g")), '-' + element + '-');

                            newHtml = (newHtml).replace(new RegExp('value="' + sourceLang + '"', "g"), 'value="' + element + '"');

                            newHtml = (newHtml).replace((new RegExp("\\[" + current_num + "\\]", "g")), '[' + current_state + ']');

                            var final = "";
                            if (currentSelectedLang == element) {
                                final = ' <div class="step-section-content" step-lang="' + element + '">';
                            } else {
                                final = ' <div class="step-section-content non-active-step" step-lang="' + element + '">';
                            }

                            final += newHtml;

                            final += ' </div>';

                            $(".steps-content").append(final);
                            $("#stepTitle-" + element + "-" + index).val('');
                            $("#stepDescription-" + element + "-" + index).val('');

                            if (element != userCurrentDefaultLang) {
                                $("#translate-step-" + element + "-" + index).show();
                            } else {
                                $("#translate-step-" + element + "-" + index).hide();
                            }
                            copiedStepsLang.push({ "lang": element, "step": index });

                            translationDone = true;
                        } else {

                            if ($("#stepTitle-" + element + "-" + index).val() != "") {
                                var currentTitle = $("#stepTitle-" + element + "-" + index).val();
                                var currentDescription = $("#stepDescription-" + element + "-" + index).val();
                                var currentImage = $("#stepImageId-" + element + "-" + index).val();
                                var imgOfBaseElement = $("#stepImageId-" + sourceLang + "-" + index).val();

                                var parent_div = $("#stepIdNumber-" + element + "-" + index).parent().parent();

                                parent_div.remove();

                                var nameOfObjectToTranslate = $("#stepTitle-" + sourceLang + "-" + index).attr("name");

                                var current_num = (nameOfObjectToTranslate.match(regularExpression))[0];
                                current_num = current_num.replace("[", "");
                                current_num = current_num.replace("]", "");

                                current_state++;

                                var temp = sourceLang;

                                var originHtml = $(this).html();

                                var newHtml = (originHtml).replace((new RegExp("-" + temp + "-", "g")), '-' + element + '-');

                                newHtml = (newHtml).replace(new RegExp('value="' + sourceLang + '"', "g"), 'value="' + element + '"');

                                newHtml = (newHtml).replace((new RegExp("\\[" + current_num + "\\]", "g")), '[' + current_state + ']');

                                var final = ' <div class="step-section-content non-active-step" step-lang="' + element + '">';

                                final += newHtml;

                                final += ' </div>';

                                $(".steps-content").append(final);

                                $("#stepTitle-" + element + "-" + index).val(currentTitle);
                                $("#stepDescription-" + element + "-" + index).val(currentDescription);

                                if (element != userCurrentDefaultLang) {
                                    $("#translate-step-" + element + "-" + index).show();
                                } else {
                                    $("#translate-step-" + element + "-" + index).hide();
                                }

                                if (currentImage != "") {
                                    $("#stepImageId-" + element + "-" + index).val(currentImage);
                                }
                                copiedStepsLang.push({ "lang": element, "step": index });

                                translationDone = true;
                            }

                        }
                    }
                });
            //}
            index++;
        }
    });



    if (index != 1 && translationDone) {
        if ($('#auto_translate_recipe_setting').val()) {

            var totalToTranslate = (languagesToTranslate.length * 2) * (index - 1);
            var stepsTranslated = 0;

            languagesToTranslate.forEach(element => {

                for (let i = 1; i <= index; i++) {

                    var elementToTranslate = $("#stepTitle-" + element + "-" + index);

                    var validByContent = elementToTranslate.length > 0 && ($("#stepTitle-" + element + "-" + i).val()).trim() != "";

                    var vlidByTranslate = checkIfStepNeedToBeTranslated(element, i, copiedStepsLang);


                    if (validByContent || vlidByTranslate) {

                        var parent_div = $("#stepIdNumber-" + sourceLang + "-" + i).parent().parent();

                        var title_to_translate = "";
                        var desc_to_translate = "";

                        parent_div.each(function (i, obj) {
                            desc_to_translate = ($(this).find('.step-description')).val();
                            title_to_translate = ($(this).find(':input.tt-input')).val();
                            if (title_to_translate == null) {
                                title_to_translate = ($(this).find(':input.step-title-input')).val();
                            }

                        });


                        if (($("#stepTitle-" + element + "-" + i).val()).trim() == "") {
                            if (title_to_translate != null && title_to_translate != "") {
                                // stepsToTranslet++;
                                text_translate(sourceLang, element, title_to_translate, function (data) {

                                    if (data['t'] != null) {
                                        $("#stepTitle-" + element + "-" + i).val(data['t']);
                                        stepsTranslated++;
                                        if (submitFormAfterFinish) {
                                            checkIfFormNeedToBeCalled(stepsTranslated, totalToTranslate);

                                        }
                                    }
                                });
                            }
                        } else {
                            stepsTranslated++;
                            if (submitFormAfterFinish) {
                                checkIfFormNeedToBeCalled(stepsTranslated, totalToTranslate);
                            }
                        }

                        if (($("#stepDescription-" + element + "-" + i).val()).trim() == "") {
                            if (desc_to_translate != null && desc_to_translate != "") {
                                //stepsToTranslet++;
                                text_translate(sourceLang, element, desc_to_translate, function (data) {

                                    if (data['t'] != null) {
                                        $("#stepDescription-" + element + "-" + i).val(data['t']);
                                        stepsTranslated++;

                                        if (submitFormAfterFinish) {
                                            checkIfFormNeedToBeCalled(stepsTranslated, totalToTranslate);

                                        }
                                    }
                                });
                            }
                        } else {
                            stepsTranslated++;
                            if (submitFormAfterFinish) {
                                checkIfFormNeedToBeCalled(stepsTranslated, totalToTranslate);
                            }

                        }

                    }

                }
            });

        }
    }
    $(".current-steps-count").val(current_state);
    if (submitFormAfterFinish && !translationDone) {

        $("form").submit();
    }
}



function checkIfFormNeedToBeCalled(current, total) {

    if (current >= total) {

        $("form").submit();
    }
}

window.selectedFiltersTexts = {};
window.icounter;

// let icounter;

function toggleFilter(selectedFilter) {
    window.icounter = $(selectedFilter).attr('data-icounter');
    const filterButtons = $(`.ingredient-filter-button[data-icounter="${icounter}"]`);
    const enabledFilters = filterButtons.not('.disabled');

    if ($(selectedFilter).hasClass('disabled')) {
        $(selectedFilter).removeClass('disabled');
    } else if (enabledFilters.length > 1) {
        $(selectedFilter).addClass('disabled');
    }

    const updatedEnabledFilters = filterButtons.not('.disabled');
    const selectedFiltersText = updatedEnabledFilters.map(function() {
        return $(this).text().toLowerCase();
    }).get().join(',');

    window.selectedFiltersTexts[icounter] = selectedFiltersText;
}

// Assign the function to the window object
window.toggleFilter = toggleFilter;

window.groups_ctrl = false; // check if ctrl-key + mouse-click on recipe groups checkbox
$('.categories-well-filter input[type=checkbox]').click(function (e) {
    if (window.ctrl_active) {
        window.groups_ctrl = true;
    }
});


$(document).ready(function () {
    var nutritionTagsType = $('#nutrition_tags_type').val();
    $('body').find('input[name="recipe_nutrition_tags_type"]').val(nutritionTagsType);

    var currentLang = getCurrentLanguage();
    //$("div.a[customattrib='2']")
    if ($(".step-section-content[step-lang='" + currentLang+"']").length == 0)
    {
        var languages = getAvailableLanguages();
        var langTranslateFrom = null;
        languages.forEach(element => {
            if ($(".step-section-content[step-lang='" + element + "']").length > 0)
            {
                langTranslateFrom = element;
            }
        });

        if(langTranslateFrom != null)
        {
            checkStepsLang(langTranslateFrom, false);
        }
    }

    if ($('.recipe-view .lmt').length) {
        $('.recipe-view .lmt-info').show();
    }
    if ($('.recipe-view .nnt').length) {
        $('.recipe-view .nnt-info').show();
    }

    // select/unselect all child categories if not ctrl-key + mouse-click
    $('body').on('change clicked', '.categories-well-filter input[type=checkbox]', function (e) {
        var selector = '#child_categories_' + this.value;
        var parent = this;
        var it = 0;
        if (!window.groups_ctrl) {
            $(selector).children('label').each(function () {
                var child = $(this).children('input');
                if (child[0].checked != parent.checked)
                    child[0].checked = parent.checked;
                it++;
            });
        }
        // if child category, run ?should it check/uncheck parent category as well
        if (it == 0 && !window.groups_ctrl) {
            var group_parent = "";
            if ($('.recipe-form-page')) {
                var group_parent_obj = $(parent).parents('.parent-cat');
                group_parent = group_parent_obj.children('input').val();
            } else {
                group_parent = window.categories[parent.value];
            }
            selector = '#child_categories_' + group_parent;

            var active = 0;
            $(selector).children('label').each(function () {
                var child = $(this).children('input');
                if (child[0].checked == true)
                    active++;
            });

            if ($('.recipe-form-page') && $(selector).children('label').length == 0) {
                if ($(this)[0].checked == true)
                    active++;
            }

            selector = '#categories_' + group_parent;

            $(selector).each(function () {
                var child = $(this).children('input');
                child[0].checked = active > 0;
            });
        }
        window.groups_ctrl = false;

        // update message for closed block
        var message_elements = [];
        $('#categories-list').children().each(function () {
            if ($(this).find('input[type="checkbox"]')[0].checked) {
                var message_element = "";
                var parentName = $(this).find('.rightbar-block-content-label')[0].innerText;
                var selectedChilds = 0;
                $(this).find('.subwell-content').children('label').each(function () {
                    if ($(this).find('input[type="checkbox"]')[0].checked) {
                        selectedChilds++;
                    }
                });
                if (selectedChilds != 0) {
                    message_element = parentName + "(" + selectedChilds + ")";
                } else {
                    message_element = parentName;
                }
                message_elements.push(message_element);
            }
        });
        if (message_elements.length > 0) {
            var finalMessage = message_elements.join(', ');
            $('.cats-empty-message').addClass('hidden');
            $(this).parents('.rightbar-block').addClass('groups-block-height-fix');
            $('.cats-message').removeClass('hidden');
            $('.cats-message')[0].innerText = finalMessage;
        } else {
            $('.cats-empty-message').removeClass('hidden');
            $(this).parents('.rightbar-block').removeClass('groups-block-height-fix');
            $('.cats-message').addClass('hidden');
        }

    });

    $('[name=price_type]').on('change', function () {
        var $this = $(this),
            brutto = ($this.val() == 'brutto'),
            wrapper = $('#recipe-price-wrapper');

        if (!brutto) {
            wrapper.removeClass('recipe-price-brutto').addClass('recipe-price-netto');
        } else {
            wrapper.removeClass('recipe-price-netto').addClass('recipe-price-brutto');
        }
    });

    // toggle sync single recipe
    $('#right-menu').find('.syncnnshare input:checkbox').on('click', function () {
        var cb = $(this),
            url = cb.attr('data-toggle');

        $.ajax({
            url: url,
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                cb.prop('checked', data.status === 'true');
            }
        });
        return false;
    });

    if ($('#auto_translate_recipe_setting').val()) {
        var empty_titles = $('.recipe-title .text-field').filter(function () {
                return !this.value;
            }),
            empty_descs = $('.recipe-description .text-field').filter(function () {
                return !this.value;
            });

        if (empty_titles.length || empty_descs.length)
            autotranslate_empty(empty_titles, empty_descs, 1);
    }

    var fixLastVisibleAllergen = function (prnt) {
        $('.allergen.show-name:not(.off)', prnt || $('body')).last().addClass('last-visible');
    };
    $('.allergo-values').each(function () {
        fixLastVisibleAllergen($(this));
    });

    // price update
    $('body').on('click fake-click', '.cmcalc-price-selector li a', function (e) {
        $(this).async(function (data) {
            if ('type' in data) {
                $('.recipe-view[data-cmcalc-update-url] .ingredients .row.ing').trigger('refresh');
                $('.recipe-form .ingredients .row[data-id]').each(function () {
                    cost_update($(this));
                });
            }
        });

        return false;
    });

    $('body').on('hide.bs.modal', '.modal.cmcalc-edit-modal', function () {
        var a = $('.last-clicked-cmcalc').removeClass('last-clicked-cmcalc');

        if ($('.recipe-form').length) {
            cost_update(a.closest('.ing-row'));
        } else if ($('.recipe-view[data-cmcalc-update-url]').length) {
            a.closest('.ing[data-id]').trigger('refresh');
        }
    });

    $(document).ready(function () {
        $('ingredient-quantity-amount').change(function () {
            alert("The text has been changed.");
        });
    });

    // cost update
    var cost_update = function (rows) {
        portion_size_update(function () {
            cost_update_do(rows)
        });
    };
    var cost_update_do = function (rows) {
        var params = [];

        rows.each(function () {
            var row = $(this);
            params.push({
                id: row.find('.recipe-ingredient-id').val(),
                ingredient_id: row.find('.ingredient-id').val(),
                ing_recipe_id: row.find('.ingredient-recipe-id').val(),
                product_group_id: row.find('.ingredient-product-group-id').val(),
                quantity: row.find('.ingredient-quantity-amount').val(),
                quantity_unit: row.find('.ingredient_quantity_unit').val()
            })
        });
        var costs = rows.find('.ingredient-cost-div');

        $.ajax({
            url: recipe_form.attr('data-cmcalc-update-url'),
            method: 'post',
            data: { ingredients: params },
            beforeSend: function () {
                costs.find('.ingredient-cost').addClass('loading');
            },
            success: function (data) {
                if ('costs' in data) {
                    for (var i = 0, len = data.costs.length; i < len; i++) {
                        var icost = data.costs[i];
                        var id = icost.id;
                        var iid = icost.ingredient_id;
                        var irid = icost.ing_recipe_id;
                        var pgid = icost.product_group_id;
                        var quantity = toNumber(icost.quantity);
                        var unitGramQuantity = icost.unit_gram_quantity;

                        var ugqIndex = null;
                        if (id) {
                            ugqIndex = id;
                        } else if (iid) {
                            ugqIndex = iid;
                        } else if (irid) {
                            ugqIndex = irid;
                        } else if (pgid) {
                            uggIndex = pgid;
                        }

                        if (unitGramQuantity != null) {
                            $('[data-id="' + ugqIndex + '"] .customunit-weight-quantity').html(unitGramQuantity);
                            $('[data-id="' + ugqIndex + '"] .customunit-weight-unit').html('g');
                        } else {
                            $('[data-id="' + ugqIndex + '"] .customunit-weight-quantity').html('');
                            $('[data-id="' + ugqIndex + '"] .customunit-weight-unit').html('');
                        }
                        if (iid || irid || pgid) {
                            rows.find('.ingredient-id[value="' + iid + '"], .ingredient-recipe-id[value="' + irid + '"], .ingredient-product-group-id[value="' + pgid + '"]').each(function () {
                                var row = rows.filter(function () {
                                    var $row = $(this);
                                    if (!$row.find('.ingredient-id[value="' + iid + '"]').length &&
                                        !$row.find('.ingredient-recipe-id[value="' + irid + '"]').length &&
                                        !$row.find('.ingredient-product-group-id[value="' + pgid + '"]').length) {
                                        return false;
                                    }
                                    if ('_error' in icost) {
                                        return true;
                                    }
                                    if (toNumber($row.find('.ingredient-quantity-amount').val()) != toNumber(icost.quantity)) {
                                        return false;
                                    }
                                    if ($row.find('.ingredient_quantity_unit').val() != icost.quantity_unit) {
                                        return false;
                                    }
                                    return true;
                                });

                                var div = row.find('.ingredient-cost-div');
                                var cost = div.find('.ingredient-cost');

                                if ('_error' in icost) {
                                    div.addClass('has-error');
                                    var wrap = $('.cmcalc-master-wrap');

                                    if (!div.data('error-shown') && !wrap.hasClass('height-transition-hidden') && wrap.is(':visible')) {
                                        cost.showPopover(icost._error, 5000);
                                        setTimeout(function () {
                                            cost.popover({
                                                container: 'body',
                                                placement: 'top',
                                                trigger: 'hover click',
                                                content: icost._error
                                            });
                                        }, 5000);
                                        cost.on('mouseover', function () {
                                            if (!$(this).closest('.ingredient-cost-div').hasClass('has-error')) {
                                                cost.popover('destroy');
                                                cost.off('mouseover');
                                            }
                                        });
                                        div.data('error-shown', 1);
                                    }
                                    cost.val('');
                                } else if ('cost' in icost) {
                                    div.removeClass('has-error');
                                    cost.val(toPrice(icost.cost, 4));
                                    cost.trigger('change');
                                }
                            });
                        }
                    }
                } else {
                    div.removeClass('has-error');
                }

            },
            complete: function () {
                costs.find('.ingredient-cost').removeClass('loading');
            }
        });
    };

    var recipe_form = $('.recipe-form');

    $('input[name="portion_size_unit"]').on('change', function () {
        if(recipe_form.find('[name=auto_calculate_portion_size]').is(':checked')) {
            portion_size_update(null, true);
        }
    });

    // portion_size update
    var portion_size_update = function (success_cb, ignore_auto_calculate_button) {
        var params = [];

        recipe_form.find('.ing-row.ingredient-selected').each(function () {
            var row = $(this);
            params.push({
                ingredient_id: row.find('.ingredient-id').val(),
                ing_recipe_id: row.find('.ingredient-recipe-id').val(),
                product_group_id: row.find('.ingredient-product-group-id').val(),
                quantity: row.find('.ingredient-quantity-amount').val(),
                quantity_unit: row.find('.ingredient_quantity_unit').val(),
                loss: row.find('.ingredient-loss').val()
            })
        });

        $.ajax({
            url: recipe_form.attr('data-weight-recalc-url'),
            method: 'post',
            data: {
                ingredients: params,
                use_recipe_loss: $('[name=use_recipe_loss]').prop('checked') ? 1 : 0,
                recipe_loss: $('[name=recipe_loss]').val()
            },
            beforeSend: function () {
            },
            success: function (data) {
                var persons;
                var persons_input = recipe_form.find('[name=persons]');

                if (persons_input.length) {
                    persons = persons_input.val();
                }
                if (!persons || !$.isNumeric(persons)) {
                    persons = 1
                }

                if (ignore_auto_calculate_button || recipe_form.find('[name=auto_calculate_portion_size]').is(':checked')) {
                    // get selected portion size unit
                    var portion_size_unit = $('.portion-size-unit-value li:is(.selected) a').data('value');
                    var portion_size_new, portion_size_formatted;
                    switch(portion_size_unit){
                        case 'g':
                            portion_size_new = data.weight / persons;
                            portion_size_formatted = portion_size_new;
                            break;
                        case 'ml':
                            portion_size_new = data.weight / persons;
                            portion_size_formatted = portion_size_new;
                            break;
                        case 'kg':
                            portion_size_new = (data.weight / persons) / 1000;
                            portion_size_formatted = parseFloat(portion_size_new).toFixed(3);
                            portion_size_formatted = (portion_size_formatted*1000)%1000 == 0 ? parseInt(portion_size_formatted) : portion_size_formatted;
                            break;
                        case 'l':
                            portion_size_new = (data.weight / persons) / 1000;
                            portion_size_formatted = parseFloat(portion_size_new).toFixed(3);
                            portion_size_formatted = (portion_size_formatted*1000)%1000 == 0 ? parseInt(portion_size_formatted) : portion_size_formatted;
                            break;
                        default:
                            portion_size_formatted = 0;
                    }

                    var size = recipe_form.find('[name=portion_size]');
                    size.val( localizedNumber( portion_size_formatted ) );

                    size.blinkMe();

                    var focused = $(document.activeElement);

                    var unit = recipe_form.find('[name=portion_size_unit]');
                    $('.portion-size-unit-dropdown-btn').attr('data-quantity-unit', portion_size_unit.trim());
                    document.getElementsByClassName('portion-size-unit-dropdown-btn')[0].innerHTML = ' ' + portion_size_unit.trim() + ' <span class="caret"></span>';
                    unit.val(portion_size_unit);
                    unit.siblings('.dropdown-menu').find('li[class=selected]').toggleClass('selected', false);
                    unit.siblings('.dropdown-menu').find('a[data-value=' + portion_size_unit + ']').parent().toggleClass('selected', true);

                    if (focused.hasClass('ingredient-quantity-amount')) {
                        focused.data('no-select', 1);
                        focused.focus();
                    } else {
                        focused.focus();
                    }
                }


                if (success_cb) {
                    success_cb.call();
                }
            }
        });
    };
    recipe_form.on('updated', '.ing-row .ingredient-loss', function () { portion_size_update(); })
    $('#weight-recalculate').on('click', function () {
        portion_size_update(null, true);
    });

    /**
     * REMOVE INGREDIENT INFORMATION IF NAME IS DELETED ON INPUT
     */
    $('.recipe-form input.ingredient-name').on('keyup', function () {
        if([67,17].includes(e.keyCode)) {
            return;
         }
        var row = $(this).closest('.ing-row, .recipe-row');
        row.removeClass("ingredient-selected");

        if ($(this).val() == 0) {
            var ing_search = row.find('input.typeahead')
                .typeahead('destroy')
                .off('typeahead:selected')
                .off('typeahead:autocompleted');
            var icounter = guid();
            var inputs = row.find('input').val('').removeAttr('data-value');
            inputs.filter('.recipe-ingredient-id').attr('name', 'ingredient[' + icounter + '][recipe_ingredient_id]');
            inputs.filter('.ingredient-id').attr('name', 'ingredient[' + icounter + '][ingredient_id]');
            inputs.filter('.ingredient-recipe-id').attr('name', 'ingredient[' + icounter + '][ing_recipe_id]');
            inputs.filter('.ingredient-group-id').attr('name', 'ingredient[' + icounter + '][group_id]');
            inputs.filter('.ingredient-product-group-id').attr('name', 'ingredient[' + icounter + '][product_group_id]');
            inputs.filter('.ingredient-name').attr('name', 'ingredient_name[' + icounter + ']');
            inputs.filter('.ingredient-quantity-amount').attr('name', 'ingredient[' + icounter + '][quantity]');
            inputs.filter('.ingredient_quantity_unit').attr('name', 'ingredient[' + icounter + '][quantity_unit]');
            inputs.filter('.ingredient-loss').attr('name', 'ingredient[' + icounter + '][loss]');
            row.attr('data-id', ""); //remove data-id pasted from added ingredient
            row.find('input.typeahead').add_ingredients_typeahead();
            row.find('.blsd-bg').text('');
            row.find('input').css('background-image', '');

            /* // removes whole row on empty input field
            var row = $(this).closest('.ing-row, .recipe-row').trigger('removed');
            if(row.attr('data-id') != "") // if row is not last row for input new ingredient
                row.slideUp(function () {
                    row.remove();
                });
             */
        }
    });

    if (recipe_form.length) {
        var recipe_new = recipe_form.hasClass('recipe-new');

        $('[name=auto_calculate_portion_size]').on('change', function () {
            var $this = $(this),
                checked = $this.is(':checked');
            $.ajax({
                url: '/settings/user/save/single',
                type: 'post',
                data: {
                    k: 'auto_calculate_portion_size',
                    v: checked
                }
            });

            if (checked) {
                portion_size_update(null, true);
            }
        });

        $('[name=price], [name=persons], [name=portion_size]').on('focus', function () {
            $(this).select();
        });
        $('body').on('focus', '.ing-row input.ingredient-quantity-amount', function () {
            var $this = $(this);

            if ($this.data('no-select') != 1) {
                $(this).select();
            }

            $this.removeData('no-select');
        });

        // ingredient sorting
        recipe_form.on('add-ingredient-sorting', '.ingredients', function () {
            var $this = $(this);
            if ($this.hasClass('ui-sortable')) {
                $this.sortable('destroy');
            }

            $this.sortable({
                items: '> .ing-row.ingredient-selected',
                axis: 'y',
                scroll: true,
                containment: 'parent',
                sort: function (e, ui) {
                    ui.helper.css({ 'top': ui.position.top + $(window).scrollTop() + 'px' });
                },
            })
        });
        // group sorting sorting
        recipe_form.on('add-group-sorting', '.ingredients', function () {
            var $this = $(this);
            if ($this.hasClass('ui-sortable')) {
                $this.sortable('destroy');
            }
            $this.sortable({
                items: '> .group-row',
                axis: 'y',
                scroll: true,
                containment: 'parent',
                handle: '.group-name-div',
                tolerance: 'pointer',
                sort: function (e, ui) {
                    ui.helper.css({ 'top': ui.position.top + $(window).scrollTop() + 'px' });
                },
            })
        });
        // group ingredients sorting
        recipe_form.on('add-group-ingredients-sorting', '.group-row:not(.hidden)', function (a, b) {
            var $this = $(this), ings = $this.find('.group-ingredients');
            if (ings.hasClass('ui-sortable')) {
                ings.sortable('destroy');
            }

            ings.sortable({
                items: '> .ing-row.ingredient-selected',
                axis: 'y',
                scroll: true,
                containment: '.ingredients',
                // connect with other groups to be able to drag&drop between groups
                connectWith: '.group-ingredients.ui-sortable',
                sort: function (e, ui) {
                    ui.helper.css({ 'top': ui.position.top + $(window).scrollTop() + 'px' });
                },
                // there is a bug when you want to drop item from one group to 
                // empty another group. ingredient gets somehow thrown below 
                // "ingredient search" instead of above. this beforeStop funct
                // fixes that by moving search to the bottom if it is not last item.
                beforeStop: function (e, ui) {
                    var p = $(ui.item).closest('.group-ingredients');
                    var ing_search = p.find('.ing-row:not(.ingredient-selected)');
                    // if ing_search is not on the bottom, move it there
                    if (ing_search.length && ing_search.next('.ing-row.ingredient-selected')) {
                        p.append(ing_search);
                    }
                },
                // on drop, set correct group_id for this ingredient
                deactivate: function (e, ui) {
                    var ing_row = $(ui.item),
                        //ingredient group
                        group_id = ing_row.closest('.group-row').find('.group-id').val();
                        ing_row.find('.ingredient-group-id').val(group_id)
                        //product group
                        product_group_id = ing_row.closest('.group-row').find('.product-group-id').val();
                        ing_row.find('.ingredient-product-group-id').val(product_group_id)
                }
            }).closest('.ingredients').find('.group-ingredients.ui-sortable').sortable('refresh')
        });
        // allow recipe sorting
        recipe_form.find('.recipes').sortable({
            items: '.recipe-row',
            axis: 'y',
            containment: 'parent'
        });
        // add sorting on start
        if (recipe_form.find('.ingredients .group-row:not(.hidden)').length) {
            recipe_form.find('.ingredients').trigger('add-group-sorting')
                .find('.group-row').trigger('add-group-ingredients-sorting');
        } else {
            recipe_form.find('.ingredients').trigger('add-ingredient-sorting');
        }


        //ingredients auto complete
        $.fn.cleanUpIngredient = function () {
            var that = $(this);
            var val = that.attr('data-value');

            if ($.trim(that.val()).length == 0) {
                val = that.attr('data-value');
                that.removeAttr('data-value').removeAttr('data-name')
                    .name('ingredient_name[]').css('background-image', '')
                    .siblings('.ingredient-name').css('background-image', '')
                    .find('.blsd-bg').text('');

                that.parents('.ingredient-selected').removeClass('ingredient-selected');
            }
            if (!val || val.length == 0) {
                that.addClass('has-error');
            } else {
                that.removeClass('has-error');
            }
        };

        // fix ingredient order
        var fixIngredientOrder = function (form) {
            form.find('input[name^=ingredient_ordr]').remove();
            form.find('input.ingredient-id').each(function (i) {
                if ($(this).attr('name').match(/^ingredient\[(.*?)\]/)) {
                    var id = RegExp.$1;
                    if (id) {
                        form.append('<input type="hidden" name="ingredient_ordr[' + id + ']" value="' + i + '" />');
                    }
                }
            });
            form.find('input[name^=recipe_ordr]').remove();
            form.find('input.recipe-id').each(function (i) {
                form.append('<input type="hidden" name="recipe_ordr[' + $(this).val() + ']" value="' + i + '" />');
            });
        };

        var ingredientQuantitiesZero = function () {
            var quantities_available = false;
            recipe_form.find('.ingredients .ingredient-quantity-amount').each(function () {
                var $this = $(this), val = $.trim($this.val());
                if (val.length > 0 && val != 0) {
                    quantities_available = true;
                    return false;
                }
            });
            return !quantities_available;
        };

        if (!recipe_form.find('.allergo-values .allergen:not(.off)').length) {
            recipe_form.find('.no-allergens:not(.recipe-imported)').addClass('visible');
        }

        var activeElement;
        // recipes autocomplete
        var recipesBH = new Bloodhound({
            datumTokenizer: function (d) {
                return Bloodhound.tokenizers.whitespace(d.val)
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            limit: 15,
            // preload the most used recipes
            //prefetch: recipe_form.attr('data-url-ings-prefetch')
            remote: {
                url: recipe_form.attr('data-url-recipes') + '/%QUERY',
                ajax: {
                    beforeSend: function () {
                        var aE = $(document.activeElement);
                        if (!aE.length || aE.is('body')) {
                            return;
                        }
                        activeElement = aE.addClass('loading')
                    },
                    complete: function () {
                        if (activeElement) {
                            activeElement.removeClass('loading')
                        }
                    }
                },
                // filter out recipes which are already on the list
                filter: function (ings) {
                    var el = activeElement || $(document.activeElement);
                    el.removeClass('loading');

                    var ids = $.map(el.parents('.recipes').first().find('.recipe-id'), function (input) {
                        return $(input).val() * 1;
                    });

                    return (ids.length == 0) ? ings : $.map(ings, function (ing) {
                        if ($.inArray(ing.id * 1, ids) == -1) {
                            return ing;
                        }
                    });
                }
            },
        });
        recipesBH.initialize();
        recipe_form.find('.recipe-find').each(function () {
            $(this).typeahead({
                minLength: 1,
                highlight: true,
            }, {
                displayKey: 'name',
                name: 'recipes',
                source: recipesBH.ttAdapter(),
                templates: {
                    suggestion: Handlebars.compile($('#recipe-typeahead-tmpl').html()),
                    empty: Handlebars.compile($('#recipe-typeahead-empty-tmpl').html()),
                }
            }).on('typeahead:selected', function (jQ, o, s) {
                $(jQ.target).trigger('add-recipe', o);
            })
        })
        // add recipe event (to combined recipe)
        recipe_form.on('add-recipe', '.recipe-find', function (e, o) {
            var tmpl = $(Handlebars.compile($('#add-recipe-tmpl').html())(o));
            var input = $(this);
            input.parents('.recipe-find-row').before(tmpl);
            input.typeahead('val', '');

            var cost = tmpl.find('.ingredient-cost-div');
            var alls = tmpl.find('.recipe-allergens-div');
            if (alls.length) {
                alls.addClass('loading');
                cost.addClass('loading');

                $.ajax({
                    url: o.aurl,
                    success: function (data) {
                        if ('allergens' in data) {
                            alls.html($(data.allergens));
                        }
                        if ('cost' in data) {
                            cost.html($(data.cost));
                        }
                    },
                    complete: function () {
                        alls.removeClass('loading');
                        cost.removeClass('loading');
                        $('.ingredient-cost').first().trigger('change');
                    }
                });
            }
        });

        // ingredients autocomplete
        var ingredientsBH = new Bloodhound({
            datumTokenizer: function (d) {
                return Bloodhound.tokenizers.whitespace(d.val)
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            limit: 15,
            // preload the most used ingredients
            //prefetch: recipe_form.attr('data-url-ings-prefetch')
            remote: {
                url: recipe_form.attr('data-url-ings') + '/%QUERY' + '?filter=recipes,products,groups',
                replace: function (url, query) {
                    if (Object.keys(window.selectedFiltersTexts).length) {
                        url = url.replace('recipes,products,groups', window.selectedFiltersTexts[icounter]);
                    }
                    return url.replace('%QUERY', encodeURI(query));
                },
                ajax: {
                    beforeSend: function () {
                        var aE = $(document.activeElement);
                        if (!aE.length || aE.is('body')) {
                            return;
                        }
                        activeElement = aE.addClass('loading');
                        $('div.filter-buttons[data-icounter="' + icounter + '"]').addClass('move-filter-buttons');
                    },
                    success: function (data) {
                        var recipe_id = $('#recipe-id').val();
                        // remove recipe suggestion with same ID as recipe for which this detailed search is performed
                        for (var i = 0; i < data.length; i++) {
                            if (data[i]['ing_recipe_id'] !== 'undefined') {
                                if (Number(data[i]['ing_recipe_id']) === Number(recipe_id)) {
                                    data.splice(i, 1);
                                }
                            }
                        }
                    },
                    complete: function () {
                        if (activeElement) {
                            activeElement.removeClass('loading');
                            $('div.filter-buttons[data-icounter="' + icounter + '"]').removeClass('move-filter-buttons');
                        }
                    }
                },
                // filter out ingredients which are already on the list
                xfilter: function (ings) {
                    var el = activeElement || $(document.activeElement);
                    el.removeClass('loading');
                    $('div.filter-buttons[data-icounter="' + icounter + '"]').removeClass('move-filter-buttons');

                    var ids = $.map(el.parents('.ingredients').first().find('.ingredient-selected .ingredient-name.tt-input'), function (input) {
                        return $(input).attr('data-value') * 1;
                    });

                    return (ids.length == 0) ? ings : $.map(ings, function (ing) {
                        if ($.inArray(ing.id * 1, ids) == -1) {
                            return ing;
                        }
                    });
                }
            },
            // this is here just to override typeahead built in sorter
            // to be able to use server sorting
            sorter: function (a, b) {
                return a.o - b.o
            }
        });

        var ac_tmpls = {
            suggestion: $('#ingredient-typeahead-tmpl'),
            footer: $('#ingredient-typeahead-footer-tmpl'),
            empty: $('#ingredient-typeahead-empty-tmpl')
        };
        var ac_tmpl = {
            suggestion: ac_tmpls.suggestion.length ? Handlebars.compile(ac_tmpls.suggestion.html()) : null,
            footer: ac_tmpls.footer.length ? Handlebars.compile(ac_tmpls.footer.html()) : null,
            empty: ac_tmpls.empty.length ? Handlebars.compile(ac_tmpls.empty.html()) : null
        };
        ingredientsBH.initialize();
        $.fn.add_ingredients_typeahead = function () {
            var $this = $(this);
            if (!$this.length) {
                return $this;
            }

            $this.typeahead({
                minLength: 1,
                highlight: true,
            }, {
                displayKey: 'name',
                name: 'ingredients',
                source: ingredientsBH.ttAdapter(),
                templates: ac_tmpl

            }).on('typeahead:selected', function (jQ, o, s) {
                $(jQ.target).trigger('add-ingredient', o);

            }).focusin(function () {
                var that = $(this);
                window.icounter = that.attr('data-icounter');
                const filterButtons = $(`.ingredient-filter-button[data-icounter="${icounter}"]`);
                const updatedEnabledFilters = filterButtons.not('.disabled');

                const selectedFiltersText = updatedEnabledFilters.map(function() {
                    return $(this).text().toLowerCase();
                }).get().join(',');

                window.selectedFiltersTexts[icounter] = selectedFiltersText;

                if ($.trim(that.val()).length == 0) {
                    that.removeAttr('data-value').removeAttr('data-name');
                } else {
                    that.typeahead('val', that.attr('data-name'))
                }

            }).focusout(function () {
                var that = $(this);
                that.cleanUpIngredient();
                that.typeahead('val', that.attr('data-name'))

            }).on('keydown keyup keypress', function (e) {
                var that = $(this);

                if ($.trim(that.val()).length == 0) {
                    that.cleanUpIngredient();
                }
            }).on('change', function (e) {
                $(this).cleanUpIngredient();
            })

            return $this;
        };

        // add ingredient event
        recipe_form.on('add-ingredient', '.ingredient-name', function (e, o) {
            var input = $(this),
                group = input.parents('.form-group'),
                row = input.parents('.ing-row');

            input.attr('data-value', o.id);

            var lang = $('.lang-tabs li.active').attr('data-lang');
            input.attr('data-name', o.name);

            if ('names' in o) {
                if (o.names[lang]) {
                    input.attr('data-name', o.names[lang]['name']);
                }
                $.each(o.names, function (lang, itm) {
                    input.attr('data-lang-' + lang, itm['name']);
                });
            }

            if (o.no_autocomplete) {
                input.val(o.name);
                input.typeahead('val', o.name);
            }
            //input.css('background-image', 'url('+o.photo+')');

            // set ingredient and recipe ids
            row.find('.ingredient-id').val(o.ingredient_id);
            row.find('.ingredient-recipe-id').val(o.ing_recipe_id);
            row.find('.ingredient-product-group-id').val(o.product_group_id);

            // remove class hidden for gesoca-row template
            row.find('.gesoca-row').removeClass('hidden');

            // set group id
            var group_row = input.closest('.group-row');
            if (group_row.length) {
                var group_id = group_row.find('.group-id').val();
                row.find('.ingredient-group-id').val(group_id);

                //var product_group_id = group_row.find('.product-group-id').val();
                //row.find('.ingredient-product-group-id').val(product_group_id);
            }

            row.attr('data-id', o.id);
            if (!row.hasClass('hide-quantities')) {
                row.find('.ingredient-name-div').removeClass('col-xs-12 col-md-12').addClass('col-xs-6 col-md-8');
            }

            if (o.blsd) {
                row.find('.blsd-bg').text(o.blsd);
            }
            var na = row.find('.na-bg');
            if (o.na) {
                if (!na.length) {
                    na = $('<span class="na-bg" title="na-bg"><i class="glyphicon glyphicon-exclamation-sign warnsign"></i></span>').attr('title', recipe_form.find('.ingredients').attr('data-trans-ingrediant-na'));
                }
                row.find('.ingredient-name-div').append(na);
            } else {
                na.remove();
            }

            row.addClass('allergens-not-calculated');
            row.addClass('ingredient-selected');
            row.find('input').trigger('load-originals');

            setTimeout(function () {
                group.find('.ingredient-quantity-amount').focus();
            }, 200);
            row.trigger('changed');

            // cmcalc
            if ('cmcalc_url' in o) {
                row.find('.cmcalc-edit-url').attr('href', o.cmcalc_url);
            }
            else {
                row.find('.cmcalc-edit-url').attr('href', '#');
            }

            // add to cart
            if ('shop_url' in o) {
                row.find('.ingredient-add-to-cart a').attr('href', o.shop_url);
            }

            // add to list(s)
            if ('lists_url' in o) {
                row.find('.add-to-list').attr('href', o.lists_url);
            }

            //group.find('.ingredient-selected').name('ingredient_selected['+o.id+']');

            row.trigger('changed');
            // unit list: clone, empty, add dropdown
            var qgroup = group.find('.ingredient-quantity-selector');
            var cgroup = qgroup.clone();
            qgroup.after(cgroup);
            qgroup.remove();
            cgroup.find('li').remove();
            cgroup.find('.dropdown-toggle').dropdown();
            var list = cgroup.find('.dropdown-menu');
            var ru_title_set = false;
            $.each(o.unit, function (k, v) {
                if (k.includes('ru')) {
                    if (!ru_title_set) {
                        var ru_title = $(Handlebars.compile($('#global-recipe-unit-tmpl').html())());
                        list.append(ru_title);
                        ru_title_set = true;
                    }
                    list.append('<li class="recipe-units"><a href="#" data-value="' + k + '">' + v + '</a></li>');

                } else {
                    list.append('<li><a href="#" data-value="' + k + '">' + v + '</a></li>');
                }
            });
            var add_unit = $(Handlebars.compile($('#ingredient-unit-add-tmpl').html())());
            list.append(add_unit);
            cgroup.triggerHandler('updated');
            cgroup.find('li a').first().click();

            var unit_button = group.find('button[data-dropdown-menu-input-name=ingredient_quantity_unit].dropdown-toggle');
            var unit = unit_button.attr('data-quantity-unit');
            group.find('input[type=hidden].ingredient_quantity_unit').val(unit);

            var form = input.parents('form').first();

            // add new line only if there are no empty lines
            var empty_available = false;
            row.nextAll('.ing-row').find('input.ingredient-name').each(function () {
                var data_value = $(this).attr('data-value');
                if (!data_value || data_value.length == 0) {
                    empty_available = true;
                    return false;
                }
            });

            // refresh sorting if available
            group_row.trigger('add-group-ingredients-sorting');

            if (empty_available) {
                return;
            }

            try {
                input.typeahead('destroy').off('typeahead:selected');
            } catch (e) {
            }

            var nrow = row.clone(true).fadeOut();
            nrow.removeClass('allergens-not-calculated nutritions-not-calculated nutritions-data-changed');

            var icounter = guid();
            input.add_ingredients_typeahead();
            var inputs = nrow.find('input').val('').removeAttr('data-value');
            inputs.filter('.recipe-ingredient-id').attr('name', 'ingredient[' + icounter + '][recipe_ingredient_id]');
            inputs.filter('.ingredient-id').attr('name', 'ingredient[' + icounter + '][ingredient_id]');
            inputs.filter('.ingredient-recipe-id').attr('name', 'ingredient[' + icounter + '][ing_recipe_id]');
            inputs.filter('.ingredient-group-id').attr('name', 'ingredient[' + icounter + '][group_id]');
            inputs.filter('.ingredient-product-group-id').attr('name', 'ingredient[' + icounter + '][product_group_id]');
            inputs.filter('.ingredient-name').attr('name', 'ingredient_name[' + icounter + ']');
            inputs.filter('.ingredient-quantity-amount').attr('name', 'ingredient[' + icounter + '][quantity]');
            inputs.filter('.ingredient_quantity_unit').attr('name', 'ingredient[' + icounter + '][quantity_unit]');
            inputs.filter('.ingredient-loss').attr('name', 'ingredient[' + icounter + '][loss]');
            inputs.filter('.ingredient-cooking-method').attr('name', 'ingredient[' + icounter + '][cooking_method]');
            inputs.filter('.ingredient-holding-time').attr('name', 'ingredient[' + icounter + '][holding_time]');
            nrow.find('.ingredient-name-div').addClass('col-xs-12 col-md-12').removeClass('col-xs-6 col-md-8');
            // hide gesoca-row for nrow (cloned object)
            nrow.find('.gesoca-row').addClass('hidden');
            nrow.removeClass('ingredient-selected');
            nrow.find('.typeahead').add_ingredients_typeahead();
            nrow.find('li').remove();
            nrow.find('.blsd-bg').text('');
            nrow.find('input').css('background-image', '');
            nrow.attr('data-id', ""); //remove data-id pasted from added ingredient
            row.after(nrow);
            nrow.fadeIn();
            nrow.find('.ingredient-quantity-selector').trigger('updated');
        });

        // autocomplete ingredient
        recipe_form.find('.ingredient-name.typeahead').add_ingredients_typeahead();

        var nutrition_data_changed_timeout;
        // update ing rows
        recipe_form.on('start changed removed allergens-updated nutritions-updated nutrition-data-changed', '.ing-row, [name=persons], [name=portion_size], [name=recipe_loss]', function (e) {
            var row = $(this);
            var is_person = (row.attr('name') == 'persons');
            var is_portion_size = (row.attr('name') == 'portion_size');
            var is_recipe_loss = (row.attr('name') == 'recipe_loss');

            // updated loss weight
            if (is_person || is_recipe_loss) {
                recipe_form.find('.ing-row .ingredient-loss:first').trigger('updated');
            }

            var rows;
            if (e.type != 'removed') {
                rows = recipe_form.find('.ingredients .ing-row.ingredient-selected');
            } else {
                rows = row.siblings('.ing-row.ingredient-selected');
            }

            var ir = recipe_form.find('.calculation-info-row'),
                calc_btn = recipe_form.find('.btn-calculate');

            var enableCalc = function () {
                ir.slideDown();
                calc_btn.removeAttr('disabled').removeClass('disabled');
            };
            var disableCalc = function () {
                ir.slideUp();
                calc_btn.attr('disabled', 'disabled');
            };

            var updateCost = function (ing_updated, timeout) {
                clearTimeout(row.data('nutrition_data_changed_timeout'));
                row.data('nutrition_data_changed_timeout', setTimeout(function () {
                    !ing_updated ? $('.ingredient-cost').trigger('force-change') : cost_update(row)
                }, timeout || 800));
            };

            // var updateWeight = function(timeout) {
            //     clearTimeout(row.data('nutrition_data_changed_timeout'));
            //     row.data('nutrition_data_changed_timeout', setTimeout(function() {
            //         weight_update(row)
            //     }, timeout || 800));
            // };

            // empty list? disable buttons
            if (!rows.length) {
                disableCalc();
                updateCost();
                return;
            }

            // recalculated? disable buttons
            if (e.type == 'allergens-updated' || e.type == 'nutritions-updated') {
                disableCalc();
                return;
            }

            // removed row
            if (e.type == 'removed') {
                if (!row.hasClass('allergens-not-calculated') || !row.hasClass('nutritions-not-calculated')) {
                    enableCalc();
                    updateCost();
                }
                return;
            }

            // added/changed row
            if (rows.hasClass('allergens-not-calculated') || (!ingredientQuantitiesZero() && (rows.hasClass('nutritions-not-calculated'))) || rows.hasClass('nutritions-data-changed') || $('#recipe-persons-number').hasClass('nutritions-data-changed') || $('#portion-size').hasClass('nutritions-data-changed')) {
                enableCalc();
            } else {
                disableCalc();
            }

            if (is_person || is_portion_size) {
                updateCost(false, 100);
            } else if (recipe_form.attr('data-cmcalc-update-url') && e.type == 'nutrition-data-changed') {
                updateCost(true)
            }

            // if (recipe_form.attr('data-gwcalc-update-url') && e.type == 'nutrition-data-changed') {
            //     updateWeight(true)
            // }

        }).find('.ing-row').first().trigger('start');


        // remove ingredient
        recipe_form.on('click', '.ingredient-remove-div .close, .recipe-remove-div .close, .recipe-edit-dropdown-menu .ingredient-remove-item', function () {
            var row = $(this).closest('.ing-row, .recipe-row').trigger('removed');
            row.slideUp(function () {
                row.remove();
            });
        });

        recipe_form.on('keydown', 'input', function (e) {
            if (e.which == 13) { // enter
                var $this = $(this), focused = false;
                // enter in quantity input
                if ($this.hasClass('ingredient-quantity-amount')) {
                    // go to next row
                    var next_row = $this.closest('.ing-row').next('.ing-row');
                    // no next? are you in a group?
                    if (!next_row.length || next_row.is(':hidden')) {
                        var this_group_row = $this.closest('.group-row');
                        var group_row = this_group_row.next('.group-row:not(.hidden)');
                        // no next group? take current group and focus search
                        if (!group_row.length && this_group_row.length) {
                            this_group_row.trigger('add-search');
                            focused = true;

                        } else
                            // next group
                        if (group_row.length) {
                            next_row = group_row.find('.ing-row:first');

                            // nothing else in the group? focus search
                            if (!next_row.length || next_row.is(':hidden')) {
                                group_row.trigger('add-search');
                                focused = true;
                            }
                        }
                    }
                    if (next_row.length && !focused) {
                        if (!next_row.find('.ingredient-quantity-amount:visible').focus().select().length) {
                            next_row.find('.ingredient-name.tt-input:first, input[type=text]:not(.tt-hint):first').first().focus();
                        }
                    }
                }
                e.stopImmediatePropagation();
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        });

        // catch changes
        recipe_form.find('.ingredients-wrapper').on('keyup change keypress keydown updated', 'input.ingredient-quantity-amount, input.ingredient_quantity_unit, input[name=persons], [name=portion_size], [name=recipe_loss]', function (e, data) {
            var changed = false, $this = $(this);
            if (e.which && e.which == 9) {
                return
            }

            if (e.type == 'change' && $this.attr('name') != 'persons') {
                //return;
            }

            clearTimeout($this.data('timeout'));
            $this.data('timeout', setTimeout(function () {
                var name = $this.attr('name');
                if (name == 'persons' || name == 'portion_size' || name == 'recipe_loss') {
                    var orig = $this.data('orig');
                    if (orig && orig.length > 0 && orig != $this.val()) {
                        changed = true;
                    }
                    $this.closest('div').toggleClass('nutritions-data-changed', changed);
                    $this.trigger('changed');

                } else {
                    var ing_row = $this.parents('.ing-row');
                    ing_row.find('input.ingredient-quantity-amount, input.ingredient_quantity_unit').each(function () {
                        var orig = $this.data('orig');
                        if (orig != $this.val()) {
                            changed = true;
                            return false;
                        }
                    });
                    ing_row.toggleClass('nutritions-data-changed', changed).trigger('nutrition-data-changed');
                }
            }, 400));
        });
        // save last used/saved values in the form
        recipe_form.on('load-originals', function () {
            recipe_form.find('.ingredients input.ingredient-quantity-amount, .ingredients input.ingredient_quantity_unit, input[name=persons], input[name=portion_size]').each(function () {
                var $this = $(this);
                $this.data('orig', $this.val())
            })
        }).trigger('load-originals');
        recipe_form.on('load-originals', '.ingredients input.ingredient-quantity-amount, .ingredients input.ingredient_quantity_unit, input[name]', function () {
            var $this = $(this);
            $this.data('orig', $this.val())
        });

        var calc = {};
        // calc on button
        recipe_form.find('[name=calc-allergens], [name=calc-nutritions]').on('click clicked', function (e) {
            var btn = $(this);
            fixIngredientOrder(recipe_form);

            var allergens_well = $('#right-menu').find('.allergens'),
                nutritions_well = $('#right-menu').find('.nutritions');

            clearTimeout(calc.timeout);
            calc.timeout = setTimeout(function () {
                $.ajax({
                    url: recipe_form.attr('data-url-calc'),
                    method: 'post',
                    data: recipe_form.serialize(),
                    beforeSend: function () {
                        btn.button('loading');
                    },
                    success: function (data) {
                        btn.button('reset');

                        if (data.tid) {
                            var tid = recipe_form.find('[name=tid]');
                            if (tid.length == 0) {
                                tid = $('<input type="hidden" name="tid">');
                                recipe_form.append(tid);
                            }
                            tid.val(data.tid);
                        }

                        recipe_form.removeAttr('data-recipe-imported').removeClass('recipe-new');

                        nutritions_well.removeClass('force-calculation').find('.nav-tabs').removeClass('hidden');
                        var nv = $('.nutritions #nutr-portion .content');
                        var nv100g = $('.nutritions #nutr-100g .content');
                        nv.find('.values, p').remove();
                        nv100g.find('.values, p').remove();

                        $('.ingredients .ing-row').removeClass('nutritions-not-calculated nutritions-data-changed');
                        $('[name=persons]').parents('div').removeClass('nutritions-data-changed');
                        recipe_form.removeClass('recipe-new-nutritions');
                        setTimeout(function () {
                            $('.ingredients .ing-row').first().trigger('nutritions-updated');
                        }, 50);

                        allergens_well.removeClass('force-calculation');
                        var av = $('.allergens .content');
                        av.find('.allergen, p').remove();
                        $('.ingredients .allergens-not-calculated').removeClass('allergens-not-calculated');
                        recipe_form.removeClass('recipe-new-allergens');
                        setTimeout(function () {
                            $('.ingredients .ing-row').first().trigger('allergens-updated');
                        }, 50);

                        nv.append($(data.nv_html));
                        nv100g.append($(data.nv100g_html));

                        var av = $('.additives .content');
                        av.find('.values, p').remove();
                        if (data.ad_html) {
                            av.append($(data.ad_html));
                        }

                        var aw = $('.weight .content');
                        aw.find('.values, p').remove();
                        if (data.weight_html) {
                            aw.append($(data.weight_html));
                        }

                        allergens_well.replaceWith($(data.av_html));
                        //recipe_form.find('.allergens, .nutritions, .additives').fadeIn();
                        recipe_form.trigger('load-originals');
                    },
                    complete: function () {
                        btn.button('reset');
                    },
                    error: function () {
                        btn.button('reset');
                    }
                });
            }, (e.type == 'click') ? 0 : 300);

            return false;
        });

        $("#btn-recipe-save").click(function (e) {
            e.preventDefault();

            var autoTranslate = $('#auto_translate_recipe_setting').val();
            if (parseInt(autoTranslate) == 1)
            {
                checkStepsLang(null, true);
            } else {
                $("form").submit();
            }
            
        });

        // save recipe
        recipe_form.on('submit', function (e) {

            var form = $(this);

            fixIngredientOrder(form);

            // add values from right-menu
            form.find('.restaurants.hidden, .syncnnshare.hidden, .tags.hidden').remove();
            form.append($('#right-menu').find('.restaurants, .syncnnshare, .tags').clone().addClass('hidden'));
            form.append($('#right-menu').find('.categories, .syncnnshare, .tags').clone().addClass('hidden'));

            var empty_titles = $('.recipe-title .text-field').filter(function () {
                    return !this.value;
                }),
                empty_descs = $('.recipe-description .text-field').filter(function () {
                    return !this.value;
                });

            if ($('#auto_translate_recipe_setting').val() &&
                (
                    ($('.recipe-title:first .text-field').val() && empty_titles.length) ||
                    ($('.recipe-description:first .text-field').val() && empty_descs.length)
                )) {
                autotranslate_empty(empty_titles, empty_descs, 0, form);
            }
            else {
                form.save(function (data) {
                    window.onbeforeunload = null;

                    if (!form.find('[name=id]').length) {
                        form.prepend($('<input name="id" type="hidden"/>').val(data.id));
                    }
                });
            }

            return false;
        });

        // warn if unsaved changes in the recipe
        (function () {
            var serializeForm = function () {
                fixIngredientOrder(recipe_form);
                return $.param(recipe_form.find(
                    '[name=id],[name=tid],[name=title],[name=image_id],[name=description],[name=categories],[name=price],[name=persons],[name=comments],' +
                    '.ingredient-selected input.ingredient-name:not(.tt-hint), .ingredient-selected input.ingredient-quantity-amount, ' +
                    '.ingredient-selected .ingredient_quantity_unit, .ingredient-selected input[name^=ingredient_ordr], ' +
                    'input.recipe-id, input[name^=recipe_ordr], .recipe-cmcalc input:not([readonly])'
                ).serializeArray());
            };

            recipe_form.data('serialized', serializeForm());
            window.onbeforeunload = function () {
                if (serializeForm() != recipe_form.data('serialized')) {
                    return recipe_form.attr('data-onbeforeunload-msg');
                }
            };
        })();

        // tokenfield autocomplete
        (function () {
            var filter = function (tags) {
                var existingTags = $.map($(document.activeElement).parents('.form-control').first().find('.token-input').tokenfield('getTokens'), function (tag) {
                    return tag.value;
                });

                return (existingTags.length == 0) ? tags : $.map(tags, function (tag) {
                    if ($.inArray(tag.value, existingTags) == -1) {
                        return tag;
                    }
                });
            };

            var removePlaceholder = function (obj) {
                if (!obj.is('[data-placeholder]')) {
                    obj.attr('data-placeholder', obj.attr('placeholder'));
                }

                obj.attr('placeholder', '');
            };

            var reactivatePlaceholder = function (obj) {
                if (obj.is('[data-placeholder]')) {
                    obj.attr('placeholder', obj.attr('data-placeholder'));
                }
            };

            $('.recipe-form-page').find('.token-input[data-prefetch-url][data-remote-url]').each(function () {
                var that = $(this)
                prefetch_url = that.attr('data-prefetch-url'),
                    remote_url = that.attr('data-remote-url');

                that.on('tokenfield:initialize tokenfield:createdtoken tokenfield:removedtoken tokenfield:editedtoken', function (e) {
                    if (that.tokenfield('getTokens').length) {
                        removePlaceholder(that);
                        that.siblings().each(function () {
                            removePlaceholder($(this));
                        })
                    } else {
                        reactivatePlaceholder(that);
                        that.siblings('input').each(function () {
                            reactivatePlaceholder($(this));
                        })
                    }
                });

                var tagsBH = new Bloodhound({
                    datumTokenizer: function (d) {
                        return Bloodhound.tokenizers.whitespace(d.value)
                    },
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    prefetch: {
                        url: prefetch_url,
                        filter: filter
                    },
                    remote: {
                        url: remote_url + '%QUERY',
                        filter: filter
                    }
                });
                tagsBH.initialize();


                that.tokenfield({
                    minLength: 0,
                    showAutocompleteOnFocus: true,
                    createTokensOnBlur: true,
                    limit: 10,
                    typeahead: {
                        source: tagsBH.ttAdapter(),
                        autoselect: false
                    }
                }).on('tokenfield:createtoken', function (event) {
                    var existingTokens = $(this).tokenfield('getTokens');
                    $.each(existingTokens, function (index, token) {
                        if (token.value === event.attrs.value)
                            event.preventDefault();
                    });
                });

                // workarounds:
                // 1. show/hide placeholders correctly
                // 2. show typeahead list on focus
                var inputs = that.form('.token-input');
                inputs.each(function () {
                    var $this = $(this);
                    $this.attr('_placeholder', $this.attr('placeholder'));

                }).on('focus paste tokenfield:createdtoken', function (e) {
                    inputs.attr('placeholder', '');
                    var $this = $(this);

                    if (e.type == 'tokenfield:createdtoken') {
                        $this = inputs;
                    }

                    var e = $.Event("keydown");
                    e.which = 40;
                    e.keyCode = 40;
                    $this.trigger(e);

                    $this.typeahead('val', '_');
                    $this.typeahead('open');
                    $this.typeahead('val', '');

                }).on('blur focusout', function () {
                    inputs.each(function () {
                        var $this = $(this);
                        $this.attr('placeholder', $this.attr('_placeholder'));
                    });
                });
            });
        })();

        // remember last persons number
        $('input[name=persons]').on('focusin', function () {
            if ($(this).val()) $(this).data('persons_last', $(this).val());
        });

        // ingredient's quantities recalculation on recipe's persons number difference
        $('.ingredient-recalculate').click(function (e) {
            var fld_persons = $('[name=persons]');
            var persons_last = toNumber(fld_persons.data('persons_last'));
            var persons = toNumber(fld_persons.val().trim());
            if (!persons) persons = fld_persons.attr('placeholder');
            if (+persons && +persons_last) {
                $('.ingredients .ing-row .ingredient-quantity-amount').each(function () {
                    var val = $(this).val().trim();
                    if (val && val.match(/^[0-9]+([,.][0-9]+)?$/)) {
                        val = toNumber(val);
                        if (val > 0) {
                            val = +(val * persons / persons_last).toFixed(2);
                            $(this).val(localizedNumber(val));
                        }
                    }
                });
                cost_update($('.ingredients .ing-row'));
            }
        });
    }
});

// recipe ingredients groups
$(document).ready(function () {


    var recipe_form = $('.recipe-form');
    if (!recipe_form.length) {
        return;
    }

    var ingredients = recipe_form.find('.ingredients');

    // keyboard keys in group name
    recipe_form.on('keyup keydown keypress', 'input.group-name', function (e) {
        // enable defocus/blur on pressing enter key
        if (e.which == 13) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $(this).trigger('update');
            return false;
        } else
            // escape
        if (e.which == 27) {
            $(this).trigger('blurme');
        }
    })
        // update group name
        .on('update', 'input.group-name', function (e) {
            var $this = $(this), val = $this.val();
            var name_ro = $this.siblings('.group-name-ro').text(val || $this.attr('placeholder'));
            name_ro.toggleClass('empty-group-name', !val);

            $this.trigger('blurme');
        })
        // on blurme, hide
        .on('blurme', 'input.group-name', function (e) {
            $(this).addClass('hidden').blur();
            //$(this).hide();
        })
        // on save button click
        .on('click', '[name=save-group-name]', function (e) {
            $(this).siblings('.group-name').trigger('update');
            return false;
        })
        // on click on name or edit button
        .on('click', '.group-name-ro, .btn-edit-group', function (e) {
            $(this).closest('.group-row').find('.group-name').removeClass('hidden').select()
        })
        // remove group with all the ingredients
        .on('click', '.ingredients .group-row .btn-delete-group', function () {
            var $this = $(this);

            modal_confirm($this.attr('data-confirm-delete'), function () {
                // no more groups? add a new empty group
                if (recipe_form.find('.group-row:not(.hidden)').length == 1) {
                    recipe_form.find('button.new-group').trigger('clicked');
                }

                $this.closest('.group-row').slideUp(function () {
                    $(this).remove();
                })

                if (!ingredients.find('.group-row:not(.hidden)').length) {
                    recipe_form.find('button.new-group').click();
                }

                $(this).modal('hide');
            });
        })
        // create a new group
        .on('click clicked', 'button.new-group', function () {
            var group = ingredients.find('div.row.group-row.hidden').clone(true);
            group.find('.group-id').val(guid());
            group.hide().removeClass('hidden');
            // new name ids
            var group_guid = guid();
            group.find('input').each(function () {
                var input = $(this), name = input.attr('name');
                if (name && name.match(/^group\[.*?](.*)$/)) {
                    input.attr('name', 'group[' + group_guid + ']' + RegExp.$1)
                }
            });

            ingredients.find('> div:last-child').after(group);
            group.slideDown().trigger('add-search');
            // focus name
            group.find('.btn-edit-group').trigger('click');
        })
        // add ingredient search
        .on('add-search', '.group-row', function () {
            var group = $(this);
            var ings = group.find('.ing-row:last');

            // if search is already there, just focus it
            if (ings.length && !ings.hasClass('ingredient-selected')) {
                if (ings.hasClass('hidden')) {
                    ings.hide().removeClass('hidden');
                }
                ings.slideDown(function () {
                    $(this).find('input.tt-input:first, input[type=text]:first').focus();
                });

                // hide "add ingredient" button
                ings.closest('.group-row').find('.add-ingredient-in-group-div').slideUp();
                return;
            }

            // if not, create it by copying first ing-search you find
            var ing_search_row = recipe_form.find('.ing-row:not(.ingredient-selected):first');
            // disable typeahead on original before cloning. dont ask why...
            var ing_search = ing_search_row.find('input.typeahead')
                .typeahead('destroy')
                .off('typeahead:selected')
                .off('typeahead:autocompleted');

            var ing_search_row2 = ing_search_row.clone(true);
            // clean up search field and add typeahead
            // this is code copy from "recipe_form.on('add-ingredient', '.ingredient-name'"
            var icounter = guid();
            var inputs = ing_search_row2.find('input').val('').removeAttr('data-value');
            inputs.filter('.recipe-ingredient-id').attr('name', 'ingredient[' + icounter + '][recipe_ingredient_id]');
            inputs.filter('.ingredient-id').attr('name', 'ingredient[' + icounter + '][ingredient_id]');
            inputs.filter('.ingredient-recipe-id').attr('name', 'ingredient[' + icounter + '][ing_recipe_id]');
            inputs.filter('.ingredient-group-id').attr('name', 'ingredient[' + icounter + '][group_id]');
            inputs.filter('.ingredient-product-group-id').attr('name', 'ingredient[' + icounter + '][product_group_id]');
            inputs.filter('.ingredient-name').attr('name', 'ingredient_name[' + icounter + ']');
            inputs.filter('.ingredient-quantity-amount').attr('name', 'ingredient[' + icounter + '][quantity]');
            inputs.filter('.ingredient_quantity_unit').attr('name', 'ingredient[' + icounter + '][quantity_unit]');
            inputs.filter('.ingredient-loss').attr('name', 'ingredient[' + icounter + '][loss]');
            ing_search_row2.find('input.typeahead').add_ingredients_typeahead();
            ing_search_row2.find('.blsd-bg').text('');
            ing_search_row2.find('input').css('background-image', '');
            ing_search_row2.attr('data-id', ""); //remove data-id pasted from added ingredient

            group.find('.group-ingredients').append(ing_search_row2);
            if (ing_search_row2.hasClass('hidden')) {
                ing_search_row2.hide().removeClass('hidden');
            }
            ing_search_row2.slideDown(function () {
                $(this).find('.ingredient-name:last').focus();
            });

            // hide "add ingredient" button
            ing_search_row2.closest('.group-row').find('.add-ingredient-in-group-div').slideUp();

            // enable typeahead again on original
            ing_search.add_ingredients_typeahead();
            group.trigger('add-group-ingredients-sorting');
        })
        // show search in group => add an ingredient into its group
        .on('click', 'button.add-ingredient-in-group', function () {
            $(this).closest('.group-row').trigger('add-search');
        })
        // enable ingredient groups creation
        .on('change', '#use-recipe-groups', function () {
            if ($(this).prop('checked')) {
                var group = ingredients.find('.group-row.hidden').clone();

                // create first default group and all selected ingredients to it
                var sel_ings = ingredients.find('.ing-row.ingredient-selected');
                var g_ingredients = group.find('.group-ingredients').append(sel_ings);

                // find unselected, remove all but last and append to ingredients
                g_ingredients.append(ingredients.find('.ing-row:not(.ingredient-selected)').each(function (i) {
                    if (i > 0) {
                        $(this).remove();
                    }
                }).first());

                // new name ids
                var group_guid = guid();
                group.find('.group-id').val(group_guid);
                group.find('input').each(function () {
                    var input = $(this), name = input.attr('name');
                    if (name) {
                        if (name.match(/^group\[.*?](.*)$/)) {
                            input.attr('name', 'group[' + group_guid + ']' + RegExp.$1)
                        } else if (input.hasClass('ingredient-group-id')) {
                            input.val(group_guid);
                        }
                    }
                });

                // hide/show buttons
                ingredients.prepend(group.removeClass('hidden'));
                $(this).addClass('hidden');
                recipe_form.find('.convert-to-single.hidden').removeClass('hidden');
                recipe_form.find('.new-group.hidden').removeClass('hidden');

                group.trigger('add-search');
                ingredients.trigger('add-group-sorting');
                group.trigger('add-group-ingredients-sorting');
                // focus name
                group.find('.btn-edit-group').trigger('click');

            } else {
                // move ingredients to ungrouped list
                ingredients.append(ingredients.find('.ing-row.ingredient-selected'));
                // move one ingredient-search to ungrouped list
                ingredients.append(recipe_form.find('.ing-row:not(.ingredient-selected):first').removeClass('hidden'));
                ingredients.find('.group-row:not(.hidden)').remove();
                ingredients.find('.ingredient-group-id').val('');

                // hide buttons
                recipe_form.find('.new-group').addClass('hidden');
                $(this).addClass('hidden');

                // show "create groups" buttons
                recipe_form.find('.create-groups.hidden').removeClass('hidden');

                // add sorting
                ingredients.trigger('add-ingredient-sorting');
            }
        })
        // disable ingredient groups creation and delete all groups
        .on('click', '.convert-to-single', function () {

            // move ingredients to ungrouped list
            ingredients.append(ingredients.find('.ing-row.ingredient-selected'));
            // move one ingredient-search to ungrouped list
            ingredients.append(recipe_form.find('.ing-row:not(.ingredient-selected):first').removeClass('hidden'));
            ingredients.find('.group-row:not(.hidden)').remove();
            ingredients.find('.ingredient-group-id').val('');

            // hide buttons
            recipe_form.find('.new-group').addClass('hidden');
            $(this).addClass('hidden');

            // show "create groups" buttons
            recipe_form.find('.create-groups.hidden').removeClass('hidden');

            // add sorting
            ingredients.trigger('add-ingredient-sorting');
        });

    // translations
    $('.text-field').on('input', function () {
        $(this).prev().removeClass('auto-translated');
    });

    $('.tab-pane:first .text-field').on('change', function () {
        if ($('.recipe-title:first .text-field').val()) {
            if ($('#auto_translate_recipe_setting').val()) {
                var titles = $('.recipe-title .text-field').filter(function () {
                        return !this.value;
                    }),
                    descs = $('.recipe-description .text-field').filter(function () {
                        return !this.value;
                    });

                if (!titles.length) titles = $('.recipe-title .auto-translated').next();
                if (!descs.length) descs = $('.recipe-description .auto-translated').next();

                if (titles.length || descs.length)
                    autotranslate_empty(titles, descs);
            }
        }
    });

    //translate-step

    //cb(from, to, data['t'], index);
    function updateStepAfterTranslation(from, to, text, index, field)
    {
        $(field + to + "-" + index).val(text);
    }


    $('body').on('click', '.translate-step', function () {

        var a = $(this);

        var userCurrentDefaultLang = getUserDefaultLang();

        var userCurrentLang = getCurrentLanguage();

        var index = 1;
        var endStepLoop = false;
        var updatingIndex = 1;
        while(!endStepLoop)
        {
            updatingIndex = index;
            var title_for_translate = $("#stepTitle-" + userCurrentDefaultLang + "-" + index);
            var desc_for_translate = $("#stepDescription-" + userCurrentDefaultLang + "-" + index);

            if(title_for_translate.length == 0 || desc_for_translate.length == 0)
            {
                endStepLoop = true;
            } 
            else {
                
                title_for_translate = title_for_translate.val();
                desc_for_translate = desc_for_translate.val();

                var translate_data = [
                    {
                        'field_id': "#stepTitle-",
                        'text': title_for_translate
                    },
                    {
                        'field_id': "#stepDescription-",
                        'text': desc_for_translate
                    }
                ];

                translate_data.forEach((element) => {
                    
                    text_translate_with_custom_callback(userCurrentDefaultLang, userCurrentLang, element.text, index, element.field_id, updateStepAfterTranslation);

                    // text_translate(userCurrentDefaultLang, userCurrentLang, element.text, function (data) {

                    //     if (data['t'] != null) {
                            
                    //         $(element.field_id + userCurrentLang + "-" + index).val(data['t']);
                    //     }
                        
                    // });
                });

            }

            index++;
        }

        // var raw_id = (a.attr("id")).split("-");

        // var lang_to_translate = raw_id[2];

        // var step_for_translate = raw_id[3];

        // var title_for_translate = $("#stepTitle-" + userCurrentDefaultLang+"-" + step_for_translate).val();
        // var desc_for_translate = $("#stepDescription-" + userCurrentDefaultLang +"-" + step_for_translate).val();

        // var translate_data = [
        //     {
        //         'field_id': "#stepTitle-",
        //         'text': title_for_translate
        //     },
        //     {
        //         'field_id': "#stepDescription-",
        //         'text': desc_for_translate
        //     }
        // ];

        // translate_data.forEach((element, index) => {

        //     text_translate(userCurrentDefaultLang, lang_to_translate, element.text, function (data) {

        //         if (data['t'] != null) {
        //             $(element.field_id + lang_to_translate + "-" + step_for_translate).val(data['t']);
        //         }
        //     });
        // });


        // $translate_finisg = false;



        // text_translate("de", lang_to_translate, title_for_translate, function (data) {

        //     if (data['t'] != null) {
        //         $("#stepTitle-" + lang_to_translate + "-" + stap_for_translate).val(data['t']);
        //     }
        // });

        // text_translate("de", lang_to_translate, desc_for_translate, function (data) {

        //     if (data['t'] != null) {
        //         $("#stepDescription-" + lang_to_translate + "-" + stap_for_translate).val(data['t']);
        //     }
        // });


    })


    $('.lang-tabs li:not(:first)').on('click', function () {



        var lang = $(this).attr('data-lang');
        if ($('#lang-' + lang).find('.auto-translated').length)
            $('.translations-info').removeClass('hidden').addClass('fade in');




        currentSelectedLang = lang;


        //ovjde
        checkShowingTranslateButton(lang);

        var userCurrentDefaultLang = getUserDefaultLang();

        if (currentSelectedLang != userCurrentDefaultLang)
        {
            var index_temp = 1;
            $('.step-section-content').each(function (i, obj) {

                var attr = $(this).attr('step-lang');

                if (attr != "") {

                    if (attr == lang) {
                        $(this).removeClass('non-active-step');

                        if ($('#auto_translate_recipe_setting').val()) {
                            var title_for_translate = $("#stepTitle-de-" + index_temp).val();
                            var desc_for_translate = $("#stepDescription-de-" + index_temp).val();

                            var stepTitle = $(this).find("input.tt-input");

                            if (stepTitle == null) {
                                stepTitle = $(this).find("input.step-title");
                            }

                            if (stepTitle.length > 0 && stepTitle.val() == "") {
                                text_translate(userCurrentDefaultLang, currentSelectedLang, title_for_translate, function (data) {

                                    if (data['t'] != null) {
                                        stepTitle.val(data['t']);
                                    }
                                });
                            }

                            var stepDesc = $(this).find("textarea.step-description");

                            if (stepDesc.length > 0 && stepDesc.val() == "") {

                                text_translate(userCurrentDefaultLang, currentSelectedLang, desc_for_translate, function (data) {

                                    if (data['t'] != null) {
                                        stepDesc.val(data['t']);
                                    }
                                });

                            }
                        }

                    } else {

                        if (!$(this).hasClass('non-active-step')) {
                            $(this).addClass('non-active-step');
                        }
                    }
                }

            });
        }

    });

    $('.lang-tabs li').on('click', function () {

        var lang = $(this).attr('data-lang'),
            selectedIngsRows = $('.ingredients .ing-row.ingredient-selected'),
            ingsIdInput = selectedIngsRows.find('.ingredient-id'),
            ingIds = ingsIdInput.map(function () {
                return $(this).val();
            }).get(),
            // defaultLang = $('.lang-tabs li:first').attr('data-lang');
            defaultLang = $('html').attr('lang');

        currentSelectedLang = lang;

        checkStepsLang(previousSelectedLang, false);

        checkShowingTranslateButton(lang);

        previousSelectedLang = lang;

        $('.step-section-content').each(function (i, obj) {

            var attr = $(this).attr('step-lang');
            if (attr != '') {
                if (attr == lang) {
                    $(this).removeClass('non-active-step');
                } else {
                    if (!$(this).hasClass('non-active-step')) {
                        $(this).addClass('non-active-step');
                    }
                }
            }

        });

        $.ajax({
            url: $(this).attr('data-ingredients-multilang-url'),
            method: 'post',
            data: { ingredients: ingIds },
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                if (data[lang]) {
                    selectedIngsRows.each(function () {
                        var row = $(this),
                            name = data[lang][row.find('.ingredient-id').val()],
                            fallback = data[defaultLang][row.find('.ingredient-id').val()],
                            ingName = name['name'] || fallback['name'];

                        row.find('.ingredient-name').val(ingName);
                    });
                }
            },
            complete: function () {
                $('html').removeClass('async-loading');
            }
        });

        $('.recipe-text .tab-pane').removeClass('in active');
        $('#lang-' + lang).addClass('in active');

        $('.lang-tabs li').removeClass('active');
        $('.lang-tabs li[data-lang="' + lang + '"]').addClass('active');

        $('.recipe-comments .tab-pane').removeClass('in active');
        $('#comments-lang-' + lang).addClass('in active');
    });

    $('.btn-translate').on('click', function () {
        var btn = $(this), parent = btn.parent(),
            fld = parent.find('input, textarea'),
            source_lang = $('.lang-tabs').attr('data-source-lang'),
            target_lang = fld.attr('data-lang'),
            text = ''
        ;

        if (parent.hasClass('recipe-description')) {
            text = $('.recipe-description-title[data-lang=' + source_lang + ']').val();
        } else if (parent.hasClass('recipe-title') && btn.parent().hasClass('recipe-title')) {
            text = $('#recipe-title-input-' + source_lang).val()
        } else {
            text = fld.attr('value') ? fld.attr('value') : fld.val();
        }

        if (text) {
            $('html').addClass('async-loading');
            text_translate(source_lang, target_lang, text, function (data) {
                if (data['t'] === text)
                    text_translate(null, target_lang, text, function (data) {
                        update_with_translation(data, fld, btn);
                    });
                else update_with_translation(data, fld, btn);
            });
        }
    });

    $('.btn-translate-comments').on('click', function () {
        var btn = $(this),
            target_lang = btn.attr('data-lang'),
            fld = $('#tiny-mce-recipe-comment-' + target_lang + '_ifr').contents()
                .find("#tinymce"),
            source_lang = $('.lang-tabs').attr('data-source-lang'),
            text = $('#tiny-mce-recipe-comment-' + source_lang + '_ifr').contents()
                .find("#tinymce").html().replace(/<(?:.|\n)*?>/gm, ' '),
            dataExists = $('#tiny-mce-recipe-comment-' + source_lang + '_ifr').contents()
                .find("#tinymce").attr('data-exists') ? true : $('#tiny-mce-recipe-comment-' + source_lang).attr('data-exists'),
            tinymce_textarea = tinymce.get('tiny-mce-recipe-comment-' + target_lang);

        if (text && dataExists) {
            $('html').addClass('async-loading');
            text_translate(source_lang, target_lang, text, function (data) {
                if (data['t'] === text)
                    text_translate(null, target_lang, text, function (data) {
                        update_comment_with_translation(data, tinymce_textarea, btn);
                    });
                else update_comment_with_translation(data, tinymce_textarea, btn);
            });

            fld.attr('data-exists', true);
        }
    });

    // disclaimer kill
    $('#disclaimer-info .close').click(function () {
        $(this).async(function () {
            $('#disclaimer-info').slideUp(function () {
                $(this).remove();
            });
        });
    });

    $('body').on('click', '.advanced-ingredient-search', function () {
        var a = $(this);
        $('.ing-row.active-ingredients-detailed-search').removeClass('active-ingredients-detailed-search');
        a.closest('.ing-row').addClass('active-ingredients-detailed-search');
        a.async();
        return false;
    })

    // detailed ingredients search
    var ingredients_detailed_search_timeout;
    $('body').on('submit', '.ingredients-detailed-search', function () {
        var form = $(this);
        form.parents('.modal').first().find('.btn-submit').button('loading');

        clearTimeout(ingredients_detailed_search_timeout);
        form.save(function (data) {
            if (data.html) {
                var results = form.find('.results .ingredients');
                if (results.is(':visible')) {
                    results.html(data.html);
                }
            }
        });

        return false;
    });
    // detailed search autosubmit
    var ingredients_detailed_search = function () {
        var form = $('.ingredients-detailed-search');
        var sform = form.serialize();
        if (form.data('sform') != sform) {
            clearTimeout(ingredients_detailed_search_timeout);
            ingredients_detailed_search_timeout = setTimeout(function () {
                clearTimeout(ingredients_detailed_search_timeout);
                form.data('sform', sform);
                form.submit()
            }, 500);
        }
    };
    // just to be sure. reset autosubmit if modal closed
    $('body').on('hide.bs.modal', '.modal.detailed-search-modal', function () {
        clearTimeout(ingredients_detailed_search_timeout);
        ingredients_detailed_search = function () {
        };
    });
    $('body').on('click change', '.ingredients-detailed-search :checkbox', function () {
        var $this = $(this);
        $this.parents('.checkbox').first().toggleClass('active', $this.is(':checked'));
        ingredients_detailed_search()
    });
    $('body').on('change keydown keyup keypress', '.ingredients-detailed-search input[name=q]', function () {
        ingredients_detailed_search();
    });
    // click on result add ingredient
    $('body').on('click', '.detailed-search-modal .results li[data-id], .detailed-search-modal .results li[data-recipe-id]', function () {
        var $this = $(this), id = $this.attr('data-id'),
            recipe_id = $this.attr('data-recipe-id'),
            modal = $('.detailed-search-modal');
        var form = $this.form();

        $.ajax({
            url: form.attr('data-url-selected-ing'),
            method: 'post',
            data: {
                id: id,
                recipe_id: recipe_id
            },
            success: function (data) {
                modal.modal('hide');
                data.no_autocomplete = true;
                $('.ing-row.active-ingredients-detailed-search .ingredient-name.tt-input').trigger('add-ingredient', data);
            }
        });
        return false;
    });

    $('.lang-tabs li').each(function () {
        var recipeLang = $(this).attr('data-lang');

        if (!!recipeLang) {
            var lang = $('html').attr('lang');
            var placeholder = $('#tiny-mce-recipe-comment-' + recipeLang).attr('placeholder');
            var inner = $('#tiny-mce-recipe-comment-' + recipeLang).text();
            if (lang === 'fr') {
                lang = 'fr_FR'
            }
            tinymce.baseURL = '/js/tinymce';
            tinymce.suffix = '.min';
            tinymce.init({
                selector: '#tiny-mce-recipe-comment-' + recipeLang,
                height: 200,
                theme: 'modern',
                language: lang,
                menubar: true,
                branding: false,
                images_upload_url: '/recipes/images/upload',
                ximages_upload_handler: function (blobInfo, success, failure) {
                    setTimeout(function (data) {

                        /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                        success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                    }, 2000);
                },
                plugins: [
                    'advlist autolink lists link image hr autoresize fullscreen imagetools print'
                ],
                //toolbar1: 'bold italic underline | link  | numlist bullist outdent indent  | removeformat',
                setup: function (editor) {
                    editor.on('init', function () {
                        var content = "<p>" + (inner || placeholder) + "</p>";
                        editor.setContent(content);

                        if (inner) {
                            $(this).contents().find("#tinymce").attr('data-existed', true);
                        } else {
                            $(this).contents().find("#tinymce").attr('data-existed', null);
                        }
                    });

                    editor.on('focus', function () {
                        editor.theme.resizeTo(editor.theme.height, 500);
                        if (editor.getContent({ format: 'text' }) == placeholder) {
                            editor.setContent('');
                        }

                    });

                    editor.on('blur', function () {
                        editor.theme.resizeTo(editor.theme.height, 200);

                        if (!editor.getContent({ format: 'text' }).trim().length) {
                            //    editor.setContent("<p>" + placeholder + "</p>");
                        }
                    });

                    editor.on('change', function () {
                        editor.save();

                        if (editor.getContent()) {
                            $(this).contents().find("#tinymce").attr('data-exists', true);
                        } else {
                            $(this).contents().find("#tinymce").attr('data-exists', null);
                        }
                    });

                    editor.on('paste', function () {
                        $(this).contents().find("#tinymce").attr('data-exists', true);
                        $(this).contents().find("#tinymce").attr('data-existed', true);
                    });

                    editor.on('keyup', function () {
                        if (editor.getContent()) {
                            $(this).contents().find("#tinymce").attr('data-existed', true);
                        } else {
                            $(this).contents().find("#tinymce").attr('data-existed', null);
                        }
                    });
                }
            });
        }
    });
});

$(document).ready(function () {
    // open modal
    $('body').on('click', '.ing-row .ing-loss-link', function () {
        var $this = $(this),
            row = $this.closest('.ing-row'),
            loss = row.find('input.ingredient-loss'),
            ing_id = row.find('input.ingredient-id'),
            ing_recipe_id = row.find('input.ingredient-recipe-id'),
            product_group_id = row.find('input.ingredient-product-group-id'),
            data = {};

        row.siblings().removeClass('editing-production-loss');

        data.loss = loss.val();

        if (ing_id.length && ing_id.val().length) {
            data.ingredient_id = ing_id.val();
        }
        if (ing_recipe_id.length && ing_recipe_id.val().length) {
            data.ingredient_recipe_id = ing_recipe_id.val();
        }
        if (product_group_id.length && product_group_id.val().length) {
            data.ingredient_product_group_id = product_group_id.val();
        }

        row.addClass('editing-production-loss');

        $.ajax({
            url: $this.attr('href'),
            data: data
        });

        return false;
    });

    // edit tmplt
    $('body').on('click', '#loss-templates-dropdown a .edit', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var a = $(this).closest('a');
        var tmplt = $('#loss-template-edit').addClass('edit').removeClass('add');
        var name = tmplt.find('[name=loss_name]').val(a.attr('data-name'));
        tmplt.find('[name=loss_value]').val(a.attr('data-loss'));
        tmplt.find('[name=template_id]').val(a.attr('data-value'));
        tmplt.find('[name=affect_existing_templates]').prop('checked', false);
        tmplt.show();
        name.focus().select();

        $('#loss-templates-dropdown').removeClass('open');
        return false;
    });

    // delete tmplt
    $('body').on('click', '#loss-templates-dropdown a .delete', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var $this = $(this);
        $this.async(function () {
            $this.closest('a').remove();
        });

        $('#loss-templates-dropdown').removeClass('open');
        $('#loss-template-edit').hide();
        return false;
    });

    // save tmplt
    $('body').on('click fake-click', '#template-edit-save', function () {
        var form = $('#loss-template-edit'),
            btn = $('#template-edit-save');

        $.ajax({
            url: form.attr('data-url'),
            type: 'post',
            data: {
                template_id: form.find('[name=template_id]').val(),
                loss_name: form.find('[name=loss_name]').val(),
                loss_value: form.find('[name=loss_value]').val(),
                affect_existing_templates: form.find('[name=affect_existing_templates]').prop('checked') ? 1 : 0
            },
            beforeSend: function () {
                btn.button('loading')
            },
            success: function (data) {
                $('#loss-template-edit').hide();
                var li = $(Handlebars.compile($('#template-loss-dropdown-tmpl').html())(data));

                // add to dropdown
                if (form.hasClass('add')) {
                    $('#loss-templates-dropdown .dropdown-menu').prepend(li);

                    // modify dropdown
                } else {
                    var old_li = $('#loss-templates-dropdown .dropdown-menu').find('li:has(a[data-value="' + data.template.id + '"])');
                    old_li.replaceWith(li);
                }

                $('#loss-templates-dropdown .dropdown-toggle').trigger('reset');
            },
            complete: function () {
                btn.button('reset')
            }
        });

        return false;
    });

    // submit modal form on enter
    $('body').on('keydown', '#ingredient-loss', function (e) {
        if (e.which == 13) { // enter
            $(this).closest('form').submit();
            return false;
        }
    });

    // submit modal tmplt add/edit form on enter
    $('body').on('keydown', '#loss-template-edit [name=loss_name], #loss-template-edit [name=loss_value]', function (e) {
        if (e.which == 13) { // enter
            $('#template-edit-save').trigger('fake-click');
            return false;
        }
    });

    // close tmplt edit form
    $('body').on('click', '#loss-template-edit .close', function () {
        $('#loss-template-edit').hide();
    });

    // select tmplt
    $('body').on('change', '#loss-templates-dropdown .selected-loss-template', function (e, a) {
        var $this = $(this);

        if ($this.val() == 'add') {
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();

            var a = $(this).closest('a');
            var tmplt = $('#loss-template-edit').addClass('add').removeClass('edit');
            var name = tmplt.find('[name=loss_name]').val('');
            tmplt.find('[name=loss_value]').val('');
            tmplt.find('[name=template_id]').val('');
            tmplt.show();
            name.focus();

        } else {
            if ($('#main-settings').length) {
                a.find('.edit').click();
            } else {
                $('#ingredient-loss').val(a.attr('data-loss'));
                $('#loss-templates-dropdown .dropdown-toggle').trigger('reset');
            }
        }
    });

    //
    $('body').on('submit', '.production-loss-modal #recipes_ingredients_production_loss_save', function (e) {

        var form = $(this),
            modal = $(this).closest('.modal');

        form.save(function (data) {
            var row = $('.row.editing-production-loss');
            row.find('.ingredient-loss').val(data.loss).trigger('updated');
            row.find('.ing-loss-link').text(data.loss + '%');

            ($(".ing-row")).removeClass('editing-production-loss');

            modal.modal('hide');
        });

        return false;
    });

    $('#show-default-product').on('change', function() {
    
        if ($(this).prop('checked')) {
            $(".row.ing").off('click');
            $('.subproduct-wrapper').hide();
            $(".row.ing").each(function() {
                $(this).find('.recipe-view-product-group-name').hide();
                $(this).find('.recipe-view-default-product-group-name').show();
                $(this).find('.artikel-id-pg').show();
                $(this).find('.producer-pg').show();
                $(this).find('.nnt-pg').hide();
                $(this).find('.not-shop-product-pg').hide();
                $(this).find('.product-group-count').hide();
            });
        } else {
            $(".row.ing").on("click", function(event) {
                if ($(event.target).closest('.recipe-view-dropdown-toggle').length) {
                    return;
                }
                var subproductWrapperDiv = $(this).find(".subproduct-wrapper");
                subproductWrapperDiv.slideToggle();
            });
            $(".row.ing").each(function() {
                $(this).find('.recipe-view-product-group-name').show();
                $(this).find('.recipe-view-default-product-group-name').hide();
                $(this).find('.artikel-id-pg').hide();
                $(this).find('.producer-pg').hide();
                $(this).find('.nnt-pg').show();
                $(this).find('.not-shop-product-pg').show();
                $(this).find('.product-group-count').show();
            });
        }
    });
    
    // recipe loss switcher
    $('#use-recipe-loss').on('change', function () {
        $('.ingredients-wrapper').toggleClass('visible-recipe-loss', $(this).prop('checked'));
        $('.ingredients-wrapper .ing-row .ingredient-loss:first').trigger('updated');
    });

    // global recipe units hide/show action inside units dropdown
    $('body').on('selected', '.ingredient-quantity-unit-values a[data-no-update="true"][data-value="global-recipe-units-display"]', function (e) {
        $(this).closest('.dropdown.dropdown-menu-select').addClass('open');
        var recipe_units = document.getElementsByClassName('recipe-units');
        for (var i = 0; i < recipe_units.length; i++) {
            recipe_units[i].classList.toggle('global-recipe-unit-dropdown-visibility');
        }
    });

    ////////////////////////////////////////////////
    // open modal: add new ingredient quantity unit 
    $('body').on('selected', '.ingredient-quantity-unit-values a[data-no-update="true"][data-value="add_new"][data-href]', function () {
        var a = $(this),
            url = a.attr('data-href'),
            row = a.closest('.ing-row'),
            ing_id = row.find('input.ingredient-id'),
            ing_recipe_id = row.find('input.ingredient-recipe-id'),
            product_group_id = row.find('input.ingredient-product-group-id'),
            data = {};

        if (ing_id.length && ing_id.val().length) {
            data.ingredient_id = ing_id.val();
        }
        if (ing_recipe_id.length && ing_recipe_id.val().length) {
            data.ingredient_recipe_id = ing_recipe_id.val();
        }
        if (product_group_id.length && product_group_id.val().length) {
            data.ingredient_product_group_id = product_group_id.val();
        }

        $.ajax({
            url: url,
            data: data
        });

        return false;
    });

    // edit unit 
    $('body').on('click', '#custom-units-dropdown a .edit', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var a = $(this).closest('a');
        var tmplt = $('#custom-unit-edit').addClass('edit').removeClass('add');
        var name = tmplt.find('[name=name]').val(a.attr('data-name'));
        tmplt.find('[name=weight]').val(a.attr('data-weight'));
        tmplt.find('.ingredient-unit-selector .custom-unit-unit-value a[data-value="' + a.attr('data-unit') + '"]').trigger('clicked');
        tmplt.find('[name=unit_id]').val(a.attr('data-value'));
        tmplt.find('[name=affect_existing_templates]').prop('checked', false);
        tmplt.show();
        name.focus().select();

        $('#custom-units-dropdown').removeClass('open');
        return false;
    });

    // delete unit 
    $('body').on('click', '#custom-units-dropdown a .delete', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var $this = $(this);
        $this.async(function () {
            $this.closest('a').remove();
        });

        $('#custom-units-dropdown').removeClass('open');
        $('#units-template-edit').hide();
        return false;
    });

    // save unit 
    $('body').on('click fake-click', '#custom-unit-edit-save', function () {
        var form = $('#custom-unit-edit'),
            btn = $('#custom-unit-edit-save');
        $.ajax({
            url: form.attr('data-url'),
            type: 'post',
            data: {
                unit_id: form.find('[name=unit_id]').val(),
                unit_name: form.find('[name=unit_name]').val(),
                name: form.find('[name=name]').val(),
                unit: form.find('[name=unit]').val(),
                weight: form.find('[name=weight]').val(),
            },
            beforeSend: function () {
                btn.button('loading')
            },
            success: function (data) {
                $('#units-template-edit').hide();
                var li = $(Handlebars.compile($('#custom-unit-dropdown-tmpl').html())(data));

                // add to dropdown
                if (form.hasClass('add')) {
                    $('#custom-units-dropdown .dropdown-menu').prepend(li);

                    // modify dropdown
                } else {
                    var old_li = $('#custom-units-dropdown .dropdown-menu').find('li:has(a[data-value="' + data.unit.id + '"])');
                    old_li.replaceWith(li);
                }

                var ingredient_unit_changed = form.find('#ingredient-unit-changed').addClass('visible');
                setTimeout(function () {
                    ingredient_unit_changed.removeClass('visible');
                }, 2000);

                /*
                $('#custom-units-dropdown .dropdown-toggle').trigger('reset');

                var tmplt = $('#custom-unit-edit').addClass('add').removeClass('edit');
                var name = tmplt.find('[name=name]').val('');
                tmplt.find('[name=weight]').val('');
                tmplt.find('[name=unit_id]').val('');
                tmplt.show();
                name.focus();
                */
            },
            complete: function () {
                btn.button('reset')
            }
        });

        return false;
    });

    // submit modal tmplt add/edit form on enter
    $('body').on('keydown', '#custom-unit-edit [name=name], #custom-unit-edit [name=weight]', function (e) {
        if (e.which == 13) { // enter
            $('#custom-unit-edit-save').trigger('fake-click');
            return false;
        }
    });

    // close tmplt edit form
    $('body').on('click', '#units-template-edit .close', function () {
        $('#units-template-edit').hide();
    });

    // select unit 
    $('body').on('change', '#custom-units-dropdown .selected-custom-unit', function (e, a) {
        var $this = $(this);

        if ($this.val() == 'add') {
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();

            var a = $(this).closest('a');
            var tmplt = $('#custom-unit-edit').addClass('add').removeClass('edit');
            var name = tmplt.find('[name=name]').val('');
            tmplt.find('[name=weight]').val('');
            tmplt.find('[name=unit_id]').val('');
            tmplt.show();
            name.focus();

        } else {
            a.find('.edit').click();
        }
    });

    // modal save
    $('body').on('submit', '.ingredient-custom-units-modal #recipes_ingredients_units_save', function (e) {
        var form = $(this),
            modal = $(this).closest('.modal');

        modal.addClass('closing');

        form.save(function (data) {
            var rows = $('.ing-row');
            if ('ingredient_recipe_id' in data) {
                rows = rows.filter(':has(input.ingredient-recipe-id[value="' + data.ingredient_recipe_id + '"])')
            } else
            if ('ingredient_id' in data) {
                rows = rows.filter(':has(input.ingredient-id[value="' + data.ingredient_id + '"])')
            }

            rows.each(function () {
                var row = $(this),
                    current_unit = row.find('.ingredient_quantity_unit');
                unit_values = row.find('.ingredient-quantity-unit-values');
                unit_values.find('li:has([data-value^=cu_])').remove();

                var divider = unit_values.find('li.divider').first();
                for (cu in data.custom_units) {
                    var li = $(Handlebars.compile($('#ingredient-unit-tmpl').html())(data.custom_units[cu]));
                    divider.before(li);
                }

                // fix visible name
                unit_values.find('a[data-value="' + current_unit.val() + '"]').click();
            })

            if (!rows.length) {
                $('.ingredients-wrapper .ing-row .ingredient-loss:first').trigger('updated');
            }

            modal.modal('hide');
        });

        return false;
    });

    // save on modal close
    $('body').on('hide.bs.modal', '.ingredient-custom-units-modal:not(.closing)', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        var modal = $(this);
        if (!modal.hasClass('.closing')) {
            modal.find('#recipes_ingredients_units_save').trigger('submit');
        }
        return false;
    });

    $('.input-group input').keydown(function (e) {
        if (e.which == 9) { // tab
            e.preventDefault();
            $(this).parent().find('.dropdown-toggle').click();
            $(this).parent().find('.dropdown-menu-select a:first').focus();
        }
    });

    setTimeout(function () {
        if ($('#auto_translate_recipe_setting').val()) {
            $('.btn-translate-comments').each(function () {
                if ($(this).parent().find('textarea') && !$(this).parent().find('textarea').attr('data-exists')) {
                    this.click();
                }
            });
        }
    }, 2000);

    $('.recipe-comments').mouseleave(function (e) {
        if ($('#auto_translate_recipe_setting').val()) {
            $('.btn-translate-comments').each(function () {
                var btn = $(this),
                    target_lang = btn.attr('data-lang'),
                    fld = $('#tiny-mce-recipe-comment-' + target_lang + '_ifr').contents()
                        .find("#tinymce"),
                    source_lang = $('.lang-tabs').attr('data-source-lang'),
                    text = $('#tiny-mce-recipe-comment-' + source_lang + '_ifr').contents()
                        .find("#tinymce").html().replace(/<(?:.|\n)*?>/gm, ' '),
                    dataExists = $('#tiny-mce-recipe-comment-' + source_lang + '_ifr').contents()
                        .find("#tinymce").attr('data-exists') ? true : $('#tiny-mce-recipe-comment-' + source_lang).attr('data-exists'),
                    tinymce_textarea = tinymce.get('tiny-mce-recipe-comment-' + target_lang),
                    targetDataExists = $('#tiny-mce-recipe-comment-' + target_lang + '_ifr').contents()
                        .find("#tinymce").attr('data-existed') ? true : false;

                if (text && dataExists && !targetDataExists) {
                    $('html').addClass('async-loading');
                    text_translate(source_lang, target_lang, text, function (data) {
                        if (data['t'] === text)
                            text_translate(null, target_lang, text, function (data) {
                                update_comment_with_translation(data, tinymce_textarea, btn);
                            });
                        else update_comment_with_translation(data, tinymce_textarea, btn);
                    });

                    fld.attr('data-exists', true);
                }
            });
        }
    });

    function switchVatPercentage() {
        var elements1 = $('.inhouse_recipe_vat_hidden');
        var elements2 = $('.takeaway_recipe_vat_hidden');
        var elements3 = $('.sales_recipe_vat_hidden');
        var elements4 = $('.inhouse_recipe_vat');
        var elements5 = $('.takeaway_recipe_vat');
        var elements6 = $('.sales_recipe_vat');
        for (var i = 0; i < elements1.length; i++) {
            var tmp = elements1[i].textContent;
            elements1[i].textContent = elements4[i].value;
            elements4[i].value = tmp;
        }
        for (var i = 0; i < elements2.length; i++) {
            var tmp = elements2[i].textContent;
            elements2[i].textContent = elements5[i].value;
            elements5[i].value = tmp;
        }
        for (var i = 0; i < elements3.length; i++) {
            var tmp = elements3[i].textContent;
            elements3[i].textContent = elements6[i].value;
            elements6[i].value = tmp;
        }
    }

    $('body').on('click', '.first-one', function () {
        $('.meal-button').addClass('clicked');
        $('.drink-button').removeClass('clicked');
        $('.bundle-button').removeClass('clicked');
        $('.recipe-type-selection')[0].value = 'meal';
        switchVatPercentage();
        $('.recipe-type-rectangle').find('.recipe-type')[0].innerText = $(this).find('span')[0].innerText;
    });

    $('body').on('click', '.second-one', function () {
        $('.drink-button').addClass('clicked');
        $('.meal-button').removeClass('clicked');
        $('.bundle-button').removeClass('clicked');
        $('.recipe-type-selection')[0].value = 'drink';
        switchVatPercentage();
        $('.recipe-type-rectangle').find('.recipe-type')[0].innerText = $(this).find('span')[0].innerText;
    });

    $('body').on('click', '.third-one', function () {
        $('.bundle-button').addClass('clicked');
        $('.drink-button').removeClass('clicked');
        $('.meal-button').removeClass('clicked');
        $('.recipe-type-selection')[0].value = 'bundle';
        switchVatPercentage();
        $('.recipe-type-rectangle').find('.recipe-type')[0].innerText = $(this).find('span')[0].innerText;
    });

    $('body').on('click', '#include_takeaway_price', function () {
        var elements = $('.left-box-positioning');
        if ($('#include_takeaway_price')[0].checked) {
            $('.takeaway-included-subtitles').removeClass('row-visibility');
            $('.takeaway-non-included-subtitles').addClass('row-visibility');
            $('.center-block-positioning').removeClass('row-visibility');
            $('.with-takeaway').removeClass('row-visibility');
            $('.without-takeaway').addClass('row-visibility');
            $('.arrow').removeClass('up');
            $('.arrow').addClass('down');
            $('.cmcalc-block').empty();
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.replace('col-xs-8', 'col-xs-4');
            }
        } else {
            $('.takeaway-included-subtitles').addClass('row-visibility');
            $('.takeaway-non-included-subtitles').removeClass('row-visibility');
            $('.center-block-positioning').addClass('row-visibility');
            $('.with-takeaway').addClass('row-visibility');
            $('.without-takeaway').removeClass('row-visibility');
            $('.arrow').removeClass('up');
            $('.arrow').addClass('down');
            $('.cmcalc-block').empty();
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.replace('col-xs-4', 'col-xs-8');
            }
        }
    });

    $('body').on('click', '.arrow-fix', function () {
        var mode = $('.recipe-mode')[0].textContent;
        var $this = $(this).find('.arrow');
        var selected_id = $this.attr('id');
        $('.restaurant-price-data').removeClass('open');
        var recipe_id = $('#recipe-id').val();
        var readonly = $('.profit-margin-slider-readonly').html();

        if ($this.hasClass('down')) {
            $('.arrow').removeClass('up');
            $('.arrow').addClass('down');
            $('.cmcalc-block').empty();
            $this.toggleClass('up down');
            if (mode == "view-per-location" || mode == "view-all-locations") {
                recipe_id = $('#recipe-id')[0].innerText;
            }

            var restaurant_id = $(this).parent().parent().find('.restaurant-id-info').val();

            if (restaurant_id && (mode == "not-view-per-location" || mode == "view-per-location")) {
                $.ajax({
                    url: ('/recipes/edit/cmcalc-tmp-info'),
                    type: 'post',
                    beforeSend: function () {
                        htmlLoading();
                    },
                    data: {
                        readonly: readonly,
                        recipe_id: recipe_id,
                        restaurant_id: restaurant_id
                    },
                    success: function (data) {
                        $('#' + selected_id + '.cmcalc-block').html(data['html']);
                        $this.closest('.restaurant-price-data').addClass('open');
                        if (mode == "view-per-location") {
                            $('.db4').addClass('read-only margin-fix');
                        }
                        init_cmcalc4();
                    },
                    complete: function () {
                        htmlLoading(false);
                    }
                });
            } else {
                $.ajax({
                    url: ('/recipes/edit/cmcalc-tmp-info'),
                    type: 'post',
                    beforeSend: function () {
                        htmlLoading();
                    },
                    data: {
                        readonly: readonly,
                        recipe_id: recipe_id
                    },
                    success: function (data) {
                        $('#-1.cmcalc-block').html(data['html']);
                        $this.closest('.restaurant-price-data').addClass('open');
                        if (mode == "view-all-locations") {
                            $('.db4').addClass('read-only margin-fix');
                        }
                        init_cmcalc4();
                    },
                    complete: function () {
                        htmlLoading(false);
                    }
                });
            }
        } else {
            if (mode == "view-per-location" || mode == "not-view-per-location") {
                $('#' + selected_id + '.cmcalc-block').empty();
            } else {
                $('#-1.cmcalc-block').empty();
            }
            $this.toggleClass('down up');
        }
    });

    function getCurrentlyCheckedRestaurants(all_rests) {
        var checked_rests = [];
        for (var i = 0; i < all_rests.length; i++) {
            if (all_rests[i].checked) {
                checked_rests.push(all_rests[i]);
            }
        }
        return checked_rests;
    }

    function showPerLocationsPart(rows, dividers, checked_rests) {
        for (var i = 0; i < rows.length; i++) {
            if (i == 0) {
                rows[i].classList.add('row-visibility');
                dividers[i].classList.add('hide');
            } else {
                for (var j = 0; j < checked_rests.length; j++) {
                    var checked_rest_id = checked_rests[j].value;
                    $('hr[value=' + checked_rest_id + ']')[0].classList.remove('hide');
                    $('.restaurant-price-data[value=' + checked_rest_id + ']')[0].classList.remove('row-visibility');
                }
            }
        }
    }

    function showAllLocationsPart(rows, dividers, checked_rests) {
        for (var i = 0; i < rows.length; i++) {
            if (i == 0) {
                rows[i].classList.remove('row-visibility');
                dividers[i].classList.remove('hide');
            } else {
                for (var j = 0; j < checked_rests.length; j++) {
                    var checked_rest_id = checked_rests[j].value;
                    $('hr[value=' + checked_rest_id + ']')[0].classList.add('hide');
                    $('.restaurant-price-data[value=' + checked_rest_id + ']')[0].classList.add('row-visibility');
                }
            }
        }
    }

    $(document).ready(function () {

        if ($('.recipe-mode')[0]) {
            var rests = $('input[name="restaurant[]"]');
            var mode = $('.recipe-mode')[0].textContent;
            var dividers = $('.sales-row-divider');
            var rows = $('.restaurant-price-data');
            var checked_rests_at_beginning = getCurrentlyCheckedRestaurants(rests);
            var previously_checked_rests = checked_rests_at_beginning;
            if (mode == "not-view-per-location" || mode == "not-view-all-locations") {
                if (checked_rests_at_beginning.length == 0) {
                    $('.per-location-positioning').addClass('hide');
                    rows[0].classList.remove('row-visibility');
                    dividers[0].classList.remove('hide');
                }
                $('body').on('click', 'input[name="restaurant[]"]', function () {
                    if (previously_checked_rests == 0) {
                        $('.per-location-positioning').removeClass('hide');
                        $('#per_location_switch')[0].checked = false;
                    }
                    var checked_rests = getCurrentlyCheckedRestaurants(rests);
                    if (previously_checked_rests.length < checked_rests.length) {
                        var diff = checked_rests.filter(x => !previously_checked_rests.includes(x));
                        if (previously_checked_rests.length == 0) {
                            $('.per-location-positioning').removeClass('hide');
                            $('#per_location_switch')[0].checked = false;
                        }
                        if ($('#per_location_switch')[0].checked) {
                            var checked_rest_id = diff[0].value;
                            $('input[name="restaurant_id"][value="' + checked_rest_id + '"]').parent().removeClass('row-visibility');
                            $('hr[value="' + checked_rest_id + '"]').removeClass('hide');
                        }
                    } else {
                        if (checked_rests.length == 0) {
                            $('.per-location-positioning').addClass('hide');
                            rows.addClass('row-visibility');
                            dividers.addClass('hide');
                            rows[0].classList.remove('row-visibility');
                            dividers[0].classList.remove('hide');
                        }
                        if ($('#per_location_switch')[0].checked) {
                            if (checked_rests.length == 0) {
                                var unchecked_rest_id = previously_checked_rests[0].value;
                            } else {
                                var diff = previously_checked_rests.filter(x => !checked_rests.includes(x));
                                var unchecked_rest_id = diff[0].value;
                            }
                            var element = $('input[name="restaurant_id"][value="' + unchecked_rest_id + '"]');
                            element.parent().addClass('row-visibility');
                            var id = element[0].id;
                            $('#' + id + '.cmcalc-block').empty();
                            $('hr[value="' + unchecked_rest_id + '"]').addClass('hide');
                            var arrow = $('#' + id + '.arrow');
                            if (arrow.hasClass('up')) {
                                arrow.removeClass('up');
                                arrow.addClass('down');
                            }
                        }
                    }
                    previously_checked_rests = checked_rests;

                });
            } else {
                var rr_data_length = $('.rr-data-length')[0].textContent;
                if (rr_data_length == 0) {
                    rows[0].classList.remove('row-visibility');
                    dividers[0].classList.remove('hide');
                }
            }
        }



    });

    $('body').on('click', '.netto', function () {
        $('.netto').addClass('clicked');
        $('.brutto').removeClass('clicked');
        $('.price-type-selection').val('netto').trigger('updated');
    });

    $('body').on('click', '.brutto', function () {
        $('.brutto').addClass('clicked');
        $('.netto').removeClass('clicked');
        $('.price-type-selection').val('brutto').trigger('updated');
    });

    $('body').on('click', '#per_location_switch', function () {
        var dividers = $('.sales-row-divider');
        var rows = $('.restaurant-price-data');
        var rests = $('input[name="restaurant[]"]');
        var checked_rests = getCurrentlyCheckedRestaurants(rests);
        if ($('#per_location_switch')[0].checked) {
            showPerLocationsPart(rows, dividers, checked_rests);
            $('.recipe-mode')[0].textContent = 'not-view-per-location';
        } else {
            showAllLocationsPart(rows, dividers, checked_rests);
            $('.recipe-mode')[0].textContent = 'not-view-all-locations';
        }
        $('.cmcalc-block').empty();
        var arrows = $('.arrow');
        if (arrows.hasClass('up')) {
            arrows.removeClass('up');
            arrows.addClass('down');
        }
    });

    var archive_click = 0; // used to block ajax request on automatic click on button to hide action's info message
    var archived = $('#recipe-archive-info').attr('value'); // get current status of recipe on init

    /**
     * @param el_obj => html element of action performed
     * @param fn_timeout => function for removing notification after timeout
     */
    function archiveActiveAction(el_obj, fn_timeout) {
        archive_click++;
        if (archive_click < 2) {
            document.getElementById('recipe-archive-info').innerHTML =
                document.getElementById((archived ? 'unarchive-info-message' : 'archive-info-message')).innerHTML;
            fn_timeout();
            $.ajax({
                url: el_obj.attr('data-url'),
                method: 'post',
                success: function (data) {
                    archived = data.archived;
                    document.getElementById('recipe-view-menu-title').innerHTML =
                        document.getElementById((archived ? 'menu-title-icon-show' : 'menu-title-show')).innerHTML;
                    document.getElementById('recipe-view-main-title').innerHTML =
                        document.getElementById((archived ? 'recipe-view-title-icon-show' : 'recipe-view-title-show')).innerHTML;
                }
            });
        }
        else {
            document.getElementById('recipe-archive-info').innerHTML = "";
            archive_click = 0;
        }
    }

    $('#list-recipe-view-archive-button #recipe-view-archive-action').on('click', function () { // archive/activate recipe in recipe-view
        archiveActiveAction($(this), function () {
            setTimeout(() => {
                $('#list-recipe-view-archive-button #recipe-view-archive-action').click();
                clearTimeout();
            }, 2000);
        });
    });

    $('#db-list-recipe-view-archive-button #db-recipe-view-archive-action').on('click', function () { // archive/activate recipe in recipe-view of recipes database
        archiveActiveAction($(this), function () {
            setTimeout(() => {
                $('#db-list-recipe-view-archive-button #db-recipe-view-archive-action').click();
                clearTimeout();
            }, 2000);
        });
    });

    /* duration */
    $('body').on('focus', '.duration-input', function () {
        $(this).select();
    });



    function stepContentBlockIndexCorrections() {
        var steps = $('.step-section-content');
        for (var i = 0; i < steps.length; i++) {
            var index = i + 1;
            var stepIdDiv = $(steps[i]).find('.step-id-div');
            stepIdDiv.find('.step-id-number').html(index);
            stepIdDiv.find('input[type="hidden"]').attr('name', 'recipe_steps_data[' + index + '][step_id]');
            stepIdDiv.find('input[type="hidden"]').attr('value', index);
            var stepImageDiv = $(steps[i]).find('.step-image-border');
            stepImageDiv.find('.step-image-id').attr('name', 'recipe_steps_data[' + index + '][image-id]');
            stepImageDiv.find('.step-image-status').attr('name', 'recipe_steps_data[' + index + '][image-status]');
            var stepDescDiv = $(steps[i]).find('.step-description-part');
            stepDescDiv.find('textarea').attr('name', 'recipe_steps_data[' + index + '][description]');
            var stepTitleDiv = $(steps[i]).find('.step-title-and-icons');
            stepTitleDiv.find('input[type="text"]').attr('name', 'recipe_steps_data[' + index + '][title]');
            var activityDiv = $(steps[i]).find('.activity-block');
            activityDiv.find('input[type="checkbox"]').attr('name', 'recipe_steps_data[' + index + '][non_production_step]');
            activityDiv.find('input[type="text"]').attr('name', 'recipe_steps_data[' + index + '][persons_input]');
            var durationInputFields = $(steps[i]).find('.duration-input');
            $(durationInputFields[0]).attr('name', 'recipe_steps_data[' + index + '][duration_days]');
            $(durationInputFields[1]).attr('name', 'recipe_steps_data[' + index + '][duration_hours]');
            $(durationInputFields[2]).attr('name', 'recipe_steps_data[' + index + '][duration_minutes]');
            $(durationInputFields[3]).attr('name', 'recipe_steps_data[' + index + '][duration_seconds]');
            var equipmentDiv = $(steps[i]).find('.equipment-block');
            equipmentDiv.find('.equipment-checkmark').each(function () {
                var id = $(this).find('.equipment-checkmark-id').html();
                $(this).find('input[type="checkbox"]').attr('name', 'recipe_steps_data[' + index + '][' + id + ']');
            });
            if (i < steps.length - 1) {
                $(steps[i]).find('.recipe-step-dropdown-select').addClass('hidden');
                $(steps[i]).find('.step-icons').addClass('wo-focus-positioning');
            } else {
                $(steps[i]).find('.recipe-step-dropdown-select').removeClass('hidden');
                $(steps[i]).find('.step-icons').removeClass('wo-focus-positioning');
            }
        }
    }

    function recalcTimeData(days, hours, minutes, seconds) {
        var timeInSeconds = 0;
        if (!isNaN(days)) {
            timeInSeconds = timeInSeconds + days * 86400;
        }
        if (!isNaN(hours)) {
            timeInSeconds = timeInSeconds + hours * 3600;
        }
        if (!isNaN(minutes)) {
            timeInSeconds = timeInSeconds + minutes * 60;
        }
        if (!isNaN(seconds)) {
            timeInSeconds = timeInSeconds + seconds * 1;
        }

        if ($('.steps-content-title-positioning').html() == 'Steps') {
            var lang = "en";
        } else {
            var lang = "de";
        }
        var time_data = [];
        var message_elements = [];
        if (timeInSeconds > 0) {
            time_data.push(true);
            if (timeInSeconds >= 86400) {
                var days_new = Math.floor(timeInSeconds / 86400);
                timeInSeconds = timeInSeconds - days_new * 86400;
                if (lang == "en") {
                    message_elements.push(days_new + " D");
                } else {
                    message_elements.push(days_new + " T");
                }
            }
            if (timeInSeconds >= 3600) {
                var hours_new = Math.floor(timeInSeconds / 3600);
                timeInSeconds = timeInSeconds - hours_new * 3600;
                if (lang == "en") {
                    message_elements.push(hours_new + " Hrs");
                } else {
                    message_elements.push(hours_new + " Std");
                }
            }
            if (timeInSeconds >= 60) {
                var minutes_new = Math.floor(timeInSeconds / 60);
                timeInSeconds = timeInSeconds - minutes_new * 60;
                if (lang == "en") {
                    message_elements.push(minutes_new + " Min");
                } else {
                    message_elements.push(minutes_new + " Min");
                }
            }
            if (timeInSeconds > 0) {
                var seconds_new = timeInSeconds;
                if (lang == "en") {
                    message_elements.push(seconds_new + " Sec");
                } else {
                    message_elements.push(seconds_new + " Sek");
                }
            }
        } else {
            time_data.push(false);
        }
        time_data.push(message_elements.join(' '));

        return time_data;
    }

    function recalcTotalData(cont = null) {
        var id = cont.attr("id");
        var lang = null;
        var index = null;
        var langProcessed = [];
        var langs = getAvailableLanguages();
        var userCurrentDefaultLang = getCurrentLanguage();
        if(id != null)
        {
            var temp = id.split("-");

            lang = temp[1];
            index = temp[2];
        }
        
        var containers = $('.duration-block-container');
        var days = 0;
        var hours = 0;
        var minutes = 0;
        var seconds = 0;
        for (var i = 0; i < containers.length; i++) {

            if(lang != null && index != null)
            {
                var temp = containers[i];
                var containerIndex = getDurationBoxIndex(temp.id);
                var containerLang = getDurationBoxLang(temp.id);

              
                if (containerLang == userCurrentDefaultLang) {
                    var durationInputs = $(containers[i]).find('.duration-input');
                    days = days + $(durationInputs[0]).val() * 1;
                    hours = hours + $(durationInputs[1]).val() * 1;
                    minutes = minutes + $(durationInputs[2]).val() * 1;
                    seconds = seconds + $(durationInputs[3]).val() * 1;
                
                 }
               


            } else {
                var durationInputs = $(containers[i]).find('.duration-input');
                days = days + $(durationInputs[0]).val() * 1;
                hours = hours + $(durationInputs[1]).val() * 1;
                minutes = minutes + $(durationInputs[2]).val() * 1;
                seconds = seconds + $(durationInputs[3]).val() * 1;
            }

        }
        return recalcTimeData(days, hours, minutes, seconds);
    }

    function getDurationBoxLang(id)
    {
        var temp = id.split("-");

        return temp[1];
    }
    function getDurationBoxIndex(id)
    {
        var temp = id.split("-");

        return temp[2];
    }

    function focusOnStepSectionContent(container) {
        $('.step-id-number').addClass('id-opacity');
        $('.step-icons').addClass('wo-focus-positioning');
        $('.recipe-step-dropdown-select').addClass('hidden');
        container.find('.step-id-number').removeClass('id-opacity');
        container.find('.step-icons').removeClass('wo-focus-positioning');
        container.find('.recipe-step-dropdown-select').removeClass('hidden');
    }

    $('body').on('click', '.add-new-step-button', function () {
        $(".add-new-step-button").attr('disabled', 'disabled');
        // var ids = $('.step-id-number');
        // if (ids.length == 0) {
        //     next_id = 1;
        // } else {
        //     var next_id = toNumber($(ids[ids.length - 1]).html()) + 1;
        // }

        var step_id = parseInt($(".current-steps-count").val()) + 1;

        var lang = ($('.step-section-content:not(.non-active-step)')).attr('step-lang');

        if (currentSelectedLang != '') {
            lang = currentSelectedLang;
        }


        if (lang == null || lang == '') {

            lang = $(".user-default-lang").val();
            step_id = 1;
        }

        var step = $("div[step-lang='" + lang + "']").last();

        var step_1 = step.children(".step-id-div");

        var test = step_1.children(".step-id-number").last();

        var next_step_label = 0;
        if (test != null) {

            // var last_step = parseInt(test[0].innerText);

            // if (last_step != null) {
            //     next_step_label = (last_step + 1);
            // }

            var last_step = null;

            if (test.length > 0) {
                last_step = parseInt(test[0].innerText);
            }

            if (last_step != null) {
                next_step_label = (last_step + 1);
            } else {
                next_step_label = 1;
            }
        }



        // var ids = $('.step-id-number');
        // if (ids.length == 0) {
        //     next_id = 1;
        // } else {
        //     var next_id = toNumber($(ids[ids.length - 1]).html()) + 1;
        // }

        var userCurrentDefaultLang = getUserDefaultLang();
        $.ajax({
            url: ('/recipes/new_step'),
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            data: {
                step_id: step_id,
                lang: lang,
                next_step_label: next_step_label
            },
            success: function (data) {

                $(".current-steps-count").val((step_id));


                $('.steps-content').append(data['html']);
                var steps = $('.step-section-content');
                focusOnStepSectionContent($(steps[steps.length - 1]));
                equipment_checkboxes = getEquipmentCheckboxData();
                $(".add-new-step-button").removeAttr('disabled');

                if ($('#auto_translate_recipe_setting').val()) {
                    var parent_div = $("#stepIdNumber-de-" + next_step_label).parent().parent();

                    var title_to_translate = "";
                    var desc_to_translate = "";

                    parent_div.each(function (i, obj) {
                        desc_to_translate = ($(this).find('.step-description')).val();
                        title_to_translate = ($(this).find(':input.tt-input')).val();
                        if (title_to_translate == null) {
                            title_to_translate = ($(this).find(':input.step-title-input')).val();
                        }

                    });



                    if (title_to_translate != null && title_to_translate != "") {
                        text_translate(userCurrentDefaultLang, lang, title_to_translate, function (data) {

                            if (data['t'] != null) {
                                $("#stepTitle-" + lang + "-" + next_step_label).val(data['t']);
                            }
                        });
                    }

                    if (desc_to_translate != null && desc_to_translate != "") {
                        text_translate(userCurrentDefaultLang, lang, desc_to_translate, function (data) {

                            if (data['t'] != null) {
                                $("#stepDescription-" + lang + "-" + next_step_label).val(data['t']);
                            }
                        });
                    }
                }


            },
            complete: function () {
                htmlLoading(false);
                $(".add-new-step-button").removeAttr('disabled');
            }
        });
    });

    $('body').on('click', '.recipe-step-copy-action', function () {

        var step_id = parseInt($(".current-steps-count").val()) + 1;

        var lang = ($('.step-section-content:not(.non-active-step)')).attr('step-lang');
        //ovjde
        if (currentSelectedLang != '') {
            lang = currentSelectedLang;
        }


        if (lang == null || lang == '') {

            lang = $(".user-default-lang").val();
            step_id = 1;
        }

        var step = $("div[step-lang='" + lang + "']").last();

        var step_1 = step.children(".step-id-div");

        var test = step_1.children(".step-id-number").last();

        var next_step_label = 0;
        if (test != null) {

            var last_step = parseInt(test[0].innerText);

            if (last_step != null) {
                next_step_label = (last_step + 1);
            }
        }

        var buttonClicked = $(this).parents('.recipe-step-action-selector').prev();
        buttonClicked.removeAttr('aria-expanded');
        buttonClicked.removeAttr('data-quantity-unit');
        buttonClicked.removeAttr('data-quantity-unit-txt');
        $(buttonClicked[0]).html("");
        buttonClicked.append('<img src="/img/recipe-steps-icons/circles.svg" alt="circles" class="circles-positioning">');
        var content = $(this).parents('.step-section-content');
        var id = toNumber(content.find('.id-number').html());
        var current_id = toNumber(content.find('.step-id-number').html());
        var current_imageId = toNumber(content.find('input[name="recipe_steps_data[' + current_id + '][image-id]"]').val());
        var current_imageStatus = content.find('input[name="recipe_steps_data[' + current_id + '][image-status]"]').val();
        var current_imagePath = content.find('.step-image').attr('src');
        var current_title = content.find('input[name="recipe_steps_data[' + current_id + '][title]"]').val();
        var current_nonProductionStep = content.find('input[name="recipe_steps_data[' + current_id + '][non_production_step]"]').val();
        var current_persons = content.find('input[name="recipe_steps_data[' + current_id + '][persons_input]"]').val();
        var current_days = content.find('input[name="recipe_steps_data[' + current_id + '][duration_days]"]').val();
        var current_hours = content.find('input[name="recipe_steps_data[' + current_id + '][duration_hours]"]').val();
        var current_minutes = content.find('input[name="recipe_steps_data[' + current_id + '][duration_minutes]"]').val();
        var current_seconds = content.find('input[name="recipe_steps_data[' + current_id + '][duration_seconds]"]').val();
        var current_description = content.find('textarea[name="recipe_steps_data[' + current_id + '][description]"]').val();
        var current_recipeId = $('#recipe-id').val();
        $.ajax({
            url: ('/recipes/copy_step'),
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            data: {
                id: id,
                step_id: current_id + 1,
                step_imageId: current_imageId,
                step_imageStatus: current_imageStatus,
                step_imagePath: current_imagePath,
                step_title: current_title,
                step_nonProductionStep: current_nonProductionStep,
                step_persons: current_persons,
                step_days: current_days,
                step_hours: current_hours,
                step_minutes: current_minutes,
                step_seconds: current_seconds,
                step_description: current_description,
                step_recipeId: current_recipeId,
                lang: lang,
                next_step_label: current_id + 1,
                new_step_id: step_id

            },
            success: function (data) {

                //$(".current-steps-count").val(step_id + 1);

                var temp = $(".current-steps-count").val();
                $(".current-steps-count").val(temp + 1);



                $(data['html']).insertAfter(content);
                //stepContentBlockIndexCorrections();
                var total_data = recalcTotalData();
                if (!total_data[0]) {
                    $('.total-time').addClass('hidden');
                } else {
                    $('.total-time').removeClass('hidden');
                    $('.total-time-text-changeable').html(total_data[1]);
                }
                var steps = $('.step-section-content');
                focusOnStepSectionContent($(steps[current_id]));
                if (current_imageId) {
                    $(steps[current_id]).find('.step-empty-image-border').addClass('hidden');
                    $(steps[current_id]).find('.step-image-border').removeClass('hidden');
                }
                equipment_checkboxes = getEquipmentCheckboxData();

                var new_label = 1;

                var id_example = "stepIdNumber-" + lang;
                var steps = $("[id^=" + id_example + "]");


                $new_label = 1;
                steps.each(function () {
                    $(this).attr("id", "stepIdNumber-" + lang + "-" + new_label);
                    $(this).text(new_label);


                    new_label++;
                })

            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    function delete_step(lang,  name_step_count) {

        var current_lang_count = $(".current-steps-count").val();

        var new_step_count = 1;

        var step_changed = false;

        var regularExpression = /(?:\[).*?(?=\])/g;
        // var regularExpression_bad = /\[(.*?)\]/
        // var regularExpression = /\[(.*?)\]/;

        var new_step_label = 1;

        for (let index = 1; index <= current_lang_count; index++) {

            step_changed = false;

            if ($("#stepTitle-" + lang + "-" + index).length) {

                step_changed = true;

                //reset title step count
                $("#stepTitle-" + lang + "-" + index).attr("id", "stepTitle-" + lang + "-" + new_step_count);

                $("#stepTitle-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][title]");

                //end reset title step count
            }

            if ($("#stepDescription-" + lang + "-" + index).length) {
                //reset description step count
                $("#stepDescription-" + lang + "-" + index).attr("id", "stepDescription-" + lang + "-" + new_step_count);

                $("#stepDescription-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][description]");
                //end reset description step count
            }


            if ($("#stepLanguage-" + lang + "-" + index).length) {
                //reset language step count
                $("#stepLanguage-" + lang + "-" + index).attr("id", "stepLanguage-" + lang + "-" + new_step_count);

                $("#stepLanguage-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][lang]");
                //end reset language step count
            }

            if ($("#stepIdNumber-" + lang + "-" + index).length) {

                //reset step ID 
                $("#stepIdNumber-" + lang + "-" + index).attr("id", "stepIdNumber-" + lang + "-" + new_step_label);

                $("#stepIdNumber-" + lang + "-" + new_step_label).text(new_step_label);
                //end reset step ID

                new_step_label++;
            }

            //reset translation button 
            $("#translate-" + lang + "-" + index).attr("id", "translate-" + lang + "-" + new_step_count);

            //$("#stepImageId-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + new_step_count + "][image-id]");
            //end reset translation button


            //reset step duration count

            $("#durationDays-" + lang + "-" + index).attr("id", "durationDays-" + lang + "-" + new_step_count);

            $("#durationDays-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][duration_days]");

            $("#durationHours-" + lang + "-" + index).attr("id", "durationHours-" + lang + "-" + new_step_count);

            $("#durationHours-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][duration_hours]");

            $("#durationMinutes-" + lang + "-" + index).attr("id", "durationMinutes-" + lang + "-" + new_step_count);

            $("#durationMinutes-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][duration_minutes]");

            $("#durationSeconds-" + lang + "-" + index).attr("id", "durationSeconds-" + lang + "-" + new_step_count);

            $("#durationSeconds-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][duration_seconds]");

            //end reset step duration count

            // reset step equipment 

            var equipment_inputs = $("#equipmentBlock-" + lang + "-" + index + " :input");

            $("#equipmentBlock-" + lang + "-" + index + " :input").each(function () {
                var input_name = $(this).attr("name");

                var getTheValueWithIndex = input_name.match(regularExpression);

                var new_name = "recipe_steps_data[" + name_step_count + "][" + getTheValueWithIndex[1] + "]";

                $(this).attr("name", new_name);

            });
            //end reset step equipment 

            //reset image ID 
            $("#stepImageId-" + lang + "-" + index).attr("id", "stepImageId-" + lang + "-" + new_step_count);

            $("#stepImageId-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][image-id]");
            //end reset image ID

            //reset image status 
            $("#stepImageStatus-" + lang + "-" + index).attr("id", "stepImageStatus-" + lang + "-" + new_step_count);

            $("#stepImageStatus-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][image-status]");
            //end reset image status


            //reset stepId count
            $("#stepId-" + lang + "-" + index).attr("id", "stepId-" + lang + "-" + new_step_count);

            $("#stepId-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][step_id]");

            $("#stepId-" + lang + "-" + new_step_count).val(new_step_count);
            //end stepId count

            //reset non production step
            $("#nonProductionStep-" + lang + "-" + index).attr("id", "nonProductionStep-" + lang + "-" + new_step_count);

            $("#nonProductionStep-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][non_production_step]");

            $("#nonProductionStep-" + lang + "-" + new_step_count).val(new_step_count);
            //end non production step

            //reset persons input
            $("#personsInput-" + lang + "-" + index).attr("id", "personsInput-" + lang + "-" + new_step_count);

            $("#personsInput-" + lang + "-" + new_step_count).attr("name", "recipe_steps_data[" + name_step_count + "][persons_input]");

            $("#personsInput-" + lang + "-" + new_step_count).val(new_step_count);
            //end persons input



            if (step_changed) {
                new_step_count ++;
                name_step_count ++;
            }
        }

        return name_step_count;

    }


    $('body').on('click', '.recipe-step-delete-action', function () {

        var activeLanguages = getAvailableLanguages();

        var parentDiv = $(this).parents('.step-section-content');

        if (parentDiv.find('.step-image-id').val() != "") {
            $.ajax({
                url: ('/recipes/steps/remove-step-image'),
                type: 'post',
                data: {
                    image_id: parentDiv.find('.step-image-id').val(),
                    _confirmed: 1
                }
            });
        }
        parentDiv.remove();

        var id = $(this).attr("id");
        var id_split = id.split("-");

        let name_step_count = 1;
        activeLanguages.forEach(element => {

            var parent = $("#stepId-" + element + "-" + id_split[2]).parents('.step-section-content');

            if (parent.find('.step-image-id').val() != "") {
                $.ajax({
                    url: ('/recipes/steps/remove-step-image'),
                    type: 'post',
                    data: {
                        image_id: parent.find('.step-image-id').val(),
                        _confirmed: 1
                    }
                });
            }

            parent.remove();

            name_step_count = delete_step(element, name_step_count);
        });


        // stepContentBlockIndexCorrections();
        // var total_data = recalcTotalData();
        // if (!total_data[0]) {
        //     $('.total-time').addClass('hidden');
        // } else {
        //     $('.total-time').removeClass('hidden');
        //     $('.total-time-text-changeable').html(total_data[1]);
        // }
        // var steps = $('.step-section-content');
        // focusOnStepSectionContent($(steps[steps.length - 1]));
        // equipment_checkboxes = getEquipmentCheckboxData();
    });

    $('body').on('click', '.activity-icon', function () {
        focusOnStepSectionContent($(this).parents('.step-section-content'));
        $(this).prev().addClass('hidden');
        $(this).next().removeClass('hidden');
        $(this).addClass('clicked-version');
        $(this).find('img[alt="persons-icon"]').removeClass('img-opacity');
        if ($(this).find('img[alt="persons-icon"]').hasClass('hidden')) {
            $(this).find('img[alt="activity-icon"]').addClass('hidden');
            $(this).find('img[alt="activity-clicked-icon"]').removeClass('hidden');
        } else {
            $(this).find('img[alt="persons-icon"]').addClass('hidden');
            $(this).find('img[alt="persons-clicked-icon"]').removeClass('hidden');
        }
        if (!($(this).next().find('input[type="checkbox"]').prop('checked'))) {
            $(this).next().find('input[type="text"]').focus();
        }
        $(this).find('.step-icon-text').addClass('clicked-text-version');
    });

    $('body').on('click', '.duration-icon', function () {
        focusOnStepSectionContent($(this).parents('.step-section-content'));
        $('#right-menu').css('z-index', '-1');
        $(this).prev().addClass('hidden');
        $(this).next().removeClass('hidden');
        $($(this).next().find('input[type="number"]')[0]).focus();
        $(this).addClass('clicked-version');
        $(this).find('img[alt="time-icon"]').removeClass('img-opacity');
        $(this).find('img[alt="time-icon"]').addClass('hidden');
        $(this).find('img[alt="time-clicked-icon"]').removeClass('hidden');
        $(this).find('.step-icon-text').addClass('clicked-text-version');
    });

    $('body').on('click', '.activity-checkmark', function () {
        var parentDiv = $(this).closest('.activity-block-row-2');
        if (parentDiv.find('input[type="checkbox"]').prop('checked')) {
            parentDiv.find('input[type="checkbox"]').val(1);
            parentDiv.closest('.activity-block').find('input[type="text"]').prop('disabled', true);
        } else {
            parentDiv.find('input[type="checkbox"]').val("");
            parentDiv.closest('.activity-block').find('input[type="text"]').val(0);
            parentDiv.closest('.activity-block').find('input[type="text"]').prop('disabled', false);
            parentDiv.closest('.activity-block').find('input[type="text"]').focus();
        }
    });

    function updateActivityIconContent(container) {
        var iconContent = container.prev();
        iconContent.addClass('not-empty-content');
        iconContent.removeClass('clicked-version');
        iconContent.find('img[alt="activity-clicked-icon"]').addClass('hidden');
        iconContent.find('img[alt="persons-clicked-icon"]').addClass('hidden');
        iconContent.find('.step-icon-text').removeClass('clicked-text-version');
        if (container.find('input[type="checkbox"]').prop('checked')) {
            iconContent.find('.step-icon-text').addClass('hidden');
            iconContent.find('img[alt="persons-icon"]').addClass('hidden');
            iconContent.find('img[alt="activity-icon"]').removeClass('hidden');
        } else {
            iconContent.find('.step-icon-text').removeClass('hidden');
            iconContent.find('.step-icon-text').html(container.find('input[type="text"]').val());
            iconContent.find('img[alt="persons-icon"]').removeClass('hidden');
            iconContent.find('img[alt="persons-icon"]').addClass('img-positioning');
            iconContent.find('img[alt="persons-clicked-icon"]').addClass('img-positioning');
            iconContent.find('img[alt="activity-icon"]').addClass('hidden');
        }
    }

    function updateDurationIconContent(container) {
        var iconContent = container.prev();
        iconContent.addClass('not-empty-content');
        iconContent.removeClass('clicked-version');
        iconContent.find('img[alt="time-icon"]').removeClass('hidden');
        iconContent.find('img[alt="time-clicked-icon"]').addClass('hidden');
        iconContent.find('.step-icon-text').removeClass('clicked-text-version');
        var durationInputs = container.find('.duration-input');
        var data = recalcTimeData($(durationInputs[0]).val(), $(durationInputs[1]).val(), $(durationInputs[2]).val(), $(durationInputs[3]).val());
        var total_data = recalcTotalData(container);
        if (!data[0]) {
            iconContent.find('img[alt="time-icon"]').removeClass('img-positioning');
            iconContent.find('img[alt="time-clicked-icon"]').removeClass('img-positioning');
            iconContent.find('.step-icon-text').html("");
        } else {
            iconContent.find('img[alt="time-icon"]').addClass('img-positioning');
            iconContent.find('img[alt="time-clicked-icon"]').addClass('img-positioning');
            iconContent.find('.step-icon-text').html(data[1]);
        }
        if (!total_data[0]) {
            $('.total-time').addClass('hidden');
        } else {
            $('.total-time').removeClass('hidden');
            $('.total-time-text-changeable').html(total_data[1]);
        }
    }

    function updateEquipmentIconContent(container) {
        var iconContent = container.prev();
        iconContent.addClass('not-empty-content');
        iconContent.removeClass('clicked-version');
        iconContent.find('img[alt="equipment-icon"]').removeClass('hidden');
        iconContent.find('img[alt="equipment-clicked-icon"]').addClass('hidden');
        iconContent.find('.step-icon-text').removeClass('clicked-text-version');
        var checked_equipment = [];
        container.find('.equipment-checkmark').each(function () {
            if ($(this).find('input[type="checkbox"]').prop('checked'))
                if ($(this).find('.equipment-checkmark-label').html() != "") {
                    checked_equipment.push($(this).find('.equipment-checkmark-label').html());
                }
        });
        if (checked_equipment.length > 1) {
            var num = checked_equipment.length - 1;
            iconContent.find('.step-icon-text').removeClass('hidden');
            iconContent.find('img[alt="equipment-icon"]').addClass('img-positioning');
            iconContent.find('img[alt="equipment-clicked-icon"]').addClass('img-positioning');
            if ($('.steps-content-title-positioning').html() == 'Steps') {
                iconContent.find('.step-icon-text').html(checked_equipment[0] + ' + ' + num + ' more');
            } else {
                iconContent.find('.step-icon-text').html(checked_equipment[0] + ' + ' + num + ' mehr');
            }
        } else if (checked_equipment.length == 1) {
            iconContent.find('.step-icon-text').removeClass('hidden');
            iconContent.find('img[alt="equipment-icon"]').addClass('img-positioning');
            iconContent.find('img[alt="equipment-clicked-icon"]').addClass('img-positioning');
            iconContent.find('.step-icon-text').html(checked_equipment[0]);
        } else {
            iconContent.find('.step-icon-text').addClass('hidden');
            iconContent.find('img[alt="equipment-icon"]').removeClass('img-positioning');
            iconContent.find('img[alt="equipment-clicked-icon"]').removeClass('img-positioning');
        }
    }

    $('.recipe-form-page').mouseup(function (e) {
        var container1 = $('.activity-block-container');
        var container2 = $('.duration-block-container');
        var container3 = $('.equipment-block-container');
        var updateActivity = false;
        var updateDuration = false;
        var updateEquipment = false;

        for (var i = 0; i < container1.length; i++) {
            if (!$(container1[i]).hasClass('hidden')) {
                var container = $(container1[i]);
                updateActivity = true;
                break;
            } else if (!$(container2[i]).hasClass('hidden')) {
                var container = $(container2[i]);
                updateDuration = true;
                break;
            } else if (!$(container3[i]).hasClass('hidden')) {
                var container = $(container3[i]);
                updateEquipment = true;
                break;
            }
        }

        if (container && !container.is(e.target) && container.has(e.target).length === 0) {
            container.addClass('hidden');
            $(this).parent().find('.steps-tooltip-text').removeClass('hidden');
            $('#right-menu').css('z-index', '1');
            if (updateActivity) {
                updateActivityIconContent(container);
            } else if (updateDuration) {
                updateDurationIconContent(container);
            } else if (updateEquipment) {
                updateEquipmentIconContent(container);
            }
        }
    });

    $('body').on('change', '.duration-input, .persons-input, .equipment-edit-input', function () {
        var tmp = $(this).val();
        $(this).attr('value', tmp);
    });

    $('body').on('focus', '.step-section-content', function () {
        focusOnStepSectionContent($(this));
    });

    $('body').on('mouseover', '.step-section-content', function () {
        focusOnStepSectionContent($(this));
    });


    $('body').on('click', '.rightbar-block-title-arrow-positioning, .rightbar-block-title-content:has(.rightbar-block-title-arrow-positioning)', function () {
        var rightbarBlock = $(this).closest('.rightbar-block, .rightbar-block-opened').first();
        if (rightbarBlock.hasClass('rightbar-block')) {
            rightbarBlock.addClass('hidden');
            rightbarBlock.next().removeClass('hidden');
        } else {
            rightbarBlock.addClass('hidden');
            rightbarBlock.prev().removeClass('hidden');
        }
    });

    $('.rightbar-block-title-arrow-positioning').each(function () {
        $(this).closest('.rightbar-block-title-content').addClass('has-content')
    });

    $('body').on('focus', '.freshness-input', function () {
        $(this).select();
    });

    /*$('body').on('click', '.checkbox-container', function(e) {
    e.stopPropagation();
    e.preventDefault();
    e.stopImmediatePropagation();
    
    var input = $(this).find('[type=checkbox]');
    input.prop('checked', !input.prop('checked'));
    });*/

    $('body').on('change', 'input[name=freshness_days_real]', function () {
        $('input[name=freshness_days]').val($('input[name=freshness_days_real]').val());
    });

    $('body').on('change', 'input[name=freshness_hours_real]', function () {
        $('input[name=freshness_hours]').val($('input[name=freshness_hours_real]').val());
    });

    $('body').on('change', 'input[name=freshness_minutes_real]', function () {
        $('input[name=freshness_minutes]').val($('input[name=freshness_minutes_real]').val());
    });

    $('body').on('click', '.freshness-checkmark', function () {
        if ($(this).find('input[type="checkbox"]').prop('checked')) {
            $('input[name=can_be_freezed]').val("1");
            $('.freshness-empty-message').addClass('hidden');
            $('.freshness-message').removeClass('hidden');
        } else {
            $('input[name=can_be_freezed]').val("");
            $('.freshness-empty-message').removeClass('hidden');
            $('.freshness-message').addClass('hidden');
        }
    });

    function updateAvailabilityClosedBlockMessage() {
        var li_elements = $('.availability');
        var months = [];
        for (var i = 0; i < 12; i++) {
            months.push($(li_elements).html().trim());
        }
        var message = "";
        var availability_content = $('.recipe-availability-data').children();
        var counter = 0;
        for (var i = 0; i < availability_content.length; i++) {
            message = message + $($($('.recipe-availability-data').children()[i]).find('.availability-dropdown-btn')[0])[0].innerText.trim() + '-' + $($($('.recipe-availability-data').children()[i]).find('.availability-dropdown-btn')[1])[0].innerText.trim();
            if (counter < availability_content.length - 1) {
                message = message + ', ';
            }
            counter = counter + 1;
        }
        $('.availability-default-message').addClass('hidden');
        $('.availability-message').removeClass('hidden');
        $('.availability-message').html(message);
    }

    if ($('input[name=all_year_availability_real]')) {
        if ($('input[name=all_year_availability_real]').val() == '1') {
            $('input[name=all_year_availability_real]').trigger('click');
            $('.recipe-availability-data').addClass('hidden');
            $('.av-link-spec').addClass('hidden');
        }
    }

    $('body').on('click', '.availability-checkmark', function () {
        if ($(this).find('input[type="checkbox"]').prop('checked')) {
            $('input[name=all_year_availability]').val("1");
            $('.recipe-availability-data').addClass('hidden');
            $('.av-link-spec').addClass('hidden');
            $('.availability-default-message').removeClass('hidden');
            $('.availability-message').addClass('hidden');
        } else {
            $('input[name=all_year_availability]').val("");
            $('.recipe-availability-data').removeClass('hidden');
            $('.av-link-spec').removeClass('hidden');
            $('.availability-default-message').addClass('hidden');
            $('.availability-message').removeClass('hidden');
        }
    });

    $('body').on('click', '.availability-link-positioning', function () {
        var availability_from_fields = $('.availability_from_hidden_data');
        var availability_to_fields = $('.availability_to_hidden_data');
        var availability_from_data = [];
        var availability_to_data = [];
        for (var i = 0; i < availability_from_fields.length; i++) {
            availability_from_data[i] = availability_from_fields[i].value;
            availability_to_data[i] = availability_to_fields[i].value;
        }
        $.ajax({
            url: '/recipes/new-availability-period',
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            data: {
                availability_from_data: availability_from_data,
                availability_to_data: availability_to_data,
            },
            success: function (data) {
                $('.recipe-availability-data-place-to-insert').empty();
                $('.availability-from-and-to-data').empty();
                $('.recipe-availability-data-place-to-insert').html(data['html']);
                for (var i = 0; i < availability_from_fields.length; i++) {
                    $('.availability-from-and-to-data').append('<input type="hidden" name="availability_from_data[' + i + ']" value="' + availability_from_fields[i].value + '" class="availability_from_hidden_data"/>');
                    $('.availability-from-and-to-data').append('<input type="hidden" name="availability_to_data[' + i + ']" value="' + availability_to_fields[i].value + '" class="availability_to_hidden_data"/>');
                }
                $('.availability-from-and-to-data').append('<input type="hidden" name="availability_from_data[' + availability_from_fields.length + ']" value="1" class="availability_from_hidden_data"/>');
                $('.availability-from-and-to-data').append('<input type="hidden" name="availability_to_data[' + availability_from_fields.length + ']" value="12" class="availability_to_hidden_data"/>');
            },
            complete: function () {
                htmlLoading(false);
                updateAvailabilityClosedBlockMessage();
            }
        });
    });

    $('body').on('click', '.availability-from-value li a, .availability-to-value li a', function () {
        var month_index = $(this).attr('data-value');
        var tmp = $(this).parent().parent().parent().find('.availability-dropdown-btn').attr('data-dropdown-menu-input-name');
        $('input[type="hidden"][name="' + tmp + '"]').val(month_index);
        updateAvailabilityClosedBlockMessage();
    });

    $('body').on('click', '.close-button', function () {
        if ($('.availability-input-content').length > 3) {
            $(this).siblings().find('.availability-dropdown-btn').each(function () {
                var tmp = $(this).attr('data-dropdown-menu-input-name');
                $('input[type="hidden"][name="' + tmp + '"]').remove();
            });
            $(this).parent().remove();
        }
        updateAvailabilityClosedBlockMessage();
    });

    function updateTagsBlockMessage() {
        $('.all-tags').empty();
        var checked_tag_names = [];
        $('.tags-checkmark').each(function () {
            if ($(this).find('input[type="checkbox"]').prop('checked')) {
                checked_tag_names.push($(this).find('.tags-checkmark-label').html());
            }
        });
        if (checked_tag_names.length == 0) {
            $('.all-tags').addClass('hidden');
            $('.all-tags').next().removeClass('hidden');
            $('.all-tags').parents('.rightbar-block').removeClass('tags-block-height-fix');
        } else {
            for (var i = 0; i < checked_tag_names.length; i++) {
                var name = checked_tag_names[i];
                var new_child = '<label class="tag-border-and-text">' + name + '</label>';
                $('.all-tags').append(new_child);
            }
            $('.all-tags').removeClass('hidden');
            $('.all-tags').next().addClass('hidden');
            $('.all-tags').parents('.rightbar-block').addClass('tags-block-height-fix');
        }
    }

    $('body').on('click', '.tags-checkmark', function () {
        updateTagsBlockMessage();
        var name = 'recipe_tags[' + $(this).find('.tags-checkmark-id').html() + ']';
        if ($(this).find('input[type="checkbox"]').prop('checked')) {
            $('.recipe-tags-data').find('input[name="' + name + '"]').val("1");
        } else {
            $('.recipe-tags-data').find('input[name="' + name + '"]').val("");
        }
    });

    var action = 1;
    $('body').on('click', '.restaurant-checkmark-group', function () {
        if (action == 1) {
            action = 2;
        } else {
            if (!($('#per_location_switch').prop('checked'))) {
                $('#per_location_switch').trigger('click');
            }
            var all_restaurants = $('input[type=checkbox][name="restaurant[]"]');
            var checked_restaurants = [];
            for (var i = 0; i < all_restaurants.length; i++) {
                if ($(all_restaurants[i]).prop('checked')) {
                    checked_restaurants.push($(all_restaurants[i]).parent().find('.rightbar-block-content-label').html());
                }
            }
            var block = $(this).parent().parent().prev();
            if (checked_restaurants.length > 0) {
                if (checked_restaurants.length > 1) {
                    block.find('.remaining-restaurants').removeClass('hidden');
                    block.find('.leading-restaurant-name').html(checked_restaurants[0]);
                    block.find('.number-of-rr').html(checked_restaurants.length - 1);
                } else {
                    block.find('.empty-content').addClass('hidden');
                    block.find('.long-name-correction').removeClass('hidden');
                    block.find('.remaining-restaurants').addClass('hidden');
                    block.find('.leading-restaurant-name').html(checked_restaurants[0]);
                }
            } else {
                block.find('.empty-content').removeClass('hidden');
                block.find('.long-name-correction').addClass('hidden');
            }

            var restaurantId = $(this).find('.restaurant-id-unique').html();
            var checkbox = $(this).find('input');

            $('.sync-block-rest-id').each(function () {
                if ($(this).html() == restaurantId) {
                    if (checkbox.prop('checked')) {
                        $(this).parent().find('.onoffswitch').find('input').prop('checked', true);
                    } else {
                        $(this).parent().find('.onoffswitch').find('input').prop('checked', false);
                    }
                }
            });
            action = 1;
        }
    });

    $('body').on('change', '.freshness-input', function () {
        var days = $('input[name="freshness_days_real"]').val();
        var hours = $('input[name="freshness_hours_real"]').val();
        var minutes = $('input[name="freshness_minutes_real"]').val();
        if (days == 0 && hours == 0 && minutes == 0) {
            $('.freshness-empty-message').removeClass('hidden');
            $('.freshness-message').addClass('hidden');
        } else {
            if ($(this).parents('.rightbar-block-opened').find('.rightbar-block-title-text').html() == "Freshness") {
                var lang = "en";
            } else {
                var lang = "de";
            }
            var message_elements = [];
            var invalidData = false;
            $('.freshness-empty-message').addClass('hidden');
            $('.freshness-message').removeClass('hidden');
            if (days >= 0) {
                if (days > 0) {
                    if (lang == "de") {
                        message_elements.push(days + " Tage");
                    } else {
                        message_elements.push(days + " Days");
                    }
                }
            } else {
                invalidData = true;
            }
            if (hours >= 0 && !invalidData) {
                if (hours > 0) {
                    if (lang == "de") {
                        message_elements.push(hours + " Stunden");
                    } else {
                        message_elements.push(hours + " Hours");
                    }
                }
            } else {
                invalidData = true;
            }
            if (minutes >= 0 && !invalidData) {
                if (minutes > 0) {
                    if (lang == "de") {
                        message_elements.push(minutes + ' Minuten');
                    } else {
                        message_elements.push(minutes + ' Minutes');
                    }
                }
            } else {
                invalidData = true;
            }
        }
        if (message_elements.length > 0 && !invalidData) {
            $('.freshness-message').html("Max " + message_elements.join(', '));
        } else {
            $('.freshness-empty-message').removeClass('hidden');
            $('.freshness-message').addClass('hidden');
        }
    });

    function addNewCategory() {
        var name = $('input.new-cat-name').val();

        $.ajax({
            type: "POST",
            url: $('input.new-cat-name').attr('data-url'),
            data: { name: name },
            beforeSend: function () {
                $('html').addClass('async-loading');
                $('[name=close-bulk-sm-menu]').trigger('force-close');
            },
            success: function (data) {
                var new_category = $('' +
                    '<label id=""><input type="checkbox" name="categories[]" value="" checked/><span class="label-name"></span></label>');

                new_category.attr('id', 'categories_' + data['id']);
                new_category.find('input').attr('value', data['slug']);
                new_category.find('span').text(data['name']);

                $('#categories-list').prepend(new_category);
            }
        });
    }


    $('input.new-cat-name')
        .on('keydown', function (e) {
            // enter pressed
            if (e.which === 13) {
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();

                addNewCategory();
                return false;
            }
        })
        .on('keyup', function (e) {
            var addBtn = $('.category-add');

            if ($(this).val()) {
                addBtn.removeAttr('disabled');
            }
            else {
                addBtn.attr('disabled', 'disabled');
            }
        });

    $('button.category-add').on('click', function () {
        addNewCategory();
    });

    $('.persons-input').keydown(function (e) {
        var container = $(this).closest('.activity-block-container');
        if (e.which == 13) {
            container.addClass('hidden');
            $(this).parents('.steps-tooltip').find('.steps-tooltip-text').removeClass('hidden');
            updateActivityIconContent(container);
        }
    });

    $('.duration-input').keydown(function (e) {
        var container = $(this).closest('.duration-block-container');
        if (e.which == 13) {
            container.addClass('hidden');
            $(this).parents('.steps-tooltip').find('.steps-tooltip-text').removeClass('hidden');
            $('#right-menu').css('z-index', '1');
            updateDurationIconContent(container);
        }
    });

    function getEquipmentCheckboxData() {
        var equipment_checkboxes = [];

        var it = 0;
        $('.step-section-content').each(function () {
            equipment_checkboxes.push([]);
            $(this).find('.equipment-block-container').find('.equipment-checkmark').each(function () {
                var id = $(this).find('.equipment-checkmark-id').html();
                equipment_checkboxes[it].push({ name: id, value: $(this).find('input').prop('checked') });
            });
            it++;
        });

        return equipment_checkboxes;
    }

    var equipment_checkboxes = [];
    if ($('.steps-wrapper')) {
        equipment_checkboxes = getEquipmentCheckboxData();
    }

    $('body').on('click', '.equipment-icon', function () {
        focusOnStepSectionContent($(this).parents('.step-section-content'));
        $(this).prev().addClass('hidden');
        $(this).next().removeClass('hidden');
        $(this).addClass('clicked-version');
        $(this).find('img[alt="equipment-icon"]').removeClass('img-opacity');
        $(this).find('img[alt="equipment-icon"]').addClass('hidden');
        $(this).find('img[alt="equipment-clicked-icon"]').removeClass('hidden');
        $(this).find('.step-icon-text').addClass('clicked-text-version');
    });

    $('body').on('mouseover', '.equipment-edit-row', function () {
        $(this).find('.equipment-edit-icon').removeClass('hidden');
        $(this).find('.equipment-remove-icon').removeClass('hidden');
    });

    $('body').on('mouseout', '.equipment-edit-row', function () {
        $(this).find('.equipment-edit-icon').addClass('hidden');
        $(this).find('.equipment-remove-icon').addClass('hidden');
    });

    $('body').on('click', '.equipment-edit-row-tmp1', function () {
        $('.remove-icon').trigger('click');
        var element1 = $(this).parents('.equipment-edit-row');
        element1.addClass('hidden');
        var element2 = element1.next();
        element2.removeClass('hidden');
        element2.find('.equipment-edit-input').focus();
    });

    $('body').on('click', '.add-new-equipment', function () {
        var element = $(this).closest('.equipment-last-row');
        $.ajax({
            url: ('/recipes/step/equipment/add'),
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                $('.remove-icon').trigger('click');
                $(data['html']).insertAfter(element);
                var inputs = element.closest('.equipment-block-row-2').find('.equipment-edit-input');
                $(inputs[0]).focus();
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.manage-equipment', function () {
        var container = $(this).closest('.equipment-block-container');
        var equipmentIcon = $(this).closest('.step-section-content').find('.equipment-icon');
        $.ajax({
            url: ('/recipes/step/equipment/manage'),
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                equipmentIcon.find('.step-icon-text').html("");
                equipmentIcon.find('img[alt="equipment-icon"]').removeClass('img-positioning');
                equipmentIcon.find('img[alt="equipment-clicked-icon"]').removeClass('img-positioning');
                container.empty();
                container.append(data['html']);
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.equipment-manage-done', function () {
        var recipe_id = -1;
        if ($('#recipe-id').val()) {
            var recipe_id = $('#recipe-id').val();
        }
        $.ajax({
            url: ('/recipes/step/equipment/manage-done'),
            type: 'post',
            data: {
                steps: $('.step-section-content').length,
                recipe_id: recipe_id
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                var index = 0;
                $('.equipment-block-container').each(function () {
                    $(this).empty();
                    $(this).append(data[index]['html']);
                    var it = 0;
                    $('.step-section-content').each(function () {
                        $(this).find('.equipment-block-container').find('.equipment-checkmark').each(function () {
                            var id = $(this).find('.equipment-checkmark-id').html();
                            if (equipment_checkboxes[it].find(function (element) { return element['name'] === id })) {
                                $(this).find('input').prop('checked', equipment_checkboxes[it].find(function (element) { return element['name'] === id }).value);
                            } else {
                                $(this).find('input').prop('checked', false);
                            }
                        });
                        it++;
                    });
                    updateEquipmentIconContent($(this));
                    if (!$(this).hasClass('hidden')) {
                        $(this).prev().addClass('clicked-version');
                        $(this).prev().find('.step-icon-text').addClass('clicked-text-version');
                        $(this).prev().find('img[alt="equipment-icon"]').addClass('hidden');
                        $(this).prev().find('img[alt="equipment-clicked-icon"]').removeClass('hidden');
                    }
                    index++;
                });
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.check-icon', function () {
        var recipe_id = -1;
        if ($('#recipe-id').val()) {
            var recipe_id = $('#recipe-id').val();
        }
        var parent = $(this).parents('.equipment-edit-row-editable');
        var equipmentId = parent.prev().find('.equipment-id').html();
        var equipmentTitle = parent.find('input').val();
        parent.prev().find('.equipment-edit-label').html(equipmentTitle);
        parent.find('.equipment-edit-input').val(equipmentTitle);
        $.ajax({
            url: ('/recipes/step/equipment/save-equipment'),
            type: 'post',
            data: {
                steps: $('.step-section-content').length,
                recipe_id: recipe_id,
                equipment_id: equipmentId,
                equipment_title: equipmentTitle
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                var index = 0;
                var stepId = parent.parents('.step-section-content').find('.step-id-number').html();
                parent.find('.remove-icon').trigger('click');
                $('.equipment-block-container').each(function () {
                    if ((index + 1) != stepId) {
                        $(this).empty();
                        $(this).append(data[index]['html']);
                        updateEquipmentIconContent($(this));
                        if (!$(this).hasClass('hidden')) {
                            $(this).prev().addClass('clicked-version');
                            $(this).prev().find('.step-icon-text').addClass('clicked-text-version');
                            $(this).prev().find('img[alt="equipment-icon"]').addClass('hidden');
                            $(this).prev().find('img[alt="equipment-clicked-icon"]').removeClass('hidden');
                        }
                    }
                    index++;
                });
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('keyup', '.equipment-edit-input', function (e) {
        var newTitle = $(this).val();
        var parent = $(this).parents('.equipment-edit-row-editable');
        if (e.which == 13) {
            parent.find('.check-icon').trigger('click');
        } else if (e.which == 27) {
            parent.find('.remove-icon').trigger('click');
        }
    });

    $('body').on('click', '.remove-icon', function () {
        var element1 = $(this).parents('.equipment-edit-row-editable');
        element1.find('.equipment-edit-input').focusout();
        element1.addClass('hidden');
        var element2 = element1.prev();
        element2.removeClass('hidden');
        if (element2.find('.equipment-title').html().trim() === "" && element2.find('.equipment-id').html() === "") {
            element1.remove();
            element2.remove();
        }
    });

    $('body').on('click', '.equipment-remove-icon', function () {
        var container = $(this).parents('.equipment-block');
        var id = $(this).closest('.equipment-edit-row').find('.equipment-id').html();
        var title = $(this).closest('.equipment-edit-row').find('.equipment-title').html();
        container.addClass('delete-action');
        container.find('.equipment-block-rectangle').addClass('delete-action');
        container.find('.equipment-block-row-1').addClass('hidden');
        container.find('.equipment-block-row-2').addClass('hidden');
        container.find('.equipment-delete-block').removeClass('hidden');
        container.find('.equipment-delete-block-id').html(id);
        container.find('.equipment-delete-block-title').html(title);
    });

    $('body').on('click', '.equipment-delete-button', function () {
        var recipe_id = -1;
        if ($('#recipe-id').val()) {
            var recipe_id = $('#recipe-id').val();
        }
        var container = $(this).parents('.equipment-block');
        var id = $(this).parents('.equipment-delete-block').find('.equipment-delete-block-id').html();
        $.ajax({
            url: ('/recipes/step/equipment/remove-equipment'),
            type: 'post',
            data: {
                steps: $('.step-section-content').length,
                recipe_id: recipe_id,
                equipment_id: id
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                var index = 0;
                var stepId = container.parents('.step-section-content').find('.step-id-number').html();
                container.find('.equipment-id').each(function () {
                    if ($(this).html() == id) {
                        var parent = $(this).closest('.equipment-edit-row');
                        parent.next().remove();
                        parent.remove();
                    }
                });
                $('.equipment-block-container').each(function () {
                    if ((index + 1) != stepId) {
                        $(this).empty();
                        $(this).append(data[index]['html']);
                        updateEquipmentIconContent($(this));
                        if (!$(this).hasClass('hidden')) {
                            $(this).prev().addClass('clicked-version');
                            $(this).prev().find('.step-icon-text').addClass('clicked-text-version');
                            $(this).prev().find('img[alt="equipment-icon"]').addClass('hidden');
                            $(this).prev().find('img[alt="equipment-clicked-icon"]').removeClass('hidden');
                        }
                    }
                    index++;
                });
                container.removeClass('delete-action');
                container.find('.equipment-block-rectangle').removeClass('delete-action');
                container.find('.equipment-block-row-1').removeClass('hidden');
                container.find('.equipment-block-row-2').removeClass('hidden');
                container.find('.equipment-delete-block').addClass('hidden');
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.equipment-cancel-button', function () {
        var container = $(this).parents('.equipment-block');
        container.removeClass('delete-action');
        container.find('.equipment-block-rectangle').removeClass('delete-action');
        container.find('.equipment-block-row-1').removeClass('hidden');
        container.find('.equipment-block-row-2').removeClass('hidden');
        container.find('.equipment-delete-block').addClass('hidden');
    });

    $('body').on('click', '.equipment-checkmark, .tags-checkmark', function () {
        if ($(this).find('input[type="checkbox"]').prop('checked')) {
            $(this).find('input[type="checkbox"]').val(1);
        } else {
            $(this).find('input[type="checkbox"]').val("");
        }
    });

    $('body').on('change clicked', '.equipment-checkmark', function () {
        equipment_checkboxes = getEquipmentCheckboxData();
    });

    $('body').on('click', 'input[type=checkbox][name="meal_filter"]', function () {
        if ($('input[type=checkbox][name="drink_filter"]').prop('checked')) {
            $('input[type=checkbox][name="drink_filter"]').prop('checked', false);
        } else if ($('input[type=checkbox][name="bundle_filter"]').prop('checked')) {
            $('input[type=checkbox][name="bundle_filter"]').prop('checked', false);
        }
    });

    $('body').on('click', 'input[type=checkbox][name="drink_filter"]', function () {
        if ($('input[type=checkbox][name="meal_filter"]').prop('checked')) {
            $('input[type=checkbox][name="meal_filter"]').prop('checked', false);
        } else if ($('input[type=checkbox][name="bundle_filter"]').prop('checked')) {
            $('input[type=checkbox][name="bundle_filter"]').prop('checked', false);
        }
    });

    $('body').on('click', 'input[type=checkbox][name="bundle_filter"]', function () {
        if ($('input[type=checkbox][name="meal_filter"]').prop('checked')) {
            $('input[type=checkbox][name="meal_filter"]').prop('checked', false);
        } else if ($('input[type=checkbox][name="drink_filter"]').prop('checked')) {
            $('input[type=checkbox][name="drink_filter"]').prop('checked', false);
        }
    });

    $('body').on('mouseover', '.tags-edit-row', function () {
        $(this).find('.tags-edit-icon').removeClass('hidden');
        $(this).find('.tags-remove-icon').removeClass('hidden');
    });

    $('body').on('mouseout', '.tags-edit-row', function () {
        $(this).find('.tags-edit-icon').addClass('hidden');
        $(this).find('.tags-remove-icon').addClass('hidden');
    });

    $('body').on('click', '.tags-edit-row-tmp1', function () {
        $('.tags-exit-icon').trigger('click');
        var element1 = $(this).parents('.tags-edit-row');
        element1.addClass('hidden');
        var element2 = element1.next();
        element2.removeClass('hidden');
        element2.find('.tags-edit-input').focus();
    });

    $('body').on('click', '.add-new-tag', function () {
        var element = $(this).closest('.tags-last-row');
        $.ajax({
            url: ('/recipes/tags/add'),
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                $('.tags-exit-icon').trigger('click');
                $(data['html']).insertAfter(element);
                var inputs = element.closest('.tags-block-row-2').find('.tags-edit-input');
                $(inputs[0]).focus();
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.manage-tags', function () {
        var container = $(this).closest('.tags-block-container');
        $.ajax({
            url: ('/recipes/tags/manage'),
            type: 'post',
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                container.empty();
                container.append(data['html']);
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.tags-manage-done', function () {
        var recipe_id = -1;
        var tag_ids = [];
        var tag_titles = [];
        var tag_status = [];
        var parentContainer = $(this).parents('.tags-block-container');
        var container = parentContainer.find('.tags-block-row-2');
        container.children('.tags-edit-row').each(function () {
            tag_ids.push($(this).find('.tags-id').html());
            tag_titles.push($(this).find('.tags-title').html());
            tag_status.push($(this).find('.tags-status').html());
        });
        if ($('#recipe-id').val()) {
            var recipe_id = $('#recipe-id').val();
        }
        $.ajax({
            url: ('/recipes/tags/manage-done'),
            type: 'post',
            data: {
                recipe_id: recipe_id,
                tag_ids: tag_ids,
                tag_titles: tag_titles,
                tag_status: tag_status
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                parentContainer.empty();
                parentContainer.append(data['html']);
                updateTagsBlockMessage();
                $('.recipe-tags-data').empty();
                $('.tags-checkmark').each(function () {
                    var checked = "";
                    var new_name = 'recipe_tags[' + $(this).find('.tags-checkmark-id').html() + ']';
                    if ($(this).find('input[type="checkbox"]').prop('checked')) {
                        var checked = "1";
                    }
                    $('.recipe-tags-data').append('<input type="hidden" name="' + new_name + '" value="' + checked + '"/>');
                });
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.tags-check-icon', function () {
        var container = $(this).closest('.tags-block-container');
        var parent = $(this).parents('.tags-edit-row-editable');
        var tagId = parent.prev().find('.tags-id').html();
        var tagTitle = parent.find('input').val();
        parent.prev().find('.tags-edit-label').html(tagTitle);
        parent.find('.tags-edit-input').val(tagTitle);
        $.ajax({
            url: ('/recipes/tags/save-tag'),
            type: 'post',
            data: {
                tag_id: tagId,
                tag_title: tagTitle
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                parent.find('.tags-exit-icon').trigger('click');
                container.empty();
                container.append(data['html']);
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('keyup', '.tags-edit-input', function (e) {
        var newTitle = $(this).val();
        var parent = $(this).parents('.tags-edit-row-editable');
        if (e.which == 13) {
            parent.find('.tags-check-icon').trigger('click');
        } else if (e.which == 27) {
            parent.find('.tags-exit-icon').trigger('click');
        }
    });

    $('body').on('click', '.tags-exit-icon', function () {
        var element1 = $(this).parents('.tags-edit-row-editable');
        element1.find('.tags-edit-input').focusout();
        element1.addClass('hidden');
        var element2 = element1.prev();
        element2.removeClass('hidden');
        if (element2.find('.tags-title').html().trim() === "" && element2.find('.tags-id').html() === "") {
            element1.remove();
            element2.remove();
        }
    });

    $('body').on('click', '.tags-remove-icon', function () {
        var container = $(this).parents('.tags-block');
        var id = $(this).closest('.tags-edit-row').find('.tags-id').html();
        var title = $(this).closest('.tags-edit-row').find('.tags-title').html();
        container.find('.tags-block-row-1').addClass('hidden');
        container.find('.tags-block-row-2').addClass('hidden');
        container.find('.tags-delete-block').removeClass('hidden');
        container.find('.tags-delete-block-id').html(id);
        container.find('.tags-delete-block-title').html(title);
    });

    $('body').on('click', '.tags-delete-button', function () {
        var container = $(this).parents('.tags-block');
        var id = $(this).parents('.tags-delete-block').find('.tags-delete-block-id').html();
        $.ajax({
            url: ('/recipes/tags/remove-tag'),
            type: 'post',
            data: {
                tag_id: id
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                container.find('.tags-id').each(function () {
                    if ($(this).html() == id) {
                        var parent = $(this).closest('.tags-edit-row');
                        parent.next().remove();
                        parent.remove();
                    }
                });
                container.find('.tags-block-row-1').removeClass('hidden');
                container.find('.tags-block-row-2').removeClass('hidden');
                container.find('.tags-delete-block').addClass('hidden');
                updateTagsBlockMessage();
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.tags-cancel-button', function () {
        var container = $(this).parents('.tags-block');
        container.find('.tags-block-row-1').removeClass('hidden');
        container.find('.tags-block-row-2').removeClass('hidden');
        container.find('.tags-delete-block').addClass('hidden');
    });

    function base64ToBlob(base64, mime) {
        mime = mime || '';
        var sliceSize = 1024;
        var byteChars = window.atob(base64);
        var byteArrays = [];
        for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            var slice = byteChars.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: mime });
    }

    var cr, cr_img = "", img_w = 320, img_h = 320, isCrop = 0;
    $('body').on('click', '.step-empty-image-border', function () {


        var currentStepAddingImage = $(this).attr("id");

        var container = $(this).parents('.step-section-content');
        // $('.current-recipe-step-id').html(container.find('.step-id-number').html());

        $('.current-recipe-step-id').html(currentStepAddingImage);
        $('.pseudomodal').css('display', 'block');
    });

    $('body').on('change', '#fileselect', function (e) {
        var files = e.target.files || e.dataTransfer.files;
        if (files[0] && files[0].type.match("image.*")) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#upload').addClass('hidden');
                $('#crop').removeClass('hidden');
                if (cr_img == "") {
                    cr_img = e.target.result;
                    cropInit();
                } else {
                    cr_img = e.target.result;
                    bindCropImg();
                }
                $("#crop").fadeIn(300);
            };
            reader.readAsDataURL(files[0]);
        }
    });

    $('body').on('dragover dragleave drop', '#filedrag', function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.type == 'dragover') {
            $(this).addClass('hover');
        } else {
            $(this).removeClass('hover');
        }
    });

    function cropInit() {
        cr = $('#croppie').croppie({
            viewport: {
                width: img_w,
                height: img_h
            },
            boundary: {
                width: img_w,
                height: img_h
            },
            mouseWheelZoom: false,
            enableOrientation: true
        });

        if ($('.pseudomodal-title').html() == "Recipe step image") {
            $(".cr-slider-wrap").append('<button id="cr-rotate">↻ Rotate</button>');
        } else {
            $(".cr-slider-wrap").append('<button id="cr-rotate">↻ Drehen</button>');
        }

        bindCropImg();
    }

    function bindCropImg() {
        cr.croppie("bind", {
            url: cr_img
        });
    }

    $('body').on('click', '#cr-rotate', function () {
        cr.croppie("rotate", -90);
    });

    $('body').on('click', '#prev', function () {
        if ($('#upload').hasClass('hidden')) {
            $('#upload').fadeIn(300).siblings().addClass('hidden');
            $('#upload').removeClass('hidden');
            $('#fileselect').val("");
            isCrop = 0;
        }
    });

    $('body').on('click', '#next', function () {
        if (!isCrop) {
            isCrop = 1;
            cr.croppie("result", {
                type: "canvas",
                size: { width: img_w, height: img_h },
                format: "jpeg",
                quality: 1
            }).then(function (resp) {
                $('#crop').addClass('hidden');
                $('#result').removeClass('hidden');
                $("#result").find("img").attr("src", resp);
                $("#result").fadeIn(300);
            });
        }
    });

    $('body').on('click', '.close-recipe-step-image-modal', function () {
        $('.pseudomodal-content').animate(
            { top: '-=1000px' }, 600, function () {
                $('.pseudomodal').css('display', 'none');
                $('.pseudomodal-content').css('top', '0');
                $('#upload').removeClass('hidden');
                $('#crop').addClass('hidden');
                $('#result').addClass('hidden');
                $('#fileselect').val("");
                cr_img = "";
                isCrop = 0;
                if (cr) {
                    cr.croppie('destroy');
                }
            });
    });

    $('.recipe-form-page').mouseup(function (e) {
        if ($('.pseudomodal').css('display') == 'block') {
            var container = $('.pseudomodal-content')
            if (container && !container.is(e.target) && container.has(e.target).length === 0) {
                $('.close-recipe-step-image-modal').trigger('click');
            }
        }
    });

    $('body').on('click', '#confirm', function () {
        var image = $(this).prev().attr('src');
        var base64ImageContent = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
        var mime = image.split(',')[0].split(';')[0].split(':')[1];
        var blob = base64ToBlob(base64ImageContent, mime);
        var formData = new FormData();
        formData.append('image', blob);

        $.ajax({
            url: ('/recipes/steps/upload-new-step-image'),
            type: 'post',
            cache: false,
            contentType: false,
            processData: false,
            data: formData,
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                $('.pseudomodal-header').find('.close').trigger('click');
                //var stepId = $('.pseudomodal-header').find('.current-recipe-step-id').html();

                var currentStepAddingImage = $('.pseudomodal-header').find('.current-recipe-step-id').html();


                $("#" + currentStepAddingImage).addClass('hidden');


                var tempImgeBox = currentStepAddingImage.split("-");

                var idSufix = tempImgeBox[1] + "-" + tempImgeBox[2];

                var stepImgBorder = "#stepImgBorder-" + idSufix;
                $(stepImgBorder).removeClass('hidden');

                var stepImgId = "#stepImageId-" + idSufix;
                $(stepImgId).val(data['imgs'][0]['id']);


                var stepImgStatus = "#stepImageStatus-" + idSufix;
                $(stepImgStatus).val('ACTIVE');

                var steImgFile = "#stepImgFile-" + idSufix;
                $(steImgFile).attr('src', data['imgs'][0]['thumb']);


                // $('.step-section-content').each(function () {
                //     if (stepId == $(this).find('.step-id-number').html()) {
                //         // var container = $(this);
                //         // container.find('.step-empty-image-border').addClass('hidden');
                //         // var imageContainer = container.find('.step-image-border');
                //         // imageContainer.removeClass('hidden');
                //         // imageContainer.find('.step-image-id').val(data['imgs'][0]['id']);
                //         // imageContainer.find('.step-image-status').val('ACTIVE');
                //         // imageContainer.find('img').attr('src', data['imgs'][0]['thumb']);


                //         $("#" + currentStepAddingImage).addClass('hidden');


                //         var tempImgeBox = currentStepAddingImage.split("-");

                //         var idSufix = + tempImgeBox[1] + "-" + tempImgeBox[2];

                //         var stepImgBorder = "#stepImgBorder-" + idSufix;
                //         $(stepImgBorder).removeClass('hidden');

                //         var stepImgId = "#stepImageId-" + idSufix;
                //         $(stepImgId).val(data['imgs'][0]['id']);


                //         var stepImgStatus = "#stepImageStatus" + idSufix;
                //         $(stepImgStatus).val('ACTIVE');

                //         var steImgFile = "#stepImgFile" + idSufix;
                //         $(steImgFile).attr('src', data['imgs'][0]['thumb']);


                //     }
                // });
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.step-image-border', function () {

        var current_lang = getCurrentLanguage();
        var languages = getAvailableLanguages();

        var element_id = $(this).find('.step-image-id').attr("id");
        var index = element_id.split("-");

        index = index[2];

        var images_id = [];
        var containers = [];

    

        languages.forEach(element => {

            // var imageId = $(this).find('.step-image-id').val();
            // var container = $(this).parents('.step-section-content');

            var imageId = $("#stepImageId-" + element + "-" + index).val();
           
            var container = $("#stepImageId-" + element + "-" + index).parents('.step-section-content');

            if (imageId != "")
                images_id.push(imageId);

            if (container.length > 0)
                containers.push(container)
        });
        

        $.ajax({
            url: ('/recipes/steps/remove-step-image'),
            type: 'post',
            data: {
                image_id: images_id
            },
            success: function (data) {
 
                containers.forEach(element => {
                    element.find('.step-empty-image-border').removeClass('hidden');
                    var imageContainer = element.find('.step-image-border');
                    imageContainer.addClass('hidden');
                    imageContainer.find('.step-image-id').val("");
                    imageContainer.find('.step-image-status').val('NOT-ACTIVE');
                    imageContainer.find('img').attr('src', "");
                    $('#fileselect').val("");
                    cr_img = "";
                    isCrop = 0;
                    if (cr) {
                        cr.croppie('destroy');
                    }
                });
             
                // container.find('.step-empty-image-border').removeClass('hidden');
                // var imageContainer = container.find('.step-image-border');
                // imageContainer.addClass('hidden');
                // imageContainer.find('.step-image-id').val("");
                // imageContainer.find('.step-image-status').val('NOT-ACTIVE');
                // imageContainer.find('img').attr('src', "");
                // $('#fileselect').val("");
                // cr_img = "";
                // isCrop = 0;
                // if (cr) {
                //     cr.croppie('destroy');
                // }
            }
        });

    });

    $('body').on('click', '.recipe-groups-search-activation', function () {
        $(this).addClass('hidden');
        $('.recipe-groups-search-area').removeClass('hidden')
            .find('input').focus()
    });

    $('body').on('click', '.rg-search-exit-icon', function () {
        $('.parent-cat').removeClass('hidden');
        $('.recipe-groups-search-input').val('');
        $('.recipe-groups-search-area').addClass('hidden');
        $('.recipe-groups-search-activation').removeClass('hidden');
    });

    $('body').on('click', '.rg-search-check-icon', function () {
        var string = $('.recipe-groups-search-input').val();
        $.ajax({
            url: ('/recipes/groups/search'),
            type: 'post',
            data: {
                string: string
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                $('.parent-cat').addClass('hidden');
                $.each(data['groups'], function (key, value) {
                    var catIdentifier = 'categories_' + value;
                    $('.parent-cat').each(function () {
                        var parentCat = $(this);
                        if (parentCat.attr('id') == catIdentifier) {
                            parentCat.removeClass('hidden');
                        } else {
                            parentCat.find('.subwell-content').children().each(function () {
                                if ($(this).attr('id') == catIdentifier) {
                                    parentCat.removeClass('hidden');
                                }
                            });
                        }
                    })
                });
            },
            complete: function () {
                htmlLoading(false);
            }
        });
    });

    function searchGroupsByTitle(string) {
        var matches = [];
        $('.parent-cat').each(function () {
            if ($(this).find('.rightbar-block-content-label').html().toLowerCase().includes(string.toLowerCase())) {
                matches.push($(this).attr('id'));
            } else {
                $(this).find('.subc-element').each(function () {
                    if ($(this).find('.rightbar-block-content-label').html().toLowerCase().includes(string.toLowerCase())) {
                        matches.push($(this).attr('id'));
                    }
                });
            }
        });
        return matches;
    }

    $('body').on('keyup', '.recipe-groups-search-input', function (e) {
        if (e.which == 27) {
            $(this).parents('.recipe-groups-search-area').find('.rg-search-exit-icon').trigger('click');
        } else {
            var matches = searchGroupsByTitle($('.recipe-groups-search-input').val());
            $('.parent-cat').addClass('hidden');
            $.each(matches, function (key, value) {
                $('.parent-cat').each(function () {
                    var parentCat = $(this);
                    if (parentCat.attr('id') == value) {
                        parentCat.removeClass('hidden');
                    } else {
                        parentCat.find('.subwell-content').children().each(function () {
                            if ($(this).attr('id') == value) {
                                parentCat.removeClass('hidden');
                            }
                        });
                    }
                })
            });
        }
    });

    $('#middle-content-block.print-page-overlay').on('scroll', function () {
        var sticky = $('.print-page-header').offsetTop;
        if ($(window).scrollTop() > sticky) {
            $('.print-page-header').addClass('sticky-header-effect');
        } else {
            $('.print-page-header').removeClass('sticky-header-effect');
        }
    });

    if ($('main').hasClass('middle-content-positioning-for-print-overlay')) {
        $('.one-print-preview-page').each(function () {
            if (!$(this).hasClass('skip-front-page')) {
                printPageSpaceOrganisation($(this));
            }
            scalePrintPageContent($(this));
        });
        pageIndexCorrection();
    }

    function scalePrintPageContent(page) {
        var defaultA4Width = 1090;
        var a4Ratio = 1.413;
        if (page.hasClass('skip-front-page')) { var width = page.find('.print-page-overlay').width() - 40; }
        else { var width = page.find('.copied-preview').width() - 40; }
        page.find('.print-page-overlay').css('min-height',width * a4Ratio);
        page.find('.print-page-preview').css('min-height',width * a4Ratio);
        page.find('.print-page-content').css('transform', 'scale(' + width / defaultA4Width + ')');
        page.find('.print-page-content').css('transform-origin', '0 0');
        page.find('.print-page-content').css('height', '100%');
    }

    $(window).on('resize', function () {
        if ($('main').hasClass('middle-content-positioning-for-print-overlay')) {
            $('.one-print-preview-page').each(function () {
                scalePrintPageContent($(this));
            });
        }
    });

    function removeNextElements(page, part) {
        var className = "";
        if (part == "left-part") { className = "print-page-main-data-left-part"; }
        else { className = "print-page-main-data-right-part"; }
        var pageBreak = page.find('.' + className).find('.page-break');
        var tmp = pageBreak;
        while (!(tmp.hasClass(className))) {
            tmp.nextAll().remove();
            tmp = tmp.parent();
        }
        if (pageBreak.prev().hasClass('print-page-block-content-underline')) {
            pageBreak.prev().remove();
        }
        pageBreak.remove();
    }

    function removePreviousElements(page, part) {
        var className = "";
        if (part == "left-part") { className = "print-page-main-data-left-part"; }
        else { className = "print-page-main-data-right-part"; }
        var pageBreak = page.find('.' + className).find('.page-break');
        var tmp = pageBreak;
        while (!(tmp.hasClass(className))) {
            tmp.prevAll().remove();
            tmp = tmp.parent();
        }
        if (pageBreak.prev().hasClass('print-page-block-content-underline')) {
            pageBreak.prev().remove();
        }
    }

    function subrecipePageBreakIdentification(element, currentAvailableHeight) {

        var detectedPageBreak = null;
        var breakLoop = false;

        var heightTmp = element.outerHeight(true) - element.height();
        element.children().each(function () {
            if (breakLoop) {
                return false;
            }
            var child = $(this);
            if (child.outerHeight(true) + heightTmp > currentAvailableHeight) {
                if (child.outerHeight(true) + heightTmp < element.outerHeight(true) * 0.3) {
                    detectedPageBreak = element;
                    return false;
                } else {
                    if (child.hasClass('print-page-block-ingredients-el')) {
                        detectedPageBreak = child;
                        return false;
                    } else if (child.hasClass('print-page-block-content-underline') || child.hasClass('print-page-block-content-underline-2')) {
                        detectedPageBreak = child.prev();
                        return false;
                    } else if (child.hasClass('subrecipe-row')) {
                        detectedPageBreak = subrecipePageBreakIdentification(child, currentAvailableHeight - heightTmp);
                        return false;
                    } else if (child.hasClass('group-row')) {
                        var heightTmpTmp = child.outerHeight(true) - child.height() + child.find('.group-sub-title').outerHeight(true);
                        child.children().each(function () {
                            var grandChild = $(this);
                            if (heightTmp + heightTmpTmp + grandChild.outerHeight(true) > currentAvailableHeight) {
                                if (heightTmpTmp + grandChild.outerHeight(true) < child.outerHeight(true) * 0.3 || heightTmpTmp + grandChild.outerHeight(true) == heightTmpTmp || grandChild.hasClass('group-sub-title') || grandChild.hasClass('group-sub-weight-total')) {
                                    breakLoop = true;
                                    detectedPageBreak = child.prev();
                                    detectedPageBreak.addClass('page-break');
                                    return false;
                                } else {
                                    if (grandChild.hasClass('print-page-block-ingredients-el')) {
                                        breakLoop = true;
                                        detectedPageBreak = grandChild;
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    } else if (grandChild.hasClass('print-page-block-content-underline') || grandChild.hasClass('print-page-block-content-underline-2')) {
                                        breakLoop = true;
                                        detectedPageBreak = grandChild.prev();
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    } else if(grandChild.hasClass('print-page-block-comments-el')) {
                                        breakLoop = true;
                                        detectedPageBreak = grandChild;
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    } else {
                                        breakLoop = true;
                                        detectedPageBreak = subrecipePageBreakIdentification(grandChild, currentAvailableHeight - heightTmp - heightTmpTmp);
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    }
                                }
                            } else {
                                heightTmpTmp = heightTmpTmp + grandChild.outerHeight(true);
                            }
                        });
                    }
                }
            } else {
                heightTmp = heightTmp + child.outerHeight(true);          
            }
        });

        return detectedPageBreak;
    }

    function findPageBreak(element, currentAvailableHeight) {

        var breakLoop = false;
        var detectedPageBreak = null;

        var heightTmp = element.outerHeight(true) - element.height();
        if (element.attr('name') != 'sidebar_information') {
            if (element.attr('name') == 'comments') {
                if(!isNaN(heightTmp)) {
                heightTmp = heightTmp + element.find('.print-page-block-title-underline').outerHeight(true);
                }
            } else {
                var tmpVal = element.find('.print-page-block-lp-tb').outerHeight(true) + element.find('.print-page-block-title-underline').outerHeight(true);
                if (tmpVal) { 
                    heightTmp = heightTmp + tmpVal;
                 }
            }
        }

        if (element.find('.print-page-block-main-data').children().length == 0) {
            detectedPageBreak = element;
            detectedPageBreak.addClass('page-break');
            return detectedPageBreak;
        }
           
        element.find('.print-page-block-main-data').children().each(function () {
            if (breakLoop) {
                return false;
            }
            var child = $(this);
            if (child.outerHeight(true) + heightTmp > currentAvailableHeight) {
              if (child.hasClass('print-page-block-ingredients-el') || child.hasClass('print-page-block-steps-el') || child.hasClass('print-page-block-sales-el') || child.hasClass('print-page-block-comments-el')) {
                        detectedPageBreak = child;
                        detectedPageBreak.addClass('page-break');
                        return false;
                    } else if (child.hasClass('print-page-block-content-underline') || child.hasClass('print-page-block-content-underline-2')) {
                        detectedPageBreak = child.prev();
                        detectedPageBreak.addClass('page-break');
                        return false;
                    } else if (child.hasClass('group-row')) {
                        var heightTmpTmp = child.outerHeight(true) - child.height() + child.find('.group-sub-title').outerHeight(true);
                        child.children().each(function () {
                            var grandChild = $(this);
                            if (heightTmp + heightTmpTmp + grandChild.outerHeight(true) > currentAvailableHeight) {
                                if (heightTmpTmp + grandChild.outerHeight(true) < child.outerHeight(true) * 0.3 || heightTmpTmp + grandChild.outerHeight(true) == heightTmpTmp || grandChild.hasClass('group-sub-title') || grandChild.hasClass('group-sub-weight-total')) {            
                                    breakLoop = true;
                                    detectedPageBreak = child.prev();
                                    detectedPageBreak.addClass('page-break');
                                    return false;
                                } else {                   
                                    if (grandChild.hasClass('print-page-block-ingredients-el')) {
                                        breakLoop = true;
                                        detectedPageBreak = grandChild;
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    } else if (grandChild.hasClass('print-page-block-content-underline') || grandChild.hasClass('print-page-block-content-underline-2')) {
                                        breakLoop = true;
                                        detectedPageBreak = grandChild.prev();
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    } else if(grandChild.hasClass('print-page-block-comments-el')) {
                                        breakLoop = true;
                                        detectedPageBreak = grandChild;
                                        detectedPageBreak.addClass('page-break');
                                        return false;
                                    } 
                                }
                            } else {
                                heightTmpTmp = heightTmpTmp + grandChild.outerHeight(true);
                            }
                        });
                    } else if (child.hasClass('print-page-sidebar-content-wrapper')) {  
                        child.children().each(function () {            
                            var grandChild = $(this);
                            heightTmp = heightTmp + grandChild.outerHeight(true) - grandChild.height();
                            if (grandChild.outerHeight(true) + heightTmp > currentAvailableHeight) {
                                if (grandChild.hasClass('print-page-block-content-underline')) {
                                    breakLoop = true;
                                    detectedPageBreak = grandChild.prev();
                                    detectedPageBreak.addClass('page-break');
                                    return false;
                                } else if (grandChild.hasClass('print-page-block-sidebar-el')) {
                                    breakLoop = true;
                                    detectedPageBreak = grandChild;
                                    detectedPageBreak.addClass('page-break');
                                    return false;
                                }
                            } else {
                                heightTmp = heightTmp + grandChild.height();                     
                            }
                        });
                    } else if (child.hasClass('subrecipe-row')) {
                        detectedPageBreak = subrecipePageBreakIdentification(child, currentAvailableHeight - heightTmp);
                        detectedPageBreak.addClass('page-break');
                        return false;
                    } 
            } else {
                heightTmp = heightTmp + child.outerHeight(true);              
            }
        });
        return detectedPageBreak;
    }

    function printPageSpaceOrganisation(page) {
        page.find('.original-preview').removeClass('hidden');
        page.find('.copied-preview').remove();

        var allRightBlocks = ['sidebar_information'];
        var allLeftBlocks = ['ingredients', 'preparation','comments','sales'];
        var rightBlocks = [], leftBlocks = [];
        $('.print-view-checkbox-label').each(function () {
            if ($(this).find('input').prop('checked')) {
                var name = $(this).find('input').attr('name');
                if (allRightBlocks.includes(name)) {
                    rightBlocks.push(name);
                } else if (allLeftBlocks.includes(name)) {
                    leftBlocks.push(name);
                }
            }
        });
        rightBlocks = rightBlocks.reverse();

        var currentPageIndex = 1;
        var leftBreak = null, rightBreak = null;
        var leftBreakFound = false, rightBreakFound = false, firstPage = true, blankPaper = false;
        if (leftBlocks.length == 0 && rightBlocks.length == 0) {
            // blankPaper = true;
        }

        var headerHeight = $(page.find('.print-page-header')).height();
        var footerHeight = $(page.find('.print-page-footer')).height();
        var parentHeight = $(page.find('.print-page-content')[currentPageIndex - 1]).height();
        var remainingHeightPercent = ((parentHeight - headerHeight - footerHeight - 80) / parentHeight) * 100;
        $(page.find('.print-page-content')[currentPageIndex - 1]).find('.print-page-main-data').css('height', remainingHeightPercent + '%');

        if (page.find('div[name="comments"]').outerHeight(true) > page.find('.print-page-main-data-left-part').outerHeight(true)) {
            page.find('div[name="comments"]').css('max-height', '500px');
            page.find('div[name="comments"]').css('overflow', 'hidden');
        }

        if (page.find('p[name="description"]').outerHeight(true) > 90) {
            page.find('p[name="description"]').css('max-height', '340px');
            page.find('p[name="description"]').css('line-height', '26px');
        }

        while (leftBlocks.length > 0 || rightBlocks.length > 0) {
            if (firstPage) {
                page.find('.original-preview').removeClass('hidden');
                var copy = page.find('.original-preview').clone();
                page.find('.original-preview').addClass('hidden');
                page.append('<div class="print-page-overlay copied-preview print-height">' + copy.html() + '</div>');
                var currentAvailableHeightForLeftPart = $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data').height();
                var currentAvailableHeightForRightPart = currentAvailableHeightForLeftPart;
                firstPage = false;
            } else {
                if (leftBreakFound || rightBreakFound) {
                    var copy = $(page.find('.copied-preview')[page.find('.copied-preview').length - 1]).clone();
                    page.append('<div class="print-page-overlay copied-preview print-height">' + copy.html() + '</div>');
                    currentPageIndex++;

                    if ($('input[name="include_header"]').prop('checked')) {
                        var imgObj = $('.print-page-header-img');
                        $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-header').removeClass('hidden');
                    } else {
                        $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-header').addClass('hidden');
                        $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data.first').removeClass('first');
                        var remainingHeightPercentWithoutHeader = ((parentHeight - footerHeight - 80) / parentHeight) * 100;
                        $(page.find('.print-page-content')[currentPageIndex - 1]).find('.print-page-main-data').css('height', remainingHeightPercentWithoutHeader + '%');
                    }

                    if (leftBreakFound && !rightBreakFound) {
                        $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data-right-part').remove();
                    } else if (rightBreakFound && !leftBreakFound) {
                        $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data-left-part').remove();
                        $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data').css('justify-content', 'end');
                    }

                    if (leftBreakFound) { currentAvailableHeightForLeftPart = $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data').height(); }
                    if (rightBreakFound) { currentAvailableHeightForRightPart = $(page.find('.copied-preview')[currentPageIndex - 1]).find('.print-page-main-data').height(); }

                    leftBreakFound = false;
                    rightBreakFound = false;
                    if (leftBreak) {
                        var currentPage = $(page.find('.copied-preview')[currentPageIndex - 1]);
                        var previousPage = currentPage.prev();
                        removeNextElements(previousPage, "left-part");
                        removePreviousElements(currentPage, "left-part");
                        currentPage.find('.print-page-main-data-left-part').find('.page-break').removeClass('page-break');
                        leftBreak = null;
                    }
                    if (rightBreak) {
                        var currentPage = $(page.find('.copied-preview')[currentPageIndex - 1]);
                        var previousPage = currentPage.prev();
                        removeNextElements(previousPage, "right-part");
                        removePreviousElements(currentPage, "right-part");
                        currentPage.find('.print-page-main-data-right-part').find('.page-break').removeClass('page-break');
                        rightBreak = null;
                    }
                }
            }

            while (!leftBreakFound && leftBlocks.length > 0) {
                var element = $(page.find('.copied-preview')[currentPageIndex - 1]).find('div[name="' + leftBlocks[0] + '"]');
                if (element.outerHeight(true) <= currentAvailableHeightForLeftPart) {
                    currentAvailableHeightForLeftPart = currentAvailableHeightForLeftPart - element.height();
                    leftBlocks.shift();
                } else {
                    leftBreak = findPageBreak(element, currentAvailableHeightForLeftPart);
                    if (leftBreak) { leftBreakFound = true; }
                    else { leftBreakFound = false; }
                }
            }

            while (!rightBreakFound && rightBlocks.length > 0) {
                var element = $(page.find('.copied-preview')[currentPageIndex - 1]).find('div[name="' + rightBlocks[0] + '"]');
                if (element.outerHeight(true) <= currentAvailableHeightForRightPart) {
                    currentAvailableHeightForRightPart = currentAvailableHeightForRightPart - element.outerHeight(true);
                    rightBlocks.shift();
                } else {
                    rightBreak = findPageBreak(element, currentAvailableHeightForRightPart);
                    if (rightBreak) { rightBreakFound = true; }
                    else { rightBreakFound = false; }
                }
            }
        }

        if ($('input[name="sidebar_information"]').prop('checked')) {
            page.find('.print-page-sidebar-content-wrapper').children().removeClass('sidebar-first-block');
            page.find('.print-page-sidebar-content-wrapper').each(function () {
                var it = 0;
                $(this).children().each(function () {
                    if (it == 0) {
                        if (!$(this).hasClass('hidden')) {
                            $(this).addClass('sidebar-first-block');
                            it++;
                        }
                    } else { return false; }
                });
            });
        }
    }

    function pageIndexCorrection() {
        var numberOfPages = 0, currentPageNumber = 1;
        var pageStr = $('.print-btn-text').html() == 'Drucken' ? 'Seite' : 'Page';
        var lastPrintPagePreview = null; // To keep track of the last .print-page-preview element
        var firstPageSet = false;

        $('.one-print-preview-page').each(function () {
            if (!$(this).hasClass('skip-front-page')) {
                var previews = $(this).find('.print-page-preview');
                numberOfPages = numberOfPages + $(this).find('.copied-preview').length;

                if (!firstPageSet) {
                    $(this).find('.print-page-main-data:eq(1)').addClass('first');
                    firstPageSet = true;
                }

                lastPrintPagePreview = previews.last(); // Update the last .print-page-preview element
            }
        });
        $('.one-print-preview-page').each(function () {
            if (!$(this).hasClass('skip-front-page')) {
                $(this).find('.copied-preview').each(function () {
                    $(this).find('.footer-text').html(pageStr + ' ' + currentPageNumber + '/' + numberOfPages);
                    currentPageNumber++;
                });
            }
        });
    
        if (lastPrintPagePreview) {
            lastPrintPagePreview.addClass('last');
        }
    }

    $('body').on('change clicked', '.print-option-parentcheckbox', function (e) {
        var parent = $(this);
        parent.parent().children().each(function () {
            var child = $(this);
            if (child.hasClass("subcheckbox-style")) {
                if (child.find('input').prop('checked') != parent.find('input').prop('checked')) {
                    child.find('input').prop('checked', parent.find('input').prop('checked'));
                    $.ajax({
                        url: '/recipes/options/print',
                        type: 'post',
                        data: {
                            k: child.find('input').attr('name'),
                            v: child.find('input').prop('checked')
                        }
                    });
                }
            }
        });
        if (parent.find('input').attr('name') == 'sidebar_information') {
            $('.print-page-sidebar-content-wrapper').each(function () {
                $(this).children().each(function () {
                    if (parent.find('input').prop('checked')) {
                        $(this).removeClass('hidden');
                    } else {
                        $(this).addClass('hidden');
                    }
                });
            });
        } else if (parent.find('input').attr('name') == 'sales') {
            if (parent.find('input').prop('checked')) {
                $('div[name="sales"]').removeClass('hidden');
                $('div[name="contribution_margin"]').removeClass('hidden');
            } else {
                $('div[name="sales"]').addClass('hidden');
                $('div[name="contribution_margin"]').addClass('hidden');
            }
        } else if (parent.find('input').attr('name') == 'ingredients') {
            if (parent.find('input').prop('checked')) {
                if ($('div[name="show_subrecipes"]').length > 0) {
                    $('div[name="ingredients"]').removeClass('hidden');
                    $('div[name="show_subrecipes"]').removeClass('hidden');
                } else {
                    $('input[type="checkbox"]').attr('disabled', 'disabled');
                    setTimeout(function () { location.reload(); }, 100);
                }
            } else {
                $('div[name="ingredients"]').addClass('hidden');
                $('div[name="show_subrecipes"]').addClass('hidden');
            }
        } else if (parent.find('input').attr('name') == 'preparation') {
            if (parent.find('input').prop('checked')) {
                $('div[name="preparation"]').removeClass('hidden');
                $('div[name="show_step_images"]').removeClass('hidden');
                $('div[name="show_step_images"]').next().removeClass('step-element-data-full-width');
            } else {
                $('div[name="preparation"]').addClass('hidden');
                $('div[name="show_step_images"]').addClass('hidden');
                $('div[name="show_step_images"]').next().addClass('step-element-data-full-width');
            }
        }
    });

    $('body').on('change clicked', '.subcheckbox-style', function (e) {
        var child = $(this);
        var parent = child.parent().find('.print-option-parentcheckbox').find('input');
        if (child.find('input').prop('checked')) {
            if (!parent.prop('checked')) {
                parent.prop('checked', true);
                $('.original-preview').find('[name="' + parent.attr('name') + '"]').removeClass('hidden');
                if (child.find('input').attr('name') == 'contribution_margin') {
                    $('.original-preview').find('[name="contribution_margin"]').removeClass('hidden');
                } else if (child.find('input').attr('name') == 'show_subrecipes') {
                    if ($('div[name="show_subrecipes"]').length > 0) {
                        $('div[name="ingredients"]').removeClass('hidden');
                        $('div[name="show_subrecipes"]').removeClass('hidden');
                    } else {
                        $('input[type="checkbox"]').attr('disabled', 'disabled');
                        setTimeout(function () { location.reload(); }, 100);
                    }
                } else if (child.find('input').attr('name') == 'show_step_images') {
                    $('.original-preview').find('[name="show_step_images"]').removeClass('hidden');
                    $('.original-preview').find('[name="show_step_images"]').next().removeClass('step-element-data-full-width');
                }
                $.ajax({
                    url: '/recipes/options/print',
                    type: 'post',
                    data: {
                        k: parent.attr('name'),
                        v: parent.prop('checked')
                    }
                });
            } else {
                if (child.find('input').attr('name') == 'contribution_margin') {
                    $('.original-preview').find('[name="contribution_margin"]').removeClass('hidden');
                } else if (child.find('input').attr('name') == 'show_subrecipes') {
                    if ($('div[name="show_subrecipes"]').length > 0) {
                        $('div[name="ingredients"]').removeClass('hidden');
                        $('div[name="show_subrecipes"]').removeClass('hidden');
                    } else {
                        $('input[type="checkbox"]').attr('disabled', 'disabled');
                        setTimeout(function () { location.reload(); }, 100);
                    }
                } else if (child.find('input').attr('name') == 'show_step_images') {
                    $('.original-preview').find('[name="show_step_images"]').removeClass('hidden');
                    $('.original-preview').find('[name="show_step_images"]').next().removeClass('step-element-data-full-width');
                }
            }
        } else {
            if (!['contribution_margin', 'show_subrecipes', 'show_step_images'].includes($(this).find('input').attr('name'))) {
                var counter = 0;
                child.parent().children().each(function () {
                    var el = $(this);
                    if (el.hasClass("subcheckbox-style")) {
                        if (el.find('input').prop('checked')) {
                            counter++;
                        }
                    }
                });
                if (counter == 0) {
                    if (parent.prop('checked')) {
                        parent.prop('checked', false);
                        $.ajax({
                            url: '/recipes/options/print',
                            type: 'post',
                            data: {
                                k: parent.attr('name'),
                                v: parent.prop('checked')
                            }
                        });
                        $('.original-preview').find('[name="sidebar_information"]').addClass('hidden');
                    }
                }
            } else {
                if ($(this).find('input').attr('name') == 'contribution_margin') {
                    if ($(this).find('input').prop('checked')) {
                        $('.original-preview').find('[name="contribution_margin"]').removeClass('hidden');
                    } else {
                        $('.original-preview').find('[name="contribution_margin"]').addClass('hidden');
                    }
                } else if ($(this).find('input').attr('name') == 'show_step_images') {
                    if ($(this).find('input').prop('checked')) {
                        $('.original-preview').find('[name="show_step_images"]').removeClass('hidden');
                        $('.original-preview').find('[name="show_step_images"]').next().removeClass('step-element-data-full-width');
                    } else {
                        $('.original-preview').find('[name="show_step_images"]').addClass('hidden');
                        $('.original-preview').find('[name="show_step_images"]').next().addClass('step-element-data-full-width');
                    }
                } else {
                    if ($(this).find('input').prop('checked')) {
                        if ($('div[name="show_subrecipes"]').length > 0) {
                            $('div[name="ingredients"]').removeClass('hidden');
                            $('div[name="show_subrecipes"]').removeClass('hidden');
                        } else {
                            $('input[type="checkbox"]').attr('disabled', 'disabled');
                            setTimeout(function () { location.reload(); }, 100);
                        }
                    } else {
                        $('.original-preview').find('[name="show_subrecipes"]').addClass('hidden');
                    }
                }
            }
        }
    });

    $('body').on('change clicked', '.print-view-checkbox-label', function () {
        var name = $(this).find('input[type="checkbox"]').attr('name');
        if (name == 'print_in_color') {
            var obj = $('.print-page-content');
            if ($(this).find('input[type="checkbox"]').prop('checked')) {
                obj.removeClass('black-and-white');
            } else {
                obj.addClass('black-and-white');
            }
        } else if (name == 'include_header') {
            $('.one-print-preview-page').each(function () {
                if (!$(this).hasClass('skip-front-page')) {
                    printPageSpaceOrganisation($(this));
                }
            });
            pageIndexCorrection();
        } else if(name == 'reduced_spacing') {
            
              var obj = $('.print-page-block-ingredients-el');
              var obj2 = $('.print-page-block-content-underline-2');
              if ($(this).find('input[type="checkbox"]').prop('checked')) {
                obj.addClass('remove-padding');
               obj2.addClass('remove-border');
            } else {
                obj.removeClass('remove-padding');
                obj2.removeClass('remove-border');
              
            }
        }
        else {
            if (name != 'sales' && name != 'contribution_margin' && name != 'ingredients' && name != 'show_subrecipes' && name != 'preparation' && name != 'show_step_images') {
                var sidebarBlocks = ['connection', 'recipe_type', 'group', 'location', 'synchronisation', 'freshness', 'availability', 'tags', 'allergens', 'additives', 'nutritional_values', 'weight'];
                var element = $('.original-preview').find('[name="' + name + '"]');
                if (element.hasClass('hidden')) {
                    element.removeClass('hidden');
                    if (sidebarBlocks.includes(name)) {
                        element.next().removeClass('hidden');
                    }
                } else {
                    element.addClass('hidden');
                    if (sidebarBlocks.includes(name)) {
                        element.next().addClass('hidden');
                    }
                }
            }
            $('.one-print-preview-page').each(function () {
                if (!$(this).hasClass('skip-front-page')) {
                    printPageSpaceOrganisation($(this));
                }
            });
            pageIndexCorrection();
        }
    });

    $('.print-back-btn').click(function () {
        if ($('.back-to-the-list-url').length == 0) {
            window.history.back(-1);
        } else {
            let url = (window.location.href).split('/')[0] + $('.back-to-the-list-url').attr('data-url');
            window.location.href = url;
        }

        return false;
    });

    $('.print-back-to-recipe').click(function () {
        window.history.back(-1);
        return false;
    });

    function detectBrowser() {
        let userAgent = navigator.userAgent;
        let browserName;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = "firefox";
        } else if (userAgent.match(/safari/i)) {
            browserName = "safari";
        } else if (userAgent.match(/opr\//i)) {
            browserName = "opera";
        } else if (userAgent.match(/edg/i)) {
            browserName = "edge";
        } else {
            browserName = "no-detection";
        }

        return browserName;
    }

    $('.print-page-overlay-print-btn').click(function () {
        let browserName = detectBrowser();
        if (browserName == "safari") {
            document.execCommand('print', false, null);
        } else {
            window.print();
        }
    });

    $('input[name="sidebar_information"], input[name="nutritional_values"]').on('change', function () {
        if ($(this).prop('checked')) {
            $('div[name="nutritional_values_extra_option_selector"]').removeClass('hidden');
        } else {
            $('div[name="nutritional_values_extra_option_selector"]').addClass('hidden');
        }
    });

    $('input[name="photo"]').on('change', function () {
        if ($(this).prop('checked')) {
            $('div[name="photo_size_extra_option_selector"]').removeClass('hidden');
        } else {
            $('div[name="photo_size_extra_option_selector"]').addClass('hidden');
        }
    });

    $('input[name="sales"]').on('change', function () {
        if ($(this).prop('checked')) {
            $('div[name="sales_extra_option_selector"]').removeClass('hidden');
        } else {
            $('div[name="sales_extra_option_selector"]').addClass('hidden');
        }
    });

    if ($('.recipe-db-header').length == 0) {
        $($('.recipe-import-modal-border-line')[$('.recipe-import-modal-border-line').length - 1]).remove();
    }

    $('.btn-import-shared').on('click', function () {
        $('.pseudomodal').css('display', 'block');
        $('.pseudomodal').addClass('activated');
    });

    if ($('.recipe-db-header').length > 0) {
        var default_message = $('.import-options-dropdown').html();
    }
    $('button[name="bulk-confirm-action"]').on('click', function (e) {
        if ($('.recipe-bulk-action-selector').find('button').attr('data-quantity-unit') === 'import-recipes') {
            var array = []; // get recipe_ids of selected recipes
            $('.recipe-selected').each((idx, element) => { array.push($(element).attr('data-rid')); });

            // populate import modal with data for selected recipes and their subrecipes
            // open modal after populating with requested data
            $.ajax({
                url: ('/recipes/db/import/modal-data'),
                type: 'post',
                data: {
                    recipe_ids: array
                },
                beforeSend: function () {
                    $('#recipes-import-list').html(''); // remove previously loaded recipes in modal
                },
                success: function (data) {
                    $('#recipes-import-list').append(data['html']); // append rendered content to import modal
                },
                complete: function () { // open modal
                    $('.pseudomodal').css('display', 'block');
                    $('.pseudomodal').addClass('activated');

                    var num = $('.ri-row').length, it = 0; // num = total number of recipes needed to select option for import
                    // set number of recipes that import option is selected currently
                    $('input[name="selected_import_option"]').each(function () {
                        if ($(this).val() != "") {
                            it++;
                        }
                    }); // set status messages in modal for number of selected options of number of needed options to be selected (example: 4 of 9)
                    var message = it + " " + $('.status-of-selected-options').html().split(' ')[1] + " " + num;
                    $('.status-of-selected-options').html(message);
                    if (num === it) { // enable import if all options selected
                        $('.import-btn').prop('disabled', false);
                    }

                    $('.import-option-check').remove();

                    $($('.recipe-import-modal-border-line')[$('.recipe-import-modal-border-line').length - 1]).remove();
                }
            });
        }
    });

    $('#recipe-db-list-item').hover(function() {
        $('.dropdown').removeClass('hidden');
        $('.btn-share').removeClass('hidden');
      }, function() {
        $('.dropdown').addClass('hidden');
        $('.btn-share').addClass('hidden');
    });

    function hideRecipeImportModal() {
        $('.pseudomodal-content').animate(
            { top: '-=1000px' }, 600, function () {
                $('.pseudomodal').css('display', 'none');
                $('.pseudomodal-content').css('top', '0');
            });

        if ($('.recipe-db-header').length > 0) {
            $('#recipes-import-list').html(''); // remove previously loaded recipes in modal
        }
    }

    $('body').on('click', '.close-recipe-import-modal, .footer-link, .close-import-btn', function () {
        hideRecipeImportModal();
        if ($(this).hasClass('close-import-btn') && $('.btn-import-shared').length > 0) {
            $('.btn-import-shared').prop('disabled', true);
            location.reload();
        } else {
            $('.import-btn').prop('disabled', true);
            $('.pseudomodal').removeClass('activated');
            $('.close-recipe-import-modal').removeClass('hidden');
            $('.import-progress, .import-completed').addClass('hidden');
            $('.body-height, .locations-selector, .preprogress-footer').removeClass('hidden');
        }
    });

    $('body').on('click', function (e) {
        if ($('.pseudomodal').hasClass('activated') && $('.import-options-dropdown')) {
            if (!$('.import-options-dropdown').is(e.target) && $('.import-options-dropdown').has(e.target).length === 0) {
                $('.import-options-dropdown').removeAttr('style');
                $('.arrow-down').removeClass('hidden');
                $('.arrow-up').addClass('hidden');
            }
        }
    });

    $('body').on('click', '.import-options-dropdown', function () {
        if ($('.ri-dropdown').hasClass('open')) {
            $(this).removeAttr('style');
            $(this).find('.arrow-up').addClass('hidden');
            $(this).find('.arrow-down').removeClass('hidden');
        } else {
            $(this).attr('style', 'background: #509CBF !important; color: white !important');
            $(this).find('.arrow-up').removeClass('hidden');
            $(this).find('.arrow-down').addClass('hidden');
        }
    });

    $('body').on('click', '.import-option', function () {
        $(this).parents('.list-style').find('img').remove();
        $(this).html($(this).html() + '<img class="import-option-check" src="/img/app-icons/icon-check.svg">');
        var parent_obj = $(this).parents('.ri-dropdown');
        parent_obj.find('.import-options-dropdown').removeAttr('style');
        parent_obj.find('.arrow-down').removeClass('hidden');
        parent_obj.find('.arrow-up').addClass('hidden');
        parent_obj.find('input').val($(this).attr('name'));

        var num = $('.ri-row').length, it = 0;
        $('input[name="selected_import_option"]').each(function () {
            if ($(this).val() != "") {
                it++;
            }
        });
        var message = it + " " + $('.status-of-selected-options').html().split(' ')[1] + " " + num;
        $('.status-of-selected-options').html(message);
        if (num === it) {
            $('.import-btn').prop('disabled', false);
        }

        var list_style = $(this).parents('.list-style');
        var clicked_option = $(this).attr('name');
        var clicked_obj_row = $(this).parents('.ri-row');
        if (clicked_obj_row.find('.subrecipe-style').length > 0) {
            var value1 = clicked_obj_row.find('.subrecipe-style').css('padding-left').match(/\d+/);
        } else {
            var value1 = 0;
        }

        clicked_obj_row.parents('.import-modal-entry').nextAll('.import-modal-entry').each(function () {
            if ($(this).find('.ri-row').hasClass('hidden') || $(this).find('.ri-row').hasClass('main-row')) {
                return false;
            } else {
                if ($(this).find('.subrecipe-style').length > 0) {
                    var value2 = $(this).find('.subrecipe-style').css('padding-left').match(/\d+/);
                } else {
                    var value2 = 0;
                }

                if (value2 > value1) {
                    $(this).find('.import-option').removeClass('hidden');
                    if (clicked_option === 'do_not_import') {
                        $(this).find('.import-option[name="do_not_import"]').trigger('click');
                        $(this).find('.import-options-dropdown').prop('disabled', true);
                    } else if (clicked_option === 'overwrite_existing' || clicked_option === 'import_only_if_not_already_existing') {
                        $(this).find('.import-options-dropdown').prop('disabled', false);
                        if (list_style.children().length == 2 && $(this).find('.import-option[name="import"]').length > 0) {
                            $(this).find('.import-option[name="import"]').trigger('click');
                            $(this).find('.import-options-dropdown').prop('disabled', true);
                        } else if (list_style.children().length == 3 && $(this).find('.import-option[name="import_as_duplicate"]').length > 0) {
                            $(this).find('.import-option[name="import_as_duplicate"]').addClass('hidden');
                        }
                    } else {
                        $(this).find('.import-options-dropdown').prop('disabled', false);
                        if (list_style.children().length == 2 && $(this).find('.import-option[name="import"]').length > 0) {
                            $(this).find('.import-option[name="import"]').trigger('click');
                            $(this).find('.import-options-dropdown').prop('disabled', true);
                        }
                    }
                } else {
                    return false;
                }
            }
        });

        var current_row = $(this).parents('.import-modal-entry');
        var notification_box = current_row.find('.overwrite-notification-box');
        if ($(this).attr('name') == "overwrite_existing") {
            if (notification_box.length == 0) {
                var master_db_rid = $(this).parents('.ri-dropdown').find('input').attr('data-recipe-id');
                $.ajax({
                    url: '/recipes/db/import/overwrite-notification',
                    type: 'post',
                    data: {
                        master_db_rid: master_db_rid
                    },
                    beforeSend: function () {
                        htmlLoading();
                    },
                    success: function (data) {
                        current_row.append(data['html']);
                        var tmp = current_row.find('.subrecipe-style');
                        if (tmp.length > 0) {
                            var padding = tmp.css('padding-left');
                            current_row.find('.overwrite-notification-box').css('margin-left', padding);
                        }
                    },
                    complete: function () {
                        htmlLoading(false);
                    }
                });
            } else {
                notification_box.removeClass('hidden');
            }
        } else {
            notification_box.addClass('hidden');
        }

        var it = 0;
        $('.ll-space-organisation').each(function () {
            if ($(this).find('input').prop('checked')) {
                it++;
            }
        });
        if (it !== 1) {
            $('a[name="import_only_if_not_already_existing"]').addClass('hidden');
        }
    });

    $('.top-row').on('click', function () {
        if ($('.a-up').hasClass('hidden')) {
            $('.a-down').addClass('hidden');
            $('.a-up').removeClass('hidden');
            $('.locations-listed').removeClass('hidden');
        } else {
            $('.a-up').addClass('hidden');
            $('.a-down').removeClass('hidden');
            $('.locations-listed').addClass('hidden');
        }
    });

    $('input[name="check_all_locations"]').on('change', function () {
        if ($(this).prop('checked')) {
            $('.locations-listed').find('input').prop('checked', true);
        } else {
            $('.locations-listed').find('input').prop('checked', false);
        }
    });

    $('.ll-space-organisation').on('change', function () {
        var num = $('.ll-space-organisation').length, it = 0;
        $('.ll-space-organisation').each(function () {
            if ($(this).find('input').prop('checked')) {
                it++;
            }
        });

        if ($(this).find('input').prop('checked')) {
            if (num === it) {
                $('input[name="check_all_locations"]').prop('checked', true);
            } else {
                $('input[name="check_all_locations"]').prop('checked', false);
            }
        } else {
            $('input[name="check_all_locations"]').prop('checked', false);
        }

        if (it === 1) {
            $('a[name="import_only_if_not_already_existing"]').removeClass('hidden');
        } else {
            $('a[name="import_only_if_not_already_existing"]').each(function () {
                $(this).addClass('hidden');
                var rowObj = $(this).parents('.ri-row-content');
                if (rowObj.find('input[name="selected_import_option"]').val() === 'import_only_if_not_already_existing') {
                    if (rowObj.find('.ri-status').hasClass('imported')) {
                        rowObj.find('a[name="import_as_duplicate"]').trigger('click');
                    } else {
                        rowObj.find('a[name="import"]').trigger('click');
                    }
                }
            });
        }
    });

    var pb_it = 0;

    function animateRecipeImportProgressBar() {
        var str_array = $('.progress-message').html().split(" ");
        var recipe_number = str_array[4], inc = 100 / recipe_number, curr = 1;
        if (pb_it == 0) {
            pb_it = 1;
            var width = 10;
            var id = setInterval(frame, 50);

            function frame() {
                if (recipe_number >= 3 && width >= inc) {
                    curr++;
                    inc = inc + 100 / recipe_number;
                    $('.progress-message').html(str_array[0] + " " + str_array[1] + " " + curr + " " + str_array[3] + " " + str_array[4]);
                }
                if (width >= 95) {
                    $('.progress-message').html(str_array[0] + " " + str_array[1] + " " + str_array[4] + " " + str_array[3] + " " + str_array[4]);
                    clearInterval(id);
                    pb_it = 0;
                } else {
                    width++;
                    $(".progress-loading").width(width + "%");
                }
            }

            return id;
        }
    }

    function completeRecipeImportProgressBar(id) {
        $(".progress-loading").width("100%");
        clearInterval(id);
    }

    $('.import-btn').on('click', function () {
        var oe_it = 0;
        $('input[name="selected_import_option"]').each(function () {
            if ($(this).val() == "overwrite_existing") {
                oe_it++;
            }
        });
        if (oe_it > 0 && $('.recipe-import-update-permission').html() == 'NO') {
            hideRecipeImportModal();
            return false;
        }

        var selected_subrecipe_import_options = [], selected_main_recipe_import_options = [],
            selected_restaurants = [], multi_import_options = [];
        $('input[name="restaurant"]').each(function () {
            if ($(this).prop('checked')) {
                selected_restaurants.push($(this).attr('data-restaurant-id'));
            }
        });

        var loop = null; // use to cancel event set by setInterval in loading animation

        if ($('.recipe-db-header').length > 0) {
            $('.main-row:not(.hidden)').each(function () {
                selected_subrecipe_import_options = [];
                selected_main_recipe_import_options = [];
                var option = $(this).find('input[name="selected_import_option"]');
                selected_main_recipe_import_options.push({
                    recipe_id: option.attr('data-recipe-id'),
                    import_option: option.val()
                });
                $(this).parents('.import-modal-entry').nextAll('.import-modal-entry').each(function () {
                    if ($(this).find('.ri-row').hasClass('subrecipe-row')) {
                        var tmp_option = $(this).find('input[name="selected_import_option"]');
                        selected_subrecipe_import_options.push({
                            recipe_id: tmp_option.attr('data-recipe-id'),
                            import_option: tmp_option.val()
                        });
                    } else {
                        return false;
                    }
                });
                multi_import_options.push({
                    smrio: selected_main_recipe_import_options,
                    ssrio: selected_subrecipe_import_options
                });
            });

            // update progress message with number of only recipes that will be imported
            var selected_recipes = $('* .selected [name=import]').length + $('* .selected [name=import_as_duplicate]').length + $('* .selected [name=import_only_if_not_already_existing]').length;
            if (selected_recipes == 0) {
                $('.progress-message').addClass('hidden');
            } else {
                $('.progress-message').removeClass('hidden');
                var progress_message_array = $('.progress-message').html().split(" ");
                progress_message_array[4] = selected_recipes;
                $('.progress-message').html(progress_message_array.join(" "));
            }
            $('.recipe-import-animation-duration').html(selected_recipes * 0.5);

            $.ajax({
                url: ('/recipes/db/import/multi-import'),
                type: 'post',
                data: {
                    no_subrecipes: selected_subrecipe_import_options.length == 0 ? true : false,
                    multi_import_options: multi_import_options,
                    selected_restaurants: selected_restaurants
                },
                beforeSend: function () {
                    $('.body-height, .locations-selector, .preprogress-footer').addClass('hidden');
                    $('.import-progress').removeClass('hidden');
                    loop = animateRecipeImportProgressBar();
                },
                success: function (data) {
                    // animate loading bar to fully filled
                    completeRecipeImportProgressBar(loop);
                    setTimeout(() => {
                        $('.import-progress').addClass('hidden');
                        $('.import-completed').removeClass('hidden');
                        $('.close-recipe-import-modal').addClass('hidden');
                    }, 250);
                }
            });
        } else {
            $('input[name="selected_import_option"]').each(function () {
                var tmp = $(this).attr('data-recipe-id');
                if ($(this).hasClass('subrecipe-import-option')) {
                    selected_subrecipe_import_options.push({ recipe_id: tmp, import_option: $(this).val() });
                } else {
                    selected_main_recipe_import_options.push({ recipe_id: tmp, import_option: $(this).val() });
                }
            });
            multi_import_options.push({
                smrio: selected_main_recipe_import_options,
                ssrio: selected_subrecipe_import_options
            });

            $.ajax({
                url: ('/recipes/db/import/' + $('#recipe-id').html()),
                type: 'post',
                data: {
                    multi_import_options: multi_import_options,
                    selected_restaurants: selected_restaurants
                },
                beforeSend: function () {
                    $('.body-height, .locations-selector, .preprogress-footer').addClass('hidden');
                    $('.import-progress').removeClass('hidden');
                    $('.progress-message').removeClass('hidden');
                    var selected_recipes = $('* .selected [name=import]').length + $('* .selected [name=import_as_duplicate]').length;
                    if (selected_recipes == 0) {
                        $('.progress-message').addClass('hidden');
                    } else {
                        var progress_message_array = $('.progress-message').html().split(" ");
                        progress_message_array[4] = selected_recipes;
                        $('.progress-message').html(progress_message_array.join(" "));
                    }
                    loop = animateRecipeImportProgressBar();
                },
                success: function (data) {
                    // animate loading bar to fully filled
                    completeRecipeImportProgressBar(loop);
                    setTimeout(() => {
                        $('.import-progress').addClass('hidden');
                        $('.import-completed').removeClass('hidden');
                        $('.close-recipe-import-modal').addClass('hidden');
                    }, 250);
                }
            });
        }
    });

    $('.cancel-import-btn').on('click', function () {
        $.ajax({
            url: '/recipes/db/import/cancel-import',
            type: 'post',
            data: {
                cancel_recipe_import: true
            },
            success: function (data) {
                hideRecipeImportModal();
                $('.pseudomodal').removeClass('activated');
                $('.import-progress, .import-completed').addClass('hidden');
                $('.body-height, .locations-selector, .preprogress-footer').removeClass('hidden');
            }
        });
    });

    if ($('.bulk-menu-is-empty').html() == "TRUE") {
        $('.recipes-bulk-wrapper').remove();
    }

    /**
     * THIS EVENT IS FOR MANUALLY UPDATING EATERNITY EVALUATION
     * IF .evaluation-update-block ISN'T HIDDEN IN EDIT IT MEANS MANUAL UPDATE IS ALLOWED
     */
    $('#update-evaluation').on('click', function () {
        $('.evaluation-update-block').toggleClass('hidden', true);

        $('.evaluation-content').html($('.loading-evaluation-active').html());
        $.ajax({
            url: $('#evaluation-update-url').attr('data-url'),
            type: 'GET',
            success: function (data) {
                $('.evaluation-content').html(data['evaluation']);
                $('.evaluation-update-block').toggleClass('hidden', false);
            },
            error: function () {
                // this should never be reached since all errors handled with success event
                $('.evaluation-content').html("Error!");
                $('.evaluation-update-block').toggleClass('hidden', false);
            },
            complete: function () {
                $('.evaluation-update-block').toggleClass('hidden', false);
                $('#main-content-evaluation').html($('#open-tab-to-see-updates').html());
            }
        });
    });

    function showMandatoryModal() {
        $('.mandatory').removeClass('hidden');
        $('.non-mandatory').addClass('hidden');
    }

    function hideMandatoryModal() {
        $('.mandatory').addClass('hidden');
        $('.non-mandatory').removeClass('hidden');
        $('.save-and-keep-editing').removeClass('already-clicked');
    }

    function hideRecipeDbNotificationsModal() {
        hideMandatoryModal();
        $('.content').animate(
            { top: '-=1000px' }, 600, function () {
                $('.notification-modal').css('display', 'none');
                $('.content').css('top', '0');
            });
    }

    $('.recipe-save-dropdown-li.upper, .recipe-save-dropdown-li.lower, .save-without-dropdown').on('click', function () {
        $('.notification-modal').css('display', 'block');
        if ($(this).hasClass('lower')) {
            showMandatoryModal();
        }
    });

    $('.close-notification-modal').on('click', function () {
        hideRecipeDbNotificationsModal();
    });

    $('.save-and-keep-editing, .save-and-finish-editing, .finish-editing').on('click', function () {
        if (!$(this).hasClass('already-clicked')) {
            $(this).addClass('already-clicked');
            var rid = $('#recipe-id').val();
            var comment = $('textarea[name="comment"]').val().trim();
            var redirect = $(this).hasClass('save-and-finish-editing') || $(this).hasClass('finish-editing');
            $('input[name="to_refresh_or_not_to_refresh"]').val(redirect ? "1" : "0");
            if (!comment) {
                if ($(this).hasClass('save-and-finish-editing')) {
                    showMandatoryModal();
                } else if ($(this).hasClass('save-and-keep-editing')) {
                    $('.recipe-save-action-trigger').trigger('click');
                    hideRecipeDbNotificationsModal();
                }
            } else {
                $.ajax({
                    url: '/recipes/comments/add_new',
                    type: 'post',
                    data: {
                        comment: comment,
                        rid: rid
                    },
                    beforeSend: function () {
                        htmlLoading();
                    },
                    success: function (data) {
                        $('.recipe-save-action-trigger').trigger('click');
                        hideRecipeDbNotificationsModal();
                        if (!redirect) {
                            $('textarea[name="comment"]').val("");
                        } else {
                            var obj = $('.btn-redesign-v2');
                            var text = obj.attr('data-loading-text');
                            obj.prop('disabled', true);
                            obj.html(text);
                        }
                        htmlLoading(false);
                    }
                });
            }
        }
    });

    function showRecipeCommentsList() {
        $('.navbar').addClass('hidden');
        $('#left-menu').addClass('hidden');
        $('#right-menu').addClass('hidden');
        $('.recipe-form').addClass('hidden');
        $('main').removeClass('consider-sidebar');
        $('footer').addClass('hidden');
        if ($('.recipe-view')) {
            $('.recipe-view').addClass('hidden');
        }
    }

    function recipeCommentRowCounterAction() {
        $('.counter-time').each(function () {
            var tmp_time = $(this).html().split(':');
            var minutes = parseInt(tmp_time[0]);
            var seconds = parseInt(tmp_time[1]);
            if (seconds == 0 && minutes == 0) {
                var obj = $(this).parents('.comment-row');
                obj.find('.counter-and-icons').remove();
                obj.find('.comment-text').removeClass('recent-comment');
                obj.next().remove();
            } else {
                if (seconds == 0) {
                    minutes--;
                    seconds = 59;
                } else {
                    seconds--;
                }
                var message = "0" + minutes + ":";
                if (seconds < 10) {
                    message = message + "0" + seconds;
                } else {
                    message = message + seconds;
                }
                $(this).html(message);
            }
        });
    }

    var decrementTimer;
    function hideRecipeCommentsList() {
        $('.navbar').removeClass('hidden');
        $('#left-menu').removeClass('hidden');
        $('#right-menu').removeClass('hidden');
        $('.recipe-form').removeClass('hidden');
        $('main').addClass('consider-sidebar');
        $('footer').removeClass('hidden');
        if ($('.recipe-view')) {
            $('.recipe-view').removeClass('hidden');
        }
        clearInterval(decrementTimer);
    }

    $('.notification-modal .view-comments').on('click', function () {
        if (!$(this).hasClass('already-clicked')) {
            var current_obj = $(this);
            $.ajax({
                url: '/recipes/comments/view_comments',
                type: 'post',
                data: {
                    rid: $('#recipe-id').val(),
                    from_master_recipe_edit: true
                },
                beforeSend: function () {
                    current_obj.addClass('already-clicked');
                    htmlLoading();
                },
                success: function (data) {
                    htmlLoading(false);
                    showRecipeCommentsList();
                    hideRecipeDbNotificationsModal();
                    $('.recipe-comments-list-data').append(data['html']);
                    decrementTimer = setInterval(recipeCommentRowCounterAction, 1000);
                }
            });
        }
    });

    $('body').on('mouseover', '.comment-row', function () {
        if ($(this).hasClass('recent')) {
            $(this).find('.counter').addClass('hidden');
            $(this).find('.comment-row-icons').removeClass('hidden');
        }
    });

    $('body').on('mouseout', '.comment-row', function () {
        if ($(this).hasClass('recent')) {
            $(this).find('.counter').removeClass('hidden');
            $(this).find('.comment-row-icons').addClass('hidden');
        }
    });

    $('body').on('mouseover', '.edit-not-hovered', function () {
        $(this).addClass('hidden');
        $(this).parent().find('.edit-hovered').removeClass('hidden');
    });

    $('body').on('mouseout', '.edit-hovered', function () {
        $(this).addClass('hidden');
        $(this).parent().find('.edit-not-hovered').removeClass('hidden');
    });

    $('body').on('mouseover', '.remove-not-hovered', function () {
        $(this).addClass('hidden');
        $(this).parent().find('.edit-comment-icon').css('margin-right', '7px');
        if ($('.only-comment.recent').length == 1) {
            $(this).parent().find('.remove-hovered-empty').removeClass('hidden');
        } else {
            $(this).parent().find('.remove-hovered-normal').removeClass('hidden');
        }
    });

    $('body').on('mouseout', '.remove-hovered-normal, .remove-hovered-empty', function () {
        $(this).addClass('hidden');
        $(this).parents('.counter-and-icons').find('.edit-comment-icon').removeAttr('style');
        $(this).parents('.counter-and-icons').find('.remove-not-hovered').removeClass('hidden');
    });

    $('body').on('click', '.edit-comment-icon', function () {
        var row = $(this).parents('.comment-row');
        row.css('padding', '7.5px 5px 7.5px 10px');
        row.find('.comment').addClass('hidden');
        row.find('.editable-part').removeClass('hidden');
    });

    $('body').on('click', '.remove-comment-icon', function () {
        if ($('.only-comment.recent').length > 1) {
            var row = $(this).parents('.comment-row').next('.delete-confirmation-row');
            row.removeClass('hidden');
            row.focus();
        }
    });

    $('body').on('click', '.delete-confirmation-row .msg-block .delete-link', function () {
        var row = $(this).parents('.delete-confirmation-row');
        var cid = row.find('.comment-id').html();
        $.ajax({
            url: '/recipes/comments/delete_comment',
            type: 'post',
            data: {
                cid: cid
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function (data) {
                row.remove();
                $('.comment-row.recent').each(function () {
                    if ($(this).find('.comment-id').html() == cid) {
                        $(this).remove();
                        return false;
                    }
                });
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.delete-confirmation-row .msg-block .cancel-link', function () {
        $(this).parents('.delete-confirmation-row').addClass('hidden');
    });

    $('body').on('click', '.comment-edit-completed', function () {
        var row = $(this).parents('.comment-row');
        if (row.find('.comment-edit-input').val().trim() == "") {
            row.find('.comment-edit-canceled').trigger('click');
        } else {
            $.ajax({
                url: '/recipes/comments/update_existing',
                type: 'post',
                data: {
                    cid: $('.comment-id').html(),
                    comment: row.find('.comment-edit-input').val().trim()
                },
                beforeSend: function () {
                    htmlLoading();
                },
                success: function (data) {
                    row.next().remove();
                    row.remove();
                    $('.all-rows.recent').prepend(data['html2']);
                    $('.all-rows.recent').prepend(data['html1']);
                    htmlLoading(false);
                }
            });
        }
    });

    $('body').on('click', '.comment-edit-canceled', function () {
        var row = $(this).parents('.comment-row');
        row.removeAttr('style');
        row.find('.comment').removeClass('hidden');
        row.find('.editable-part').addClass('hidden');
        row.find('.comment-edit-input').val(row.find('.comment-text').html());
    });

    $('body').on('keyup', '.comment-edit-input', function (e) {
        var row = $(this).parents('.comment-row');
        if (e.which == 13) {
            row.find('.comment-edit-completed').trigger('click');
        } else if (e.which == 27) {
            row.find('.comment-edit-canceled').trigger('click');
        }
    });

    $('body').on('click', '.back-btn, .close-btn', function () {
        hideRecipeCommentsList();
        $('.recipe-comments-list-data').empty();
        $('.rightbar-block-content-link.notify-user, .rightbar-block-content-link.view-mods, .rightbar-block-content-link.child-recipe-view-mods, .view-comments').removeClass('already-clicked');
    });

    $('body').on('change clicked', '.dont-show-again', function () {
        var value = $(this).find('input').prop('checked') ? "1" : "0";
        $.ajax({
            url: '/recipes/comments/user_attr_for_comments_modal',
            type: 'post',
            data: {
                ask_for_comments_after_each_save: value
            },
            beforeSend: function () {
                htmlLoading();
            },
            success: function () {
                htmlLoading(false);
                if (value == "1") {
                    $('.recipe-save-action-selector').parent().addClass('hidden');
                    $('.save-without-dropdown').removeClass('hidden');
                } else {
                    $('.save-without-dropdown').addClass('hidden');
                    $('.recipe-save-action-selector').parent().removeClass('hidden');
                }
            }
        });
    });

    $('.rightbar-block-content-link.notify-user, .rightbar-block-content-link.view-mods, .rightbar-block-content-link.child-recipe-view-mods').on('click', function () {
        if (!$(this).hasClass('already-clicked')) {
            var current_obj = $(this);
            if (current_obj.hasClass('view-mods')) {
                var value1 = true;
            } else if (current_obj.hasClass('child-recipe-view-mods')) {
                var value2 = true;
            } else if (current_obj.hasClass('notify-user')) {
                var value3 = true;
            }
            $.ajax({
                url: '/recipes/comments/view_comments',
                type: 'post',
                data: {
                    rid: $('#recipe-id').html(),
                    disable_notify_user_btn: value1,
                    from_child_recipe: value2,
                    notify_user: value3
                },
                beforeSend: function () {
                    current_obj.addClass('already-clicked');
                    htmlLoading();
                },
                success: function (data) {
                    htmlLoading(false);
                    showRecipeCommentsList();
                    $('.recipe-comments-list-data').append(data['html']);
                    decrementTimer = setInterval(recipeCommentRowCounterAction, 1000);
                }
            });
        }
    });

    $('body').on('click', '.btn.notify-btn', function () {
        var recent_comment_ids = [];
        $('.only-comment.recent').find('.comment-id').each(function () {
            recent_comment_ids.push($(this).html());
        });
        $.ajax({
            url: '/recipes/comments/notify_user',
            type: 'post',
            data: {
                rid: $('#recipe-id').html(),
                recent_comment_ids: recent_comment_ids
            },
            beforeSend: function () {
                $('.btn.notify-btn').prop('disabled', true);
                $('.notification-sent-mini-msg').css('display', 'block');
                var number = 3;
                var number_new = 0;
                setInterval(function () {
                    var msg = $('.mini-msg-style').html();
                    number_new = number - 1;
                    var new_msg = msg.replace(number, number_new);
                    if (number_new >= 0) {
                        $('.mini-msg-style').html(new_msg);
                    }
                    number = number_new;
                }, 1000);
                htmlLoading();
            },
            success: function (data) {
                htmlLoading(false);
            }
        });
    });

    $('body').on('click', '.mods-action', function () {
        $('.mods-error-msg, .mods-info-msg-group, .mods-warning-msg-group').addClass('hidden');
        var selected_action = $(this).attr('name');
        $('input[name="selected-mods-action"]').val(selected_action);
        if (selected_action == 'overwrite_child_with_master_recipe') {
            if ($('.mods-info-msg-group').length > 0) {
                $('.mods-info-msg-group').removeClass('hidden');
            } else {
                $('.mods-warning-msg-group').removeClass('hidden');
            }
        } else if (selected_action == 'ignore_and_never_ask_again') {
            $('.mods-error-msg').removeClass('hidden');
        }
        $('.ok-btn').prop('disabled', false);
    });

    $('body').on('click', '.ok-btn', function () {
        var selected_action = $('input[name="selected-mods-action"]').val();
        $.ajax({
            url: '/recipes/comments/post_modification',
            type: 'post',
            data: {
                rid: $('#recipe-id').html(),
                action: selected_action
            },
            beforeSend: function () {
                $('.ok-btn').prop('disabled', true);
                htmlLoading();
            },
            success: function (data) {
                htmlLoading(false);
            }
        });
    });

    $('.nutritional-values-positioning li[role="presentation"]').on('click', function () {
        var obj = $(this).parents('.rightbar-block-opened').prev();
        if ($(this).find('a').attr('aria-controls') == "nutr-portion") {
            obj.find('.nutritional-data-msg').removeClass('hidden');
            obj.find('.nutritional-data-per100g-msg').addClass('hidden');
        } else {
            obj.find('.nutritional-data-msg').addClass('hidden');
            obj.find('.nutritional-data-per100g-msg').removeClass('hidden');
        }
    });

    $('.extra-option-selector-item').on('click', function () {
        var inputObj = $(this).parents('.extra-option-selector').find('input');
        var key = inputObj.attr('name');
        var value = $(this).attr('data-value');
        if (inputObj.attr('name') == 'nutritional_values_extra') {
            inputObj.val(value);
            if (value == 'per_portion') {
                $('div[name="per_portion"]').removeClass('hidden');
                $('div[name="per_100g"]').addClass('hidden');
            } else {
                $('div[name="per_100g"]').removeClass('hidden');
                $('div[name="per_portion"]').addClass('hidden');
            }
        } else {
            if (inputObj.attr('name') == 'language_option') {
                var newUrl = (window.location.href).split('?')[0] + '?_locale=' + value;
                window.location.href = newUrl;
            } else {
                $.ajax({
                    url: inputObj.attr('data-url'),
                    type: 'post',
                    data: {
                        k: key,
                        v: value
                    },
                    beforeSend: function () {
                        inputObj.val(value);
                        htmlLoading();
                    },
                    success: function () {
                        htmlLoading(false);
                        $('input[type="checkbox"]').attr('disabled', 'disabled');
                        setTimeout(function () { location.reload(); }, 100);
                    }
                });
            }
        }
    });

    $('.reorder-recipes').on('click', function () {
        var recipe_ids = [];
        $('.recipe-selected').each(function () {
            recipe_ids.push($(this).find('input[name="recipe-select"]').val());
        });
        $.ajax({
            url: '/recipes/list-for-cookbook-print',
            type: 'post',
            data: {
                recipe_ids: recipe_ids
            },
            beforeSend: function () {
                // hide everything from initial /recipes page in order to show cookbook reordering page as page overlay
                $('.recipes-bulk-wrapper, #middle-content').addClass('bulk-menu-positioning');
                $('.main-content-menu').addClass('hidden').next().addClass('hidden');
                $('.create-cookbook-text, .reorder-recipes').css('display', 'none');
                $('.cancel-cookbook-reorder-list-text').css('display', 'block');
                $('#bulk-menu-main-option').prop('disabled', true);
                $('.recipes-list').addClass('scroll-loaded');
                $('.recipes-list-for-cookbook').remove();
                $('#right-menu').addClass('hidden');
                htmlLoading();
            },
            success: function (data) {
                $('#middle-content-block').append(data['html']);
                htmlLoading(false);
            }
        });
    });

    $('.cancel-cookbook-reorder-list-text').on('click', function () {
        // get back to initial state on /recipes page
        $('.recipes-list-for-cookbook').remove();
        $('.recipes-bulk-wrapper, #middle-content').removeClass('bulk-menu-positioning');
        $('.main-content-menu').removeClass('hidden').next().removeClass('hidden');
        $('.create-cookbook-text, .reorder-recipes').css('display', 'block');
        $('.cancel-cookbook-reorder-list-text').css('display', 'none');
        $('#bulk-menu-main-option').prop('disabled', false);
        $('.recipes-list').removeClass('scroll-loaded');
        $('#right-menu').removeClass('hidden');
    });

    $('body').on('click', '#sortable', function () {
        // this is used to activate drag&drop features for cookbook reordering page
        if ($('.recipes-list-for-cookbook').length > 0) {
            $("#sortable").sortable();
        }
    });

    $('body').on('click', '.portion-menu a', function() {
        let selectedOption = $(this).attr('data-value');
        $('#nutr-portion, #nutr-100g').removeClass('active');
        $('#' + selectedOption).addClass('active');
    });

    $('body').on('change', '.gesoca_integration_tmp, .eaternity_integration_tmp', function() {
        if ($(this).hasClass('gesoca_integration_tmp')) {
            $('input[name="gesoca_integration"]').val($('input[name="gesoca_integration_tmp"]').prop('checked') ? "1" : "0");
        } else {
            $('input[name="eaternity_integration"]').val($('input[name="eaternity_integration_tmp"]').prop('checked') ? "1" : "0");
        }

        let integrations = [];
        if ($('input[name="eaternity_integration_tmp"]').prop('checked')) {
            integrations.push('Eaternity');
        }
        if ($('input[name="gesoca_integration_tmp"]').prop('checked')) {
            integrations.push('Gesoca');
        }

        if (integrations.length > 0) {
            $('.integrations-empty-label').addClass('hidden');
            $('.integrations-not-empty-label').removeClass('hidden');
            $('.integrations-not-empty-label').html(integrations.join(', '));
        } else {
            $('.integrations-not-empty-label').addClass('hidden');
            $('.integrations-empty-label').removeClass('hidden');
        }
    });

    $('body').on('change', '#nutrition_tags_type', function () {
        var nutritionTagsType = $('#nutrition_tags_type').val();
        $('body').find('input[name="recipe_nutrition_tags_type"]').val(nutritionTagsType);

        if (nutritionTagsType == '1') {
            $('.manually-define-tag').css('display', 'none');
            $('.automatically-custom-tag').css('display', 'inline-block');
        }
        else {
            $('.manually-define-tag').css('display', 'inline-block');
            $('.automatically-custom-tag').css('display', 'inline-block');

        }

    });

});

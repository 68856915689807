window.groups_ctrl = false; // check if ctrl-key + mouse-click on recipe groups checkbox
$('.categories-well-filter input[type=checkbox]').click(function (e) {
    if (window.ctrl_active) {
        window.groups_ctrl = true;
    }
});


/* recipes */
$(document).ready(function () {

    //right-menu



    $("#right-menu").css("overflow-y", "auto");

    var recipes_list = $('.recipes-list');
    if (!recipes_list.length) {
        return;
    }

    $(".sh-recipe-searching-params").click(function () {
        var id = ($(this).attr("id")).split("-");

        var second_part = "-content";

        if (id[0] == "categories")
            second_part = "-well-filter";

        var current_elemnt_id = "#" + id[0] + second_part;

        if ($(current_elemnt_id).is(":hidden")) {

            $(this).removeClass("icon-down-mark");
            $(this).addClass("icon-up-mark");

            id[0] == "categories" ? $(this).css("top", "20px") : $(this).css("top", "0px");

            $(current_elemnt_id).show(500)
        } else {

            $(this).addClass("icon-down-mark");
            $(this).removeClass("icon-up-mark");


            id[0] == "categories" ? $(this).css("top", "10px") : $(this).css("top", "-14px");

            $(current_elemnt_id).hide(500);
        }

    });


    $('input[type=checkbox]').change(function () {

        setTimeout(() => {
            getLocationCheckedParams(["location-content", "filter-content", "recipetype-content", "tag-content", "categories-well-filter"]);
        }, 400)


    });


    function getLocationCheckedParams(ids) {

        ids.forEach(id => {
            var panelId = id.split("-")[0];

            var count = 0;

            $("#" + id + " :checkbox").each(function () {
                if ($(this).is(":checked")) {
                    count++;
                }
            });

            if(count == 0)
            {
                $("." + panelId + "-paramscount").hide();
                $("#" + panelId + "-type-filter").hide();
            } else {
                $("." + panelId + "-paramscount").show();
                $("#" + panelId + "-type-filter").show()
                $("." + panelId + "-paramscount").html(count);
            }


        });


    }
    getLocationCheckedParams(["location-content", "filter-content", "recipetype-content", "tag-content", "categories-well-filter"]);

    // click on recipeBlock opens recipe view
    $('body').on('click', '.recipeBlock[data-href]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        if ($('.recipes-bulk-wrapper.visible').length) {
            $(this).find('.nice-select').trigger('clicked');
        } else {
            document.location.href = $(this).attr('data-href');
        }
    });
    // recipe bulk actions
    // catch click on label because of link recipeBlock and propagation
    recipes_list.on('click clicked', '.nice-select', function (e) {
        var $this = $(this).find('.recipe-select');
        var currentNumberOfRecipesInCookbook = $('.recipe-selected').length;
        $this.prop('checked', !$this.is(':checked')).trigger('clicked');
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        // logic for recipe's order tags
        if (!$this.is(':checked')) {
            currentNumberOfRecipesInCookbook--;
            var currentObj = $this.parents('.recipeBlock').find('.right-part');
            var removedNumber = Number(currentObj.find('.current-number').html());
            currentObj.empty();
            $this.parents('.recipeBlock').find('.active').find('.recipe-block-name-group').find('.left-part').first().css('max-width','100%');
            $('.recipe-selected').each(function () {
                var tmpObj = $(this).find('.right-part');
                if (Number(tmpObj.find('.current-number').html()) > removedNumber) {
                    var tmpNumber = Number(tmpObj.find('.current-number').html());
                    tmpNumber--;
                    tmpObj.find('.current-number').html(tmpNumber);
                }
            });
        } else {
            currentNumberOfRecipesInCookbook++;
            $this.parents('.recipeBlock').find('.right-part').append('<span class="current-number">' + currentNumberOfRecipesInCookbook + '</span>');
            $this.parents('.recipeBlock').find('.active').find('.recipe-block-name-group').find('.left-part').first().css('max-width','70%');
        }
        var tmpText = $('.create-cookbook-subaction-text').html().split('(')[0];
        $('.create-cookbook-subaction-text').html(tmpText + '(' + currentNumberOfRecipesInCookbook + ')');

    }).on('clicked', '.recipe-select', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        var $this = $(this), checked = $this.is(':checked');
        $this.closest('.recipe').toggleClass('recipe-selected', checked);
        if (e.type == 'clicked') {
            $this.closest('.nice-select').toggleClass('selected', checked);
        }

        var body = $('body');
        if (!body.data('marginBottom')) {
            body.data('marginBottom', body.css('margin-bottom'));
        }

        var bulks = $('.recipes-bulk-wrapper');
        var selects = $('.recipe-select:checked');
        var selected = selects.length;

        // select first visible option if currently selected does not exist
        if (selected && e.type != 'clicked') {
            var toggle_val = bulks.find('.recipe-bulk-action-selector .dropdown-toggle').attr('data-quantity-unit');

            if (!bulks.find('.recipe-bulk-action-selector .dropdown-menu li a[data-value=' + toggle_val + ']').length) {
                bulks.find('.recipe-bulk-action-selector .dropdown-menu li:first-child a').trigger('click');
            }
        }

        if (!selected) {
            bulks.removeClass('visible');
            body.get(0).style.setProperty('margin-bottom', body.data('marginBottom'), 'important');
            return;
        }
        if (selected == 1) {
            bulks.find('single').show();
            bulks.find('plural').hide();
        } else {
            bulks.find('single').hide();
            bulks.find('plural').show();
        }
        bulks.addClass('visible');
        bulks.find('recipes').text(selected);
        body.get(0).style.setProperty('margin-bottom', (bulks.height() + 20) + 'px', 'important');

        // disable unarchive if there are no archived selected
        bulks.find('.recipe-bulk-action-selector .dropdown-menu li a[data-value=unarchive]').parent('li').toggleClass('disabled',
            !selects.parents('.recipe.recipe-archived').length
        );
        // disable archive if there are no active selected
        bulks.find('.recipe-bulk-action-selector .dropdown-menu li a[data-value=archive]').parent('li').toggleClass('disabled',
            !selects.parents('.recipe.recipe-active').length
        );

        return false;

    }).find('.recipe-select:checked').trigger('clicked');
    // bulk select all recipes
    $('.recipes-bulk .bulk-action-text > *').bindFirst('click', function () {
        var $this = $(this), recipes = null;
        if ($this.data('checked-recipes')) {
            recipes_list.children('.recipe-selected').each(function () {
                    $(this).find('.recipeBlock').find('.active').find('.recipe-block-name-group').find('.left-part').first().css('max-width','100%');
            });
            recipes_list.find('[name=recipe-select]:checked').prop('checked', false).trigger('clicked');
            recipes = $this.data('checked-recipes');
            $this.removeData('checked-recipes');
        } else {
            $this.data('checked-recipes', recipes_list.find('.recipe:visible [name=recipe-select]:checked'));
            recipes = recipes_list.find('.recipe:visible [name=recipe-select]');
        }

        recipes.prop('checked', true).trigger('clicked');

        var counter = 1;
        $('.right-part').empty();
        recipes_list.children('.recipe-selected').each(function () {
            var tmpObj = $(this).find('.right-part');
            if ($('#bulk-menu-main-option').attr('data-quantity-unit') == 'create-cookbook') {
                $(this).find('.recipeBlock').find('.active').find('.recipe-block-name-group').find('.left-part').first().css('max-width','70%');
                tmpObj.removeClass('hidden').append('<span class="current-number">' + counter + '</span>');
            } else {
                console.log('akcija2')
                $(this).find('.recipeBlock').find('.active').find('.recipe-block-name-group').find('.left-part').first().css('max-width','70%');
                tmpObj.append('<span class="current-number">' + counter + '</span>');
            }
            counter++;
        });

        var tmpText = $('.create-cookbook-subaction-text').html().split('(')[0];
        $('.create-cookbook-subaction-text').html(tmpText + '(' + (counter - 1) + ')');

        return false;
    });
    // bulk main action
    $('.recipes-bulk .recipe-bulk-action-selector .dropdown-menu li a').on('click clicked', function (e) {

        var $this = $(this), action = $this.attr('data-value');
        var bulks = $('.recipes-bulk');

        var text = '.' + action + '-text';
        var sub_action = '.' + action + '-subaction';

        bulks.find('.bulk-action-text ' + text + ', .bulk-sub-action ' + sub_action).show();
        bulks.find('.bulk-action-text >:not(' + text + '), .bulk-sub-action >:not(' + sub_action + ')').hide();

        recipes_list.find('.recipe-select:checked').first().trigger('clicked');
    });
    // new category input
    $('#newCategoryModal form').submit(function () {
        var form = $(this),
            category = form.find('[name=new_category]').val(),
            cat_slug = category
                .replace(/\s+/g, '-')
                .replace(/[^0-9a-z-]/gi, '')
                .toLowerCase();

        if (!$.trim(category).length) {
            return false;
        }

        // add category to the list
        var ul = $('.recipes-bulk .category-selector');

        // check whether the category already exist
        var a = ul.find('li > a[data-value=' + cat_slug + ']').first();

        // add category
        if (!a.length) {
            var li = $('<li role="presentation" class="category"><a href="#" role="menu-item" tab-index="-1"></a></li>');
            li.find('a')
                .attr('data-value', cat_slug)
                .text(category);
            $('.category-selector').append(li);
            a = ul.find('li > a[data-value=' + cat_slug + ']');
        }
        a.click();

        var modal = form.parents('.modal');
        modal.modal('hide');

        if (modal.hasClass('submit-action')) {
            $('.recipes-bulk [name=bulk-confirm-action]').click();
            modal.removeClass('submit-action');
        }

        return false;
    });
    // do bulk action
    $('.recipes-bulk [name=bulk-confirm-action]').click(function (e) {
        var btn = $(this);

        if ($('.recipes-list-for-cookbook').length == 0) {
            var selects = $('.recipe-select:checked');
        } else {
            var selects = $('li.row-section'); // specific case where entries are populated from cookbook reordering page
        }

        var pdata = {
            recipe: []
        };
        selects.each(function () {
            if ($('.recipes-list-for-cookbook').length == 0) {
                pdata.recipe.push($(this).val());
            } else {
                pdata.recipe.push($(this).attr('data-value'));
            }
        });

        var action = pdata.action = $('.recipes-bulk .recipe-bulk-action-selector .dropdown-toggle').attr('data-quantity-unit');
        if (pdata.action == 'add-category') {
            var category = $('.recipes-bulk .add-category-subaction-selector .dropdown-toggle');
            pdata.cat_name = $('.recipes-bulk .add-category-subaction-selector .dropdown-toggle').attr('data-quantity-unit-txt');
            // new category? open modal
            if (category.attr('data-quantity-unit') == '___new_category__') {
                $('#newCategoryModal').addClass('submit-action');
                $('.recipes-bulk .add-category-subaction-selector [data-value=___new_category__]').click();
                return false;
            }
        } else
        if (pdata.action == 'add-restaurant') {
            pdata.rest_id = $('.recipes-bulk .add-restaurant-subaction-selector .dropdown-toggle').attr('data-quantity-unit');
        }
        if (pdata.action == 'create-cookbook') {
            pdata.lang = $('.recipes-bulk .language-subaction-selector .dropdown-toggle').attr('data-quantity-unit');
        }

        var bulkit = function () {

            $.ajax({
                url: btn.attr('data-url'),
                method: 'post',
                data: pdata,
                beforeSend: function () {
                    btn.button('loading');
                },
                success: function (data) {
                    btn.button('reset').removeClass('loading');
                    if (action == 'archive' || action == 'unarchive') {
                        $.each(data.rids, function (i, rid) {
                            $('.recipe[data-rid=' + rid + ']').trigger('deleted'); // remove affected recipes from the list-view
                        });
                    } else
                    if (action == 'delete') {
                        $.each(data.rids, function (i, rid) {
                            $('.recipe[data-rid=' + rid + ']').trigger('deleted')
                        });

                    } else
                    if (action == 'add-category') {
                        var cat_slug = data.cat_slug,
                            cat_name = data.cat_name;

                        // obsolete:
                        // $.each(data.rids, function(i, rid) {
                        //     $('.recipe[data-rid='+rid+']').removeClass('__no-category__').addClass(cat_slug);
                        // });

                        // add new category to category filter
                        var div = $('#categories-well-filter');
                        var matched = false;
                        div.find('label > input[value]').each(function () {
                            var $this = $(this);
                            if ($this.attr('value') == cat_slug) {
                                matched = true;
                                return false;
                            }
                        });
                        if (matched == false) {
                            var label = $('' +
                                '<label class="">' +
                                '<input type="checkbox" name="cat[]" value="">' +
                                '<span class="label-name"></span>' +
                                '</label>');
                            label.find('input').attr('value', cat_slug);
                            label.find('span').text(cat_name);
                            div.prepend(label);
                        }
                    } else
                    if (action == 'add-restaurant') {
                        if (data.color && data.restaurant) {
                            var restaurant = $('<span class="restaurant restaurant-color-' + data.color + '"></span>').attr('data-original-title', data.restaurant);
                            $.each(data.rids, function (i, rid) {
                                $('.recipe[data-rid=' + rid + '] .restaurants-colors').append(restaurant.clone());
                            });
                        }
                    }

                    //setTimeout(function() {
                    if (action != 'import-recipes') { // if import-recipes don't deselect recipe-blocks because other call is going to handle bulk-action
                        selects.prop('checked', false).trigger('clicked');
                        $('.recipes-bulk-wrapper').trigger('close').triggerHandler('close');
                    }
                    //}, 400)
                },
                complete: function () {
                    btn.button('reset').removeClass('loading');
                }
            });

        };

        var q = $('.recipes-bulk .filter-selector a:not(.hidden)[data-value=' + action + '][data-confirm-bulk]').first().attr('data-confirm-bulk');
        if (q) {
            modal_confirm(q, function () {
                bulkit();
                $(this).modal('hide');
            });
        } else {
            bulkit();
        }

        return false;
    });

    // click on cancel in bulk menu removes selection
    $('body').on('click force-close', '[name=close-bulk-sm-menu]', function (e) {
        $('.right-part').empty();
        if (!recipes_list.find('.recipe-select:checked').prop('checked', false).trigger('clicked').length) {
            $('.recipes-bulk-wrapper').trigger('close')
        }
    });

    $('body').on('close', '.recipes-bulk-wrapper', function () {
        $(this).removeClass('visible');
    });

    // show number of filters
    var number_of_filters = function () {
        var filters = $('.categories .subwell-content [type=checkbox]:checked');
        var number = $('.categories .subwell-title number');
        if (filters.length) {
            number.text('(' + filters.length + ')');
        } else {
            number.text('');
        }
    };
    number_of_filters();


    // select/unselect all child categories if not ctrl-key + mouse-click
    $('body').on('change clicked', '.categories-well-filter input[type=checkbox]', function (e) {
        var selector = '#child_cat_' + this.value;
        var parent = this;
        var it = 0;
        if (!window.groups_ctrl) {
            $(selector).children('label').each(function () {
                var child = $(this).children('input');
                if (child[0].checked != parent.checked)
                    child[0].checked = parent.checked;
                it++;
            });
        }
        // if child category, run ?should it check/uncheck parent category as well
        if (it == 0 && !window.groups_ctrl) {
            var group_parent = window.categories[parent.value];
            selector = '#child_cat_' + group_parent;

            var active = 0;
            $(selector).children('label').each(function () {
                var child = $(this).children('input');
                if (child[0].checked == true)
                    active++;
            });

            selector = '#cat_' + group_parent;

            $(selector).each(function () {
                var child = $(this).children('input');
                child[0].checked = active > 0;
            });
        }
        window.groups_ctrl = false;
    });

    // archived, restaurant and category filter
    $('body').on('change clicked', '.filters input[type=checkbox]', function (e) {
        var cb = $(this), filters = cb.closest('.filters[data-url]');
        var cbs_chk = $('.filters input[type=checkbox]:checked').serialize();
        var search = filters.hasClass('search-filter');

        if (search) {
            cbs_chk += '&search=1';
        }

        /**
         * @someone
         * change information in bulk-action dropdown when selecting multiple recipes
         *
         * @Srdjan
         * Lines below putted to comment tag
         * New logic for switch buttons added to ajax success block

         if (cbs_chk.includes('recipes'))
         document.getElementById('bulk-menu-main-option').innerHTML =
         document.getElementById((cbs_chk.includes('archived') ? 'main-option-title-active' : 'main-option-title-archive')).innerHTML;

         if (document.getElementById('option-choose-archive-default'))
         document.getElementById('option-choose-archive-default').innerHTML =
         document.getElementById((cbs_chk.includes('archived') ? 'option-choose-active' : 'option-choose-archive')).innerHTML;

         */

        $.ajax({
            type: "POST",
            url: search ? document.location.href : filters.attr('data-url'),
            data: cbs_chk,
            beforeSend: function () {
                $('html').addClass('async-loading');
                $('[name=close-bulk-sm-menu]').trigger('force-close');
            },
            success: function (data) {
                cb.removeClass('loading');

                var recipes_list = $('.recipes-list').removeClass('scroll-loaded');

                if (search && data.html) {
                    var html = $(data.html).find('.recipes-list');
                    recipes_list.html(html);
                } else {
                    if (data.recipes) {
                        recipes_list.html(data.recipes);
                    }
                    if (data.info_text) {
                        $('.recipes-list-info-text').text(data.info_text);
                    }
                    if (data.cats) {
                        $('.category-selector-wrapper').html(data.cats);
                    }
                    if (data.url) {
                        recipes_list.attr('data-scroll-load-url', data.url).removeClass('scroll-loaded');
                    } else {
                        recipes_list.removeAttr('data-scroll-load-url').addClass('scroll-loaded');
                    }
                    // a.trigger('clicked');

                    if (!$('.recipe-category-selector li a').first().trigger('fake-click').length) {
                        init_scroll_load();
                    }
                    /**
                     * switch Archive bulk action btn and Activate bulk action btn
                     * It depends on filter setup ( "Archived recipes" is checked or not )
                     */
                    if(cbs_chk.includes('archived')){
                        // "Archived recipes" is checked
                        // hide Archive btn
                        $('#archive-recipes-btn').addClass('hidden');
                        // show Activate btn
                        $('#activate-recipes-btn').removeClass('hidden');
                    }else{
                        // "Archived recipes" isn't checked
                        // show Archive btn
                        $('#archive-recipes-btn').removeClass('hidden');
                        // hide Activate btn
                        $('#activate-recipes-btn').addClass('hidden');
                    }

                    /**
                     * Find first visible(without class .hidden) element in bulk dropdown and click on it after using filter(filter from right side)
                     * First element will be selected each time after change filter settings
                     */
                    // var default_bulk_action = $('.recipes-bulk .recipe-bulk-action-selector .dropdown-menu li a:not(.hidden)').first();
                    // $( default_bulk_action ).trigger( "click" );
                }
            },
        });

        number_of_filters();
        return false;
    });

    //$('.recipe-db-categories-filter :checked').trigger('clicked');

    // update tooltip message on change of categories filter switch
    $('#use-groups .onoffswitch-checkbox').on('change', function (e) {
        var selector = '#use-groups';
        var and_active = Number($(selector).attr('data-and-active')) * -1;
        var tooltip_info = (and_active > 0 ? '#filter-groups-info-AND' : '#filter-groups-info-OR');
        var message = $(tooltip_info).html();

        // update template message
        $(selector).attr('data-original-title', message);
        // update directly in tooltip
        $('.subwell.categories').find('.tooltip-inner').html(message);

        $(selector).attr('data-and-active', and_active);
    });


    $("#tag-type-filter").click(function () {
        // var div = $(this).parent();

        // div.find('input[type=checkbox]:checked').each(function () {
        //     $(this).click();
        // })

        var url = getResetSearchingParamsUrl();

        $.ajax({
            url: url,
            method: 'post',
            data: { 'key': 'recipes.filter.tags' },
            success: function () {
                location.reload();
            }
        });
    })

    $("#categories-type-filter").click(function () {
        var url = getResetSearchingParamsUrl();

        $.ajax({
            url: url,
            method: 'post',
            data: { 'key': 'recipes.filter.categories' },
            success: function () {
                location.reload();
            }
        });
    })

    $("#location-type-filter").click(function () {
        // var div = $(this).parent();

        // div.find('input[type=checkbox]:checked').each(function () {
        //     $(this).click();
        // })

        var url = getResetSearchingParamsUrl();

        $.ajax({
            url: url,
            method: 'post',
            data: { 'key': 'recipes.filter.restaurants' },
            success: function () {
                location.reload();
            }
        });
    })

    $("#recipetype-type-filter").click(function () {
        var div = $(this).parent();

        div.find('input[type=checkbox]:checked').each(function () {
            $(this).click();
        })
    })

    $("#reset-all").click(function () {

        var url = getResetSearchingParamsUrl();

        $.ajax({
            url: url,
            method: 'post',
            data: [],
            success: function () {
                location.reload();
            }
        });
    })


    function getResetSearchingParamsUrl() {
        var isDbView = $("#db-recipes-shown");

        var url = null;

        if (isDbView.length > 0) {
            url = $("#reset-user-filter-url-db").val();

            var path = window.location.pathname;

            var params = (path.split("/"));

            url += "/" + params[(params.length - 1)];

        } else {
            url = $("#reset-user-filter-url").val();
        }

        return url;
    }

    ////////////////////////
    // end of document ready

});

/**
 * Wrapper class with methods for dinamic animation of element
 */

class Animations {
    constructor() {
        /** init */
    }

    /**
     * Iterate over all elements of selector
     * Perform rotation by 180 degrees
     * 
     * @param {Object} element 
     * @param {Number} angle 
     * @param {Number} duration 
     * @param {String} easing ['linear'|'swing'] 
     * @param {Function} complete 
     * @returns 
     */
    halfRotate(element, duration = 250, easing = 'linear', complete = function () { return 0; }) {
        return element.each(function() {
            var $elem = $(this);
            var matrix = $elem.css('transform').split(',');
            var degree = Math.round(Math.atan2(Number(matrix[1]), Number(matrix[0].split('(')[1])) * (180 / Math.PI)) > 90 ? 180 : 0;
            
            $({deg: isNaN(degree) ? 0 : degree}).animate({deg: degree + 180}, {
                duration: duration, easing: easing, complete: complete(),
                step: function(now) {
                    $elem.css({ transform: 'rotate(' + now + 'deg)' });
                }
            });
        });
    }
}

// Export the class as the default export
export default Animations;